import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import EyeIcon from "../images/eye.svg";
import EditJobDescriptionModal from "./interview_request2/edit_job_description"; // Ensure you have imported this

function EditRole({ role, setSelectedRole, spotlights }) {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isJobDescriptionModalOpen, setIsJobDescriptionModalOpen] =
    useState(false);

  const [formData, setFormData] = useState({
    title: "",
    job_description_link: "",
    email: "",
    key_experience: "",
    compensation: "",
    working_style: "",
    hiring_lead_name: "",
    hiring_lead_linkedin: "",
    equity: "",
    hiring_lead_email: "",
    interview_process: "",
    additional_info: "",
  });

  const [file, setFile] = useState(null);
  const [selectedSpotlight, setSelectedSpotlight] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({ name: "", comment: "" });

  const [isAddSpotlightModalOpen, setIsAddSpotlightModalOpen] = useState(false);
  const [newSpotlight, setNewSpotlight] = useState({
    name: "",
    comment: "",
    url: "",
  });

  const handleSpotlightChange = (spotlight) => {
    setSelectedSpotlight((prev) =>
      prev?.name === spotlight.name ? null : spotlight
    );
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission logic here
    event.preventDefault();
    // Handle the logic to add a new spotlight here
    const updatedData = {
      fields: {
        Name: editFormData.name,
        Comment: editFormData.comment,
      },
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/updateSpotlightData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {}
    setIsAddSpotlightModalOpen(false);
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openEditModal = (spotlight) => {
    setEditFormData({
      name: spotlight.name,
      comment: spotlight.comment,
    });
    setIsEditModalOpen(true);
  };

  const handleAddSpotlightChange = (event) => {
    const { name, value } = event.target;
    setNewSpotlight((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddSpotlightSubmit = async (event) => {
    event.preventDefault();
    // Handle the logic to add a new spotlight here
    const data = {
      fields: {
        Name: newSpotlight.name,
        Comment: newSpotlight.comment,
        URL: newSpotlight.url,
      },
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/createSpotlight", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {}
    setIsAddSpotlightModalOpen(false);
  };

  const openAddSpotlightModal = () => {
    setNewSpotlight({ name: "", comment: "", url: "" });
    setIsAddSpotlightModalOpen(true);
  };

  useEffect(() => {
    if (role) {
      if (role.spotlight) {
        const spotlightToSelect = spotlights.find(
          (s) => s.id === role.spotlight[0]
        );
        setSelectedSpotlight(spotlightToSelect || null);
        console.log(spotlightToSelect);
      }

      console.log(spotlights);
      console.log(role);

      setFormData({
        title: role.role_title || "",
        compensation: role.compensation || "",
        job_description_link: role.job_description_link || "",
        hiring_lead_name: role.hiring_lead_name || "",
        hiring_lead_email: role.hiring_lead_email || "",
        equity: role.equity || "",
        hiring_lead_linkedin: role.hiring_lead_linkedin || "",
        key_experience: role.key_experience || "",
        interview_process: role.interviewProcess || "",
        working_style: role.working_style || "",
      });

      if (role.spotlight) {
        setSelectedRole(role);
      }
    }
  }, [role, spotlights, setSelectedRole]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      fields: {},
      roleId: role.id,
    };

    // Add non-empty fields to the updatedData.fields object
    if (formData.title) updatedData.fields["Role Title"] = formData.title;
    if (formData.hiring_lead_name)
      updatedData.fields["Hiring Lead Name"] = formData.hiring_lead_name;
    if (formData.hiring_lead_email)
      updatedData.fields["Hiring Lead Email Address"] =
        formData.hiring_lead_email;
    if (formData.key_experience)
      updatedData.fields["Specific Experience"] = formData.key_experience;
    if (formData.hiring_lead_linkedin)
      updatedData.fields["Hiring Lead Linkedin"] =
        formData.hiring_lead_linkedin;
    if (formData.interview_process)
      updatedData.fields["Interview Process"] = formData.interview_process;
    if (formData.working_style)
      updatedData.fields["Working Style"] = formData.working_style;
    if (formData.compensation)
      updatedData.fields.Compensation = formData.compensation;
    if (formData.equity) updatedData.fields.Equity = formData.equity;
    if (formData.job_description_link)
      updatedData.fields["Job Description PDF"] = formData.job_description_link;

    if (selectedSpotlight) {
      updatedData.fields.Spotlight = [selectedSpotlight.id];
    }

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/updateRoleData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setSelectedRole(null);
      window.location.reload();
    } catch (err) {
      console.error(err);
      // Handle error
    }
  };

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleJobDescriptionButtonClick = () => {
    setIsJobDescriptionModalOpen(true);
  };

  const closeJobDescriptionModal = () => {
    setIsJobDescriptionModalOpen(false);
  };

  return (
    <div className="flex flex-col items-center bg-white">
      <div className="max-w-6xl w-full flex flex-col h-full bg-white">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="border-gray-900/10 pb-12">
              <button
                type="button"
                onClick={() => setSelectedRole(null)}
                className=""
              >
                ← Back to all roles
              </button>
              <h2 className="text-4xl pt-5 font-semibold leading-7 text-gray-900">
                Edit the role:{" "}
                <span
                  className="font-semibold"
                  style={{ color: "rgb(0, 185, 140)" }}
                >
                  {formData.title}
                </span>
              </h2>
              <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
                This information will appear when candidates will review your
                job roles.
              </p>

              <h2 className="block mt-6 flex text-3xl font-light leading-6 text-gray-900">
                Job Description{" "}
                <div className="relative group">
                  <svg
                    onClick={() => {
                      window.open(
                        "https://jumpstart-alumni.notion.site/Writing-a-Job-Description-33504887841241d0a52f5a2a9e23e245",
                        "_blank"
                      );
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-7 h-7 ml-2 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>
                  <div className="absolute left-0 hidden mt-1 w-40 p-2 z-50 text-sm text-white bg-[#02B491] rounded-lg shadow-md group-hover:block">
                    For tips on writing a JD click the icon
                  </div>
                </div>
              </h2>

              <div className="mt-4">
                <label
                  htmlFor="job-description"
                  className="block text-md font-semibold leading-6 text-gray-900"
                >
                  Link
                </label>
                <div className="mt-1">
                  <div className="relative max-w-4xl flex items-center">
                    <input
                      type="text"
                      name="job_description_link"
                      id="job-description"
                      className="block max-w-[500px] pl-2 pr-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                      placeholder="https://www.jumpstart-uk.com/"
                      value={formData.job_description_link}
                      onChange={handleChange}
                    />

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-10 cursor-pointer pl-2"
                      onClick={handleIconClick}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                      />
                    </svg>

                    <div className="pl-2 items-center">
                      <button
                        type="button"
                        onClick={handleJobDescriptionButtonClick}
                        className="px-4 py-2 text-sm hover:bg-[#02B491] w- bg-white text-[#02B491] border-2 border-[#02B491] hover:text-white rounded-lg"
                      >
                        Upload Job Description
                      </button>
                      {formErrors.job_description_link && (
                        <p className="text-red-600 text-sm ml-2">
                          {formErrors.job_description_link}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {isModalOpen && (
                  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded shadow-lg max-w-3xl w-full h-3/4">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-bold">
                          Job Description Content
                        </h2>
                        <button
                          onClick={closeModal}
                          className="px-4 py-2 text-black rounded"
                          style={{
                            backgroundColor: "rgb(0, 242, 194)",
                          }}
                        >
                          Close
                        </button>
                      </div>

                      <iframe
                        src={formData.job_description_link}
                        title="Job Description Content"
                        className="w-full h-4/5 border rounded"
                        width="80%"
                        height="80%"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-8 sm:col-span-8 flex space-x-8">
                <div className="flex-1">
                  <h2 className="block mt-6 text-3xl font-light leading-6 text-gray-900">
                    Basic Information
                  </h2>
                  <label
                    htmlFor="title"
                    className="block mt-6  text-md font-semibold leading-6 text-gray-900"
                  >
                    Role Title
                  </label>
                  <div className="mt-2 mb-4">
                    <div className="">
                      <input
                        type="text"
                        name="title"
                        id="title"
                        autoComplete="title"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="Sales"
                        value={formData.title}
                        onChange={handleChange}
                      ></input>
                    </div>
                    {formErrors.title && (
                      <p className="text-red-600 text-sm">{formErrors.title}</p>
                    )}
                  </div>
                  <label
                    htmlFor="compensation"
                    className="block text-md font-semibold leading-6 text-gray-900"
                  >
                    Compensation
                  </label>
                  <div className="mt-2 mb-4">
                    <div className="">
                      <input
                        type="text"
                        name="compensation"
                        id="compensation"
                        autoComplete="compensation"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="£40-50,000 + £10k commission"
                        value={formData.compensation}
                        onChange={handleChange}
                      />
                    </div>
                    {formErrors.compensation && (
                      <p className="text-red-600 text-sm">
                        {formErrors.compensation}
                      </p>
                    )}
                  </div>
                  <label
                    htmlFor="name"
                    className="block text-md font-semibold leading-6 text-gray-900"
                  >
                    Working Style
                  </label>
                  <div className="mt-2 mb-4">
                    <input
                      type="text"
                      name="working_style"
                      id="working_style"
                      autoComplete="working_style"
                      className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                      placeholder="Hybrid - 1 day per week in office"
                      value={formData.working_style}
                      onChange={handleChange}
                    ></input>
                    {formErrors.working_style && (
                      <p className="text-red-600 text-sm">
                        {formErrors.working_style}
                      </p>
                    )}
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="equity"
                      className="block text-md font-semibold text-gray-900 dark:text-white"
                    >
                      Would you be open to offering equity?
                    </label>
                    <select
                      id="equity"
                      name="equity"
                      value={formData.equity}
                      onChange={handleChange}
                      className="bg-transparent mt-2 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block pr-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="" disabled>
                        Open to offering equity
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="TBC">To be determined</option>
                    </select>
                  </div>
                </div>
                <div className="flex-1">
                  <h2 className="block mt-6 text-3xl font-light leading-6 text-gray-900">
                    Hiring Manager Details
                  </h2>
                  <div className="mt-6 ">
                    <label
                      htmlFor="name"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div className="mt-2 mb-4">
                      <div className="">
                        <input
                          type="text"
                          name="hiring_lead_name"
                          id="name"
                          autoComplete="name"
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="John Smith"
                          value={formData.hiring_lead_name}
                          onChange={handleChange}
                        ></input>
                      </div>
                      {formErrors.hiring_lead_name && (
                        <p className="text-red-600 text-sm">
                          {formErrors.hiring_lead_name}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="hiring_lead_email"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Email Address
                    </label>
                    <div className="mt-2 mb-4">
                      <div className="">
                        <input
                          type="hiring_lead_email"
                          name="hiring_lead_email"
                          id="hiring_lead_email"
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="john.smith@yourcompany.com"
                          value={formData.hiring_lead_email}
                          onChange={handleChange}
                        ></input>
                      </div>
                      {formErrors.hiring_lead_email && (
                        <p className="text-red-600 text-sm">
                          {formErrors.hiring_lead_email}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="linkedin"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      LinkedIn
                    </label>
                    <div className="mt-2">
                      <div className="">
                        <input
                          type="linkedin"
                          name="hiring_lead_linkedin"
                          id="linkedin"
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="https://www.linkedin.com/in/john-smith/"
                          value={formData.hiring_lead_linkedin}
                          onChange={handleChange}
                        ></input>
                      </div>
                      {formErrors.hiring_lead_linkedin && (
                        <p className="text-red-600 text-sm">
                          {formErrors.hiring_lead_linkedin}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="block mt-14 text-3xl font-light leading-6 text-gray-900">
                Role details
              </h2>

              <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                <div className="flex-1">
                  <div className="col-span-full">
                    <label
                      htmlFor="key_experience"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Key skills or experience
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="key_experience"
                        name="key_experience"
                        rows="5"
                        className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="A few lines on the type of candidate you are looking for!"
                        value={formData.key_experience}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="col-span-full">
                    <label
                      htmlFor="interview_process"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Interview Process
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="interview_process"
                        name="interview_process"
                        rows="5"
                        className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="A short description of the interview process for this role!"
                        value={formData.interview_process}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container mx-auto py-4">
                <h2 className="block mt-14 text-3xl font-light leading-6 text-gray-900">
                  Spotlights
                </h2>
                <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                  {spotlights.length === 0 ? (
                    <>
                      <p className="text-gray-500 w-[600px] dark:text-gray-400">
                        You have not recorded any spotlight with us yet.
                      </p>
                      <button
                        className="text-md px-6 py-2 mb-4 rounded-md bg-white hover:bg-[#02B491] text-black border-2 border-[#02B491] hover:text-white"
                        onClick={() => {
                          window.location.href =
                            "mailto:matthew@jumpstart-uk.com?subject=Book a Spotlight&body=Hey Matthew, I would love to book a spotlight for the next cohort!";
                        }}
                      >
                        Book a spotlight
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="flex-1">
                        {spotlights.map((spotlight, index) => (
                          <ul key={index} className="space-y-4 mb-4">
                            <li>
                              <input
                                type="radio"
                                id={`job-${index}`}
                                name="job"
                                className="hidden peer"
                                checked={
                                  selectedSpotlight?.name === spotlight.name
                                }
                                onChange={() =>
                                  handleSpotlightChange(spotlight)
                                }
                              />
                              <label
                                htmlFor={`job-${index}`}
                                className="inline-flex relative items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-green-500 peer-checked:border-[#02B491] peer-checked:text-[#02B491] hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                              >
                                <div className="block w-[400px]">
                                  <div className="w-full text-lg font-semibold">
                                    {spotlight.name}
                                  </div>
                                  <div className="w-full text-gray-500 dark:text-gray-400">
                                    {spotlight.comment}
                                  </div>
                                  <div
                                    className="absolute top-2 hover:underline right-2 text-sm font-normal cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      openEditModal(spotlight);
                                    }}
                                  >
                                    Edit Spotlight
                                  </div>
                                </div>
                              </label>
                            </li>
                          </ul>
                        ))}

                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsAddSpotlightModalOpen(true);
                          }}
                        >
                          <div className="inline-flex relative items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-green-500 peer-checked:border-[#02B491] peer-checked:text-[#02B491] hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                            <div className="block w-[400px]">
                              <div className="w-full text-lg font-semibold">
                                + Add spotlight
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {selectedSpotlight ? (
                        <div className="flex-1">
                          <video
                            src={selectedSpotlight.url}
                            className="h-[300px] w-[600px] object-cover rounded-xl bg-[var(--overlay-2)]"
                            controls
                            onError={(e) => {
                              console.error(
                                "Failed to load video at",
                                e.target.src
                              );
                            }}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : (
                        <div className="flex-1 w-[600px]">
                          <p className="text-gray-500 dark:text-gray-400">
                            You have not selected any spotlight yet.
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {isEditModalOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg">
                      <h2 className="text-2xl mb-4">Edit Spotlight</h2>

                      <div className="mb-4">
                        <label className="block text-md font-medium text-gray-700">
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={editFormData.name}
                          onChange={handleEditChange}
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-md font-medium text-gray-700">
                          Comment
                        </label>
                        <textarea
                          name="comment"
                          value={editFormData.comment}
                          onChange={handleEditChange}
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        />
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="button"
                          onClick={() => setIsEditModalOpen(false)}
                          className="mr-4 px-4 py-2 bg-gray-300 rounded-md"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={(e) => handleEditSubmit}
                          type="submit"
                          className="px-4 py-2 bg-[#02B491] text-white rounded-md"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {isAddSpotlightModalOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg">
                      <h2 className="text-2xl mb-4">Add Spotlight</h2>
                      <form onSubmit={handleAddSpotlightSubmit}>
                        <div className="mb-4">
                          <label className="block text-md font-medium text-gray-700">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={newSpotlight.name}
                            onChange={handleAddSpotlightChange}
                            className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-md font-medium text-gray-700">
                            Comment
                          </label>
                          <textarea
                            name="comment"
                            value={newSpotlight.comment}
                            onChange={handleAddSpotlightChange}
                            className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-md font-medium text-gray-700">
                            URL
                          </label>
                          <input
                            type="url"
                            name="url"
                            value={newSpotlight.url}
                            onChange={handleAddSpotlightChange}
                            className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                            required
                          />
                        </div>
                        <div className="flex justify-end">
                          <button
                            type="button"
                            onClick={() => setIsAddSpotlightModalOpen(false)}
                            className="mr-4 px-4 py-2 bg-gray-300 rounded-md"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="px-4 py-2 bg-[#02B491] text-white rounded-md"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className=" flex items-center -mt-5 justify-start gap-x-6">
            <button
              type="submit"
              className="px-6 py-2 mb-4 text-md bg-[#02B491] hover:bg-white text-white border-2 border-[#02B491] hover:text-black rounded-lg font-medium"
            >
              Save job role
            </button>
          </div>
        </form>
      </div>
      {isJobDescriptionModalOpen && (
        <EditJobDescriptionModal
          onClose={closeJobDescriptionModal}
          formData={formData}
          setFormData={setFormData}
          setFile={setFile}
          file={file}
          onFileUpload={(url) =>
            setFormData({ ...formData, job_description_link: url })
          }
        />
      )}
    </div>
  );
}

export default EditRole;
