import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FakeLogin = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [animationClass, setAnimationClass] = useState("animate-fadeInUp");

  useEffect(() => {
    const username = query.get("username");
    const password = query.get("password");

    if (username && password) {
      localStorage.clear();
      login(username, password);
    }
  }, [searchParams]);

  const login = async (username, password) => {
    setError(""); // Reset error message
    console.log(username);
    console.log(password);

    for (let attempt = 1; attempt <= 10; attempt++) {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds before each attempt
        const response = await axios.post("/api/login", { username, password });
        const { token } = response.data;
        localStorage.setItem("token", token); // Store token in local storage

        const redirectPage = query.get("page") || "/browse-candidates"; // Get the page parameter or default to /browse-candidates
        const roleId = query.get("roleId");
        const redirectUrl = roleId
          ? `${redirectPage}?roleId=${roleId}`
          : redirectPage;

        // Trigger fade-out animation
        setAnimationClass("animate-fadeOutUp");

        // Delay navigation to let the animation finish
        setTimeout(() => {
          navigate(redirectUrl); // Redirect to the specified page
          window.location.reload();
        }, 500); // 500ms delay to match the animation duration

        return; // Exit the function if login is successful
      } catch (err) {
        if (attempt === 10) {
          setError("Invalid username or password");
        } else {
          console.log(`Attempt ${attempt} failed, retrying...`);
        }
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-white">
      <h2 className={`text-xl pb-2 font-bold ${animationClass}`}>
        Logging you in
      </h2>
      <div className="w-[200px] mb-40 h-1 rounded-md bg-gray-300 overflow-hidden relative">
        <div className="h-full w-1/2 rounded-md bg-black animate-slide-bar"></div>
      </div>
    </div>
  );
};

export default FakeLogin;
