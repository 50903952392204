import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditRole from "../../components/edit_role";

function Roles() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;
        const transformedData = roles.map((role) => {
          const date = new Date(role["createdTime"]);
          const month = date.toLocaleString("default", { month: "long" });
          const year = date.getFullYear();

          return {
            id: role["id"],
            createdTime: role["createdTime"],
            month,
            year,
            equity: role["Equity"],
            role_title: role["Role Title"],
            hiring_lead_name: role["Hiring Lead Name"],
            hiring_lead_email: role["Hiring Lead Email Address"],
            hiring_lead_linkedin: role["Hiring Lead Linkedin"],
            key_experience: role["Specific Experience"],
            job_description_link: role["Job Description PDF"],
            startup: role["Startup"],
            compensation: role["Compensation"],
            interviewProcess: role["Interview Process"],
            working_style: role["Working Style"],
            spotlight: role["Spotlight"],
          };
        });

        setData(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col items-center bg-white pt-28 pb-2 px-4">
      {selectedRole ? (
        <EditRole role={selectedRole} setSelectedRole={setSelectedRole} />
      ) : (
        <div>
          <div className="relative max-w-[1400px] overflow-x-auto border-2 sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Role Title
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Salary Compensation
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Job Description
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Hiring Manager Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Hiring Manager Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Created Month
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Created Year
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Candidates
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b hover:bg-gray-50 "
                  >
                    <th
                      scope="row"
                      className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      {item.role_title}
                    </th>
                    <td className="px-4 py-4">{item.compensation}</td>
                    <td className="px-4 py-4 whitespace-normal break-words">
                      <a
                        href={item.job_description_link}
                        className="font-medium text-blue-600  hover:underline"
                      >
                        {item.job_description_link}
                      </a>
                    </td>
                    <td className="px-4 py-4">{item.hiring_lead_name}</td>
                    <td className="px-4 py-4">{item.hiring_lead_email}</td>
                    <td className="px-4 py-4">{item.month}</td>
                    <td className="px-4 py-4">{item.year}</td>
                    <td className="px-4 py-4">
                      <a
                        href="/your-candidates"
                        className="font-medium text-blue-600 hover:underline"
                      >
                        View
                      </a>
                    </td>
                    <td className="px-4 py-4">
                      <a
                        onClick={(e) => setSelectedRole(item)}
                        className="font-medium text-blue-600  hover:underline"
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full max-w-[1400px] flex justify-end mt-5">
            <button
              type="submit"
              className="px-6 py-2 mb-4 text-sm bg-black text-black rounded-lg font-medium transform hover:-translate-y-1 transition duration-400"
              style={{
                backgroundColor: "rgb(0, 242, 194)",
              }}
              onClick={(e) => navigate("/new-role")}
            >
              Create a new role
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Roles;
