import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function RedirectRole() {
  const navigate = useNavigate();
  const { id: roleId } = useParams();

  useEffect(() => {
    if (roleId) {
      navigate(`/role?roleId=${roleId}`);
    }
  }, [navigate, roleId]);

  return <div className="flex ml-56 flex-col items-center bg-white"></div>;
}

export default RedirectRole;
