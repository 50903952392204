import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useAsyncError, useNavigate } from "react-router-dom";
import CandidateCard from "../../../components/candidates/generalist_card.js";
import InterviewRequest from "../../../components/interview_request/progress_bar.js";
import ActiveFilters from "../../../components/active_filters.js";
import CandidatesLoading from "../../../components/candidates/candidates_loading.js";
import FiltersModal from "../../../components/filters.js";
import MultiSelect from "../../../components/multiselect_filter.js";

export default function Candidates() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterStream, setFilterStream] = useState([]);
  const [filterYearsOfExp, setFilterYearsOfExp] = useState([]);
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [filterWorkingStyle, setFilterWorkingStyle] = useState([]);
  const [filterIndustry, setFilterIndustry] = useState([]);
  const [roles, setRoles] = useState([]);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [recommendedCandidates, setRecommendedCandidates] = useState([]);
  const [isInterviewRequest, setIsInterviewRequest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newRoleTitle, setNewRoleTitle] = useState("");
  const [newRoleForm, setNewRoleForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Add search query state
  const [candidatesStatus, setCandidatesStatus] = useState([]);
  const [rolesNeedUpdate, setRolesNeedUpdate] = useState(true);
  const [toasts, setToasts] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState([]); // For storing multiple search keywords

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const [savedCandidates, setSavedCandidates] = useState(() => {
    const savedCandidates = localStorage.getItem("candidates");
    return savedCandidates ? JSON.parse(savedCandidates) : [];
  });

  const streams = ["Generalist", "Sales", "Software Engineering", "Marketing"];
  const year_of_exp = ["0-1", "1-2", "2-3", "3-4", "4+"];
  const working_style = ["Fully Remote", "Fully in person", "Hybrid"];

  const industry_exp = [
    "Banking",
    "Consulting",
    "Startup experience",
    "Founder experience",
  ];

  const saveFiltersToLocalStorage = () => {
    const filters = {
      filterStream,
      filterYearsOfExp,
      minSalary,
      maxSalary,
      filterWorkingStyle,
      filterIndustry,
      timestamp: Date.now(),
    };
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const loadFiltersFromLocalStorage = () => {
    const filters = JSON.parse(localStorage.getItem("filters"));
    if (filters) {
      const currentTime = Date.now();
      const twoHours = 2 * 60 * 60 * 1000;

      if (currentTime - filters.timestamp < twoHours) {
        setFilterStream(filters.filterStream);
        setFilterYearsOfExp(filters.filterYearsOfExp);
        setMinSalary(filters.minSalary);
        setMaxSalary(filters.maxSalary);
        setFilterWorkingStyle(filters.filterWorkingStyle);
        setFilterIndustry(filters.filterIndustry);
      } else {
        localStorage.removeItem("filters");
      }
    }
  };

  useEffect(() => {
    loadFiltersFromLocalStorage();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  const parseSalaryRange = (salary) => {
    const salaryRange = salary.match(/£(\d+)k?-?£?(\d+)?k?/i);
    if (salaryRange) {
      const minSalary = parseInt(salaryRange[1]) * 1000;
      const maxSalary = salaryRange[2]
        ? parseInt(salaryRange[2]) * 1000
        : minSalary;
      return { minSalary, maxSalary };
    }
    return { minSalary: null, maxSalary: null };
  };

  const fetchShortlistedCandidates = useCallback(async () => {
    if (!rolesNeedUpdate) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/roles/fetchRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const roles = response.data.roles;

      let shortlistedCandidatesByRole = {};
      let rolesList = [];

      roles.forEach((role) => {
        const roleTitle = role["Role Title"];
        const roleUrlId = role["URL ID"];
        const roleId = role["id"];
        rolesList.push(roleTitle);
        if (role["Shortlisted Candidates"]) {
          if (!shortlistedCandidatesByRole[roleTitle]) {
            shortlistedCandidatesByRole[roleTitle] = {
              roleUrlId: roleUrlId,
              roleId: roleId,
              candidates: [],
            };
          }
          shortlistedCandidatesByRole[roleTitle].candidates =
            shortlistedCandidatesByRole[roleTitle].candidates.concat(
              role["Shortlisted Candidates"]
            );
        }
      });

      setShortlistedCandidates(shortlistedCandidatesByRole);
      setRoles(roles);
      setRolesNeedUpdate(false);
    } catch (err) {
      setError(err.message);
    }
  }, [rolesNeedUpdate]);

  const fetchRecommendedCandidates = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/companies/fetchCompanyData", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const company = response.data.companyData;
      const recommendation = company["recommended_candidates"] || [];

      setRecommendedCandidates(recommendation);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  const fetchInterviews = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/interviews/fetchInterviews", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const interviews = response.data.interviews;
      const candidateStatusTuples = interviews.map((interview) => ({
        id: interview["Candidate"][0],
        status: interview["Stage"],
        role: interview["Role Title (from Role)"],
      }));

      setCandidatesStatus(candidateStatusTuples);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/candidates/fetchCandidates", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const candidates = response.data.candidates;

      const transformedData = candidates.map((candidate) => {
        const fields = candidate.fields || {};

        let sweRoles = fields["SWE relevant roles"] || "";
        if (sweRoles) {
          sweRoles = Array.isArray(sweRoles)
            ? sweRoles[0].split(",").map((role) => role.trim())
            : sweRoles.split(",").map((role) => role.trim());
          sweRoles = transformRoles(sweRoles);
        } else {
          sweRoles = [];
        }

        const stream = fields["Stream (from CRM)"][0];

        let salary;
        if (stream === "Generalist" || stream === "Marketing") {
          salary = fields["Salary - cleaned"] || "";
        } else if (stream === "Sales") {
          salary = fields["Base range + commission"] || "";
        } else if (stream === "Software Engineering") {
          salary = fields["SWE Salary - cleaned"] || "";
        }

        const { minSalary, maxSalary } = parseSalaryRange(salary);

        return {
          id: candidate["id"] || "",
          url_id: fields["ID"] || "",
          minimum_salary: minSalary || 0,
          maximum_salary: maxSalary || 0,
          createdTime: candidate["createdTime"] || "",
          years_of_exp: fields["Years of exp (or commercial exp)"] || 0,
          cv: fields["Raw CV"] || "",
          video: fields["Raw video intro"] || "",
          linkedin: fields["LinkedIn raw URL"] || "",
          industry_exp: fields["Industry experience"] || undefined,
          name: fields["Name"] || "",
          lastName: fields["Surname"] || "",
          education: fields["Degree details"] || undefined,
          roles: fields["Generalist roles suitable for"] || "",
          salary: salary || "",
          email: fields["Email"] || "",
          image: fields.Photo ? fields.Photo[0].url : null,
          work: fields["Raw work experience"] || "",
          working_style: fields["Preferred working style G/M/S"] || "",
          github: fields["Raw Github"] || "",
          swe_roles: sweRoles,
          languages: fields["Languages"] || "",
          time_coding: fields["Time spent coding"] || "",
          coding_languages: fields["Coding languages"] || "",
          description: fields["Top achievement"] || "",
          mostRecentCohort: fields["Most recent cohort"] || "",
          recentTarget: fields["Recent target achieved"] || "",
          stream: fields["Stream (from CRM)"][0] || "",
          search_fields: fields["Search Aggregate Fields"] || "", // Ensure this field is correctly populated
          sales:
            fields["Proven Sales Experience / High Potential for Sales"] || "",
          exp_swe: fields["Level of exp SWE"] || "",
        };
      });

      setCandidates(transformedData);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const redStatuses = [
      "Rejected @ CV",
      "Rejected @ Interview",
      "Rejected Interview Request",
      "Company withdrawn",
      "Candidate withdrawn",
    ];

    const getStatusPriority = (status) => {
      if (status === "Application pending") return 1;
      if (status === "Interviewing") return 3;
      if (status === "Match requested") return 4;
      if (redStatuses.includes(status)) return 5;
      return 2; // No status
    };

    const allCandidatesWithStatus = candidates.map((candidate) => {
      const candidateStatus = candidatesStatus.find(
        (status) => status.id === candidate.id
      );
      return {
        ...candidate,
        status: candidateStatus ? candidateStatus.status : null,
        isRecommended: recommendedCandidates.includes(candidate.id),
      };
    });

    const sortedCandidates = allCandidatesWithStatus.sort((a, b) => {
      const aPriority = getStatusPriority(a.status);
      const bPriority = getStatusPriority(b.status);

      if (aPriority !== bPriority) return aPriority - bPriority;

      // When priorities are equal, sort recommended candidates first
      if (a.isRecommended && !b.isRecommended) return -1;
      if (!a.isRecommended && b.isRecommended) return 1;

      return new Date(b.createdTime) - new Date(a.createdTime);
    });

    setData(sortedCandidates);
  }, [candidates, candidatesStatus, recommendedCandidates]);

  useEffect(() => {
    fetchRecommendedCandidates();
    fetchData();
    fetchInterviews();
  }, [fetchRecommendedCandidates, fetchData, fetchInterviews]);

  useEffect(() => {
    fetchShortlistedCandidates();
  }, [fetchShortlistedCandidates]);

  useEffect(() => {
    localStorage.setItem("candidates", JSON.stringify(savedCandidates));
  }, [savedCandidates]);

  useEffect(() => {
    saveFiltersToLocalStorage();
  }, [
    filterStream,
    filterYearsOfExp,
    minSalary,
    maxSalary,
    filterWorkingStyle,
    filterIndustry,
  ]);

  const addCandidate = (candidate) => {
    if (!savedCandidates.some((c) => c.id === candidate.id)) {
      setSavedCandidates([...savedCandidates, candidate]);
    }
  };

  const removeCandidate = (candidate) => {
    setSavedCandidates(savedCandidates.filter((c) => c.id !== candidate.id));
  };

  const clear = () => {
    setSavedCandidates([]);
  };

  const toggleCandidate = (candidate) => {
    if (savedCandidates.some((c) => c.id === candidate.id)) {
      removeCandidate(candidate);
    } else {
      addCandidate(candidate);
    }
  };

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleStreamFilterChange = (selectedOptions) => {
    setFilterStream(selectedOptions);
  };

  const handleStreamChange = (option) => {
    if (filterStream.includes(option)) {
      handleStreamFilterChange(filterStream.filter((item) => item !== option));
    } else {
      handleStreamFilterChange([...filterStream, option]);
    }
  };

  const handleYearsOfExpFilterChange = (selectedOptions) => {
    setFilterYearsOfExp(selectedOptions);
  };

  const handleWorkingStyleFilterChange = (selectedOptions) => {
    setFilterWorkingStyle(selectedOptions);
  };

  const handleMinSalaryChange = (e) => {
    setMinSalary(e.target.value ? parseInt(e.target.value) : "");
  };

  const handleMaxSalaryChange = (e) => {
    setMaxSalary(e.target.value ? parseInt(e.target.value) : "");
  };

  const handleSuccess = (e) => {
    navigate("/your-candidates");
    window.location.reload();
  };

  const handleFilterChange = (updatedFilters) => {
    setFilterStream(updatedFilters.filterStream);
    setFilterYearsOfExp(updatedFilters.filterYearsOfExp);
    setFilterWorkingStyle(updatedFilters.filterWorkingStyle);
    setFilterIndustry(updatedFilters.filterIndustry);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    if (searchQuery.trim()) {
      setSearchKeywords((prevKeywords) => [
        ...prevKeywords,
        searchQuery.trim(),
      ]);
      setSearchQuery(""); // Clear the input after adding the keyword
    }
  };

  const filteredData = data.filter((candidate) => {
    const matchesStream = filterStream.length
      ? filterStream.includes(candidate.stream)
      : true;

    const matchesYearsOfExp = filterYearsOfExp.length
      ? filterYearsOfExp.includes(candidate.years_of_exp)
      : true;

    const minSalaryK = minSalary ? minSalary * 1000 : null;
    const maxSalaryK = maxSalary ? maxSalary * 1000 : null;

    const matchesSalary =
      (!minSalaryK || candidate.maximum_salary >= minSalaryK) &&
      (!maxSalaryK || candidate.minimum_salary <= maxSalaryK);

    const matchesWorkingStyle = filterWorkingStyle.length
      ? candidate.working_style
        ? filterWorkingStyle.some((style) => {
            // If the filter is for "Fully in person" or "Hybrid," return candidates with the specific label
            if (style === "Fully in person" || style === "Hybrid") {
              return candidate.working_style
                .toLowerCase()
                .includes("hybrid (2/3 days) or fully in office".toLowerCase());
            }
            // For "Fully Remote," match as usual
            return candidate.working_style
              .toLowerCase()
              .startsWith(style.toLowerCase());
          })
        : false
      : true;

    const candidateIndustryArray = candidate.industry_exp
      ? candidate.industry_exp
          .split(",")
          .map((industry_exp) => industry_exp.trim())
      : [];
    const matchesIndustry = filterIndustry.length
      ? candidateIndustryArray.some((industry) =>
          filterIndustry.includes(industry)
        )
      : true;

    const matchesSearchQuery = searchKeywords.length
      ? searchKeywords.every((keyword) =>
          candidate.search_fields.toLowerCase().includes(keyword.toLowerCase())
        )
      : true;

    return (
      matchesStream &&
      matchesYearsOfExp &&
      matchesSalary &&
      matchesWorkingStyle &&
      matchesIndustry &&
      matchesSearchQuery
    );
  });

  const handleNewRoleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      fields: {
        "Role Title": newRoleTitle,
      },
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post("/api/roles/createRole", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      handleRoleSelect(response.data.newRoleData, selectedCandidate.id);
      setRolesNeedUpdate(true);
      setNewRoleForm(false);
      setNewRoleTitle("");
    } catch (err) {
      console.error(err);
    }
  };

  const handleRoleSelect = async (role, candidateId) => {
    const updatedData = {
      fields: {
        "Shortlisted Candidates": [candidateId],
      },
      roleId: role["id"],
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const updatedRoleData = response.data.updatedRoleData.fields;

      setRoles((prevRoles) => {
        const updatedRoles = prevRoles.map((r) =>
          r.id === updatedRoleData.id ? updatedRoleData : r
        );
        return updatedRoles;
      });

      setRolesNeedUpdate(true);
    } catch (err) {
      console.error(err);
    }
  };

  const addToast = (message) => {
    setToasts((prevToasts) => [...prevToasts, message]);
  };

  useEffect(() => {
    if (toasts.length > 0) {
      const timers = toasts.map((toast, index) =>
        setTimeout(() => {
          setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
        }, 5000)
      );

      // Cleanup all timeouts when the component unmounts or the `toasts` array changes
      return () => timers.forEach((timer) => clearTimeout(timer));
    }
  }, [toasts]);

  const clearFilters = () => {
    handleStreamFilterChange([]); // Clear stream filters
    handleWorkingStyleFilterChange([]); // Clear working style filters
    setFilterIndustry([]); // Clear industry filters
    setSearchKeywords([]);
    handleYearsOfExpFilterChange([]);
    setMaxSalary("");
    setFilterYearsOfExp([]);
  };

  const onChange = ({
    filterStream: updatedFilterStream,
    filterYearsOfExp: updatedFilterYearsOfExp,
    filterWorkingStyle: updatedFilterWorkingStyle,
    filterIndustry: updatedFilterIndustry,
    searchKeywords: updatedSearchKeywords,
  }) => {
    setFilterStream(updatedFilterStream); // Update the stream filter
    setFilterYearsOfExp(updatedFilterYearsOfExp); // Update the years of experience filter
    setFilterWorkingStyle(updatedFilterWorkingStyle); // Update the working style filter
    setFilterIndustry(updatedFilterIndustry); // Update the industry filter
    setSearchKeywords(updatedSearchKeywords); // Update the search keywords
  };

  const [showSuggestions, setShowSuggestions] = useState(false); // To control the visibility of suggestions
  const suggestions = [
    "SEO",
    "Biochemistry",
    "University of Cambridge",
    "Operations",
    "Founder",
    "Imperial College London",
  ];

  return (
    <div className="relative md:pl-56 bg-gray-100 min-h-screen">
      {filtersModalOpen && (
        <FiltersModal
          setFiltersModalOpen={setFiltersModalOpen}
          filterYearsOfExp={filterYearsOfExp}
          handleYearsOfExpFilterChange={handleYearsOfExpFilterChange}
          filterWorkingStyle={filterWorkingStyle}
          handleWorkingStyleFilterChange={handleWorkingStyleFilterChange}
          filterIndustry={filterIndustry}
          setFilterIndustry={setFilterIndustry}
          filterStream={filterStream}
          handleStreamFilterChange={handleStreamFilterChange}
          length={filteredData.length}
          maxSalary={maxSalary}
          setMaxSalary={setMaxSalary}
          clearFilters={clearFilters}
        />
      )}

      <div className="flex ml-auto h-full pb-20 justify-center items-center bg-gray-100">
        {isInterviewRequest && (
          <InterviewRequest
            setIsInterviewRequest={setIsInterviewRequest}
            clear={clear}
            setSuccess={setSuccess}
          />
        )}

        <div className="max-w-[1500px] w-full">
          <div className="bg-white px-4 pt-2 pb-2 fixed top-18 z-20 w-full border-b">
            <div className="ml-4 mb-2">
              <div className="flex mt-4 mb-2 items-center gap-8">
                <h2 className="text-3xl">Candidates</h2>
                <div className="mt-2">
                  <span className="font-bold">{filteredData.length} </span>{" "}
                  candidates matching your search.
                </div>
              </div>
              <div className="mb-1">
                <div className="flex pt-2">
                  <div className="relative w-[600px]">
                    <input
                      type="text"
                      id="voice-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-md focus:ring-[#02B491] focus:border-[#02B491] block w-full p-2.5"
                      placeholder="Search through CVs and profiles"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setShowSuggestions(true);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      required
                    />
                    <button
                      type="button"
                      className="absolute rounded-md bg-[#02B491] text-white m-1 py-2 px-4 inset-y-0 end-0 flex justify-center items-center"
                      onClick={handleSearch}
                    >
                      <svg
                        className="w-4 h-4 me-2 mr-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      Search
                    </button>
                    {showSuggestions && (
                      <div className="absolute hidden mt-1 w-full bg-white rounded-md shadow-lg z-10">
                        <ul className="max-h-60 overflow-auto">
                          {suggestions.map((option) => (
                            <li
                              key={option}
                              className="cursor-pointer hover:bg-gray-100 px-4 py-2"
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <MultiSelect
                    options={streams}
                    selectedOptions={filterStream}
                    onChange={setFilterStream}
                    text={"Type of candidate"}
                  />

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setFiltersModalOpen(true);
                    }}
                    className="py-2 px-4 ml-2 flex rounded-lg border items-center justify-center border-gray-500 hover:border-black hover:bg-black/5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6 mr-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                      />
                    </svg>
                    Filters
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setFiltersModalOpen(true);
                    }}
                    className="hidden py-2 px-4 ml-2 flex rounded-lg border items-center justify-center border-gray-500 hover:border-black hover:bg-black/5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6 mr-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                      />
                    </svg>
                    Sort
                  </button>
                </div>
              </div>
              <ActiveFilters
                filterStream={filterStream}
                filterWorkingStyle={filterWorkingStyle}
                filterIndustry={filterIndustry}
                filterYearsOfExp={filterYearsOfExp}
                searchKeywords={searchKeywords}
                clearFilters={clearFilters}
                onChange={onChange}
                maxSalary={maxSalary}
                setMaxSalary={setMaxSalary}
                setSearchKeywords={setSearchKeywords}
                setFilterYearsOfExp={setFilterYearsOfExp}
              />
            </div>
          </div>

          {loading ? (
            <div className="pt-48 pl-4">
              <CandidatesLoading />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 pt-44 pl-4 mr-5 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {filteredData.map((candidate, index) => (
                  <CandidateCard
                    key={index}
                    candidate={candidate}
                    isInList={savedCandidates.some(
                      (c) => c.id === candidate.id
                    )}
                    onToggleCandidate={toggleCandidate}
                    onSelectCandidate={handleSelectCandidate}
                    recommendedCandidates={recommendedCandidates}
                    roles={roles}
                    setRoles={setRoles}
                    shortlistedCandidates={shortlistedCandidates}
                    setRolesNeedUpdate={setRolesNeedUpdate}
                    setNewRoleForm={setNewRoleForm}
                    handleSelectCandidate={handleSelectCandidate}
                    candidatesStatus={candidatesStatus}
                    addToast={addToast}
                  />
                ))}
              </div>
            </>
          )}

          {newRoleForm && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                <h2 className="text-lg font-semibold mb-4">Add New Role</h2>
                <form onSubmit={handleNewRoleSubmit}>
                  <label
                    htmlFor="title"
                    className="block  text-md font-normal leading-6 text-gray-900"
                  >
                    Role Title
                  </label>
                  <input
                    type="text"
                    value={newRoleTitle}
                    onChange={(e) => setNewRoleTitle(e.target.value)}
                    placeholder="Sales"
                    className="w-full p-2 mb-2 border border-gray-300 rounded"
                    required
                  />
                  <button
                    type="submit"
                    className="block w-full px-4 py-2 bg-[#02B491] text-sm text-white rounded hover:bg-[#00f2c2] hover:text-black"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => setNewRoleForm(false)}
                    className="block w-full px-4 py-2 mt-2 text-sm text-gray-700 hover:bg-gray-100 rounded"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}

          {toasts.map((toast, index) => (
            <div
              key={index}
              className="fixed divide-x flex right-10 border z-50 justify-between items-center w-full max-w-[500px] p-4 bg-white rounded-lg shadow-lg mb-4"
              role="alert"
              style={{ top: `${10 + index * 70}px` }} // Increased spacing to 80px between toasts
            >
              <div className="text-sm font-normal text-gray-500 flex">
                <svg
                  className="text-green-500 mr-1 h-5 w-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="font-bold">{toast}</span>&nbsp;successfully
                added to shortlist.
              </div>

              <div className="flex items-center space-x-2 pl-2">
                <button
                  onClick={() => navigate("/shortlists")}
                  className="text-sm flex font-medium text-[#02B491] hover:bg-gray-100 rounded-lg p-1.5"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                    />
                  </svg>
                  View shortlists
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
