import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CompanyDetails from "./company_details";
import RoleDetails from "./role_details";
import Message from "./message";

const initialSteps = [
  { id: "1", name: "Company Information", href: "#", status: "current" },
  { id: "2", name: "Role Information", href: "#", status: "incomplete" },
  { id: "3", name: "Request introductions", href: "#", status: "incomplete" },
];

export default function InterviewRequest({
  setIsInterviewRequest,
  clear,
  setSuccess,
}) {
  const [steps, setSteps] = useState(initialSteps);
  const [activeContent, setActiveContent] = useState("Company Information");
  const navigate = useNavigate();

  function moveToNextStep() {
    const currentStepIndex = steps.findIndex(
      (step) => step.status === "current"
    );
    if (currentStepIndex < steps.length - 1) {
      updateStatus(steps[currentStepIndex + 1]);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  function updateStatus(selectedStep) {
    const newSteps = steps.map((step) => {
      if (step.name === selectedStep.name) {
        return { ...step, status: "current" };
      } else if (steps.indexOf(step) < steps.indexOf(selectedStep)) {
        return { ...step, status: "complete" };
      } else {
        return { ...step, status: "incomplete" };
      }
    });
    setSteps(newSteps);
    setActiveContent(selectedStep.name);
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-10 flex justify-center items-center">
      <div className="relative lg:-mr-80 z-50 rounded-lg items-center lg:w-[50%] h-[80%] bg-white">
        <div className="bg-white z-10 px-10 pt-10 rounded-lg pb-5">
          <button
            onClick={() => setIsInterviewRequest(false)}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <h2 className="text-4xl font-semibold pb-4 text-black">
            Great that you've selected candidates!
          </h2>
          <nav aria-label="Progress">
            <ol
              role="list"
              className="space-y-4 w-[100%] max-w-[1000px] md:flex md:space-x-8 md:space-y-0"
            >
              {steps.map((step) => (
                <li key={step.name} className="font-alliance md:flex-1">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      updateStatus(step);
                    }}
                    className={`group flex flex-col ${
                      step.status === "complete"
                        ? "border-[rgb(0,185,140)] hover:border-[rgb(0,185,140)]"
                        : step.status === "current"
                        ? "border-[rgb(0,185,140)]"
                        : "border-gray-200 hover:border-gray-300"
                    } py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4`}
                  >
                    <span
                      className={`text-sm font-medium ${
                        step.status === "complete"
                          ? "text-[rgb(0,185,140)]"
                          : step.status === "current"
                          ? "text-[rgb(0,185,140)]"
                          : "text-gray-500 group-hover:text-gray-700"
                      }`}
                    >
                      {step.id}
                    </span>
                    <span className="text-sm font-medium font-alliance">
                      {step.name}
                    </span>
                  </a>
                </li>
              ))}
            </ol>
          </nav>
        </div>

        <div className="overflow-y-auto h-[calc(100%-230px)] px-10">
          {activeContent === "Company Information" && (
            <CompanyDetails moveToNext={moveToNextStep} />
          )}
          {activeContent === "Role Information" && (
            <RoleDetails moveToNext={moveToNextStep} />
          )}
          {activeContent === "Request introductions" && (
            <Message
              clear={clear}
              setSuccess={setSuccess}
              setIsInterviewRequest={setIsInterviewRequest}
            />
          )}
        </div>
      </div>
    </div>
  );
}
