import React, { useEffect } from "react";

function OnBoarding() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div data-tf-live="01J302F4SCBAJMV2ZS6Q6B40FS"></div>
    </div>
  );
}

export default OnBoarding;
