import React, { useState, useEffect } from "react";
import axios from "axios";
import EditJobDescriptionModal from "./edit_job_description"; // Adjust the import path as necessary

const ModalRole = ({ onClose, role, setRoleNeedUpdate }) => {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [spotlights, setSpotlights] = useState([]);
  const [isAddSpotlightModalOpen, setIsAddSpotlightModalOpen] = useState(false);
  const [selectedSpotlight, setSelectedSpotlight] = useState(null);
  const [deleteSpotlight, setDeleteSpotlight] = useState(null);
  const [editFormData, setEditFormData] = useState({ name: "" });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [spotlightNeedUpdate, setSpotlightNeedUpdate] = useState(false);
  const [selectedSpotlightTab, setSelectedSpotlightTab] = useState("Dropbox"); // Initialize Loom as the default tab

  const [newSpotlight, setNewSpotlight] = useState({
    name: "",
    url_dropbox: "",
    url_loom: "",
  });

  useEffect(() => {
    const fetchSpotlights = async () => {
      try {
        const token = localStorage.getItem("token");

        // Fetch spotlights
        const spotlightsResponse = await axios.get(
          "/api/spotlights/fetchSpotlights",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const spotlights = spotlightsResponse.data.spotlights;
        const transformedSpotlights = spotlights.map((spotlight) => ({
          id: spotlight["id"],
          dropbox_url: spotlight["URL Dropbox cleaned"] || null,
          loom_url: spotlight["URL Loom cleaned"] || null,
          name: spotlight["Name"],
        }));

        setSpotlights(transformedSpotlights);

        // Set selected spotlight if any
        if (role.spotlight) {
          const spotlightToSelect = transformedSpotlights.find(
            (s) => s.id === role.spotlight[0]
          );
          setSelectedSpotlight(spotlightToSelect || null);
        }

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchSpotlights();
  }, []);

  useEffect(() => {
    const fetchSpotlights = async () => {
      try {
        const token = localStorage.getItem("token");

        // Fetch spotlights
        const spotlightsResponse = await axios.get(
          "/api/spotlights/fetchSpotlights",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const spotlights = spotlightsResponse.data.spotlights;
        const transformedSpotlights = spotlights.map((spotlight) => ({
          id: spotlight["id"],
          dropbox_url: spotlight["URL Dropbox cleaned"] || null,
          loom_url: spotlight["URL Loom cleaned"] || null,
          name: spotlight["Name"],
        }));

        setSpotlights(transformedSpotlights);
        setSpotlightNeedUpdate(false);

        setLoading(false);
      } catch (err) {
        setSpotlights([]);

        setError(err.message);
        setLoading(false);
      }
    };
    if (spotlightNeedUpdate) fetchSpotlights();
  }, [spotlightNeedUpdate]);

  const handleAddSpotlightChange = (event) => {
    const { name, value } = event.target;
    setNewSpotlight((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [formData, setFormData] = useState({
    title: "",
    job_description_link: "",
    email: "",
    key_experience: "",
    compensation: "",
    working_style: "",
    equity: "",
    interview_process: "",
    additional_info: "",
    spotlight: "",
    open_to_applications: false,
    spotlight_context: "",
  });

  const handleTabClick = (tab) => {
    setSelectedSpotlightTab(tab);
  };

  useEffect(() => {
    if (role) {
      setFormData({
        title: role.title || "",
        compensation: role.compensation || "",
        job_description_link: role.job_description_link || "",
        equity: role.equity || "",
        key_experience: role.key_experience || "",
        interview_process: role.interview_process || "",
        working_style: role.working_style || "",
        spotlight: role.spotlight || "",
        open_to_applications: role.open_to_applications || false,
        spotlight_context: role.spotlight_context || "",
      });
    }
  }, [role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFields = () => {
    const errors = {};
    if (!formData.title) errors.title = "Role Title is required.";
    if (!formData.compensation)
      errors.compensation = "Compensation is required.";
    if (!formData.job_description_link)
      errors.job_description_link = "Job Description is required.";
    if (!formData.working_style)
      errors.working_style = "Working Style is required.";
    if (!formData.equity) errors.equity = "Equity is required.";
    return errors;
  };
  const handleSpotlightChange = (spotlight) => {
    setSelectedSpotlight((prev) =>
      prev?.name === spotlight?.name ? null : spotlight
    );
  };

  const openEditModal = (spotlight) => {
    setEditFormData({
      name: spotlight.name,
      id: spotlight.id,
    });
    setIsEditModalOpen(true);
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddSpotlightSubmit = async (event) => {
    event.preventDefault();

    const data = {
      fields: {
        Name: newSpotlight.name,
      },
    };

    if (selectedSpotlightTab === "Loom") {
      data.fields["URL Loom"] = newSpotlight.url_loom;
    } else {
      data.fields["URL Dropbox"] = newSpotlight.url_dropbox;
    }

    try {
      const token = localStorage.getItem("token");
      await axios.post("/api/spotlights/createSpotlight", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.error(err);
    }
    setSpotlightNeedUpdate(true);
    setIsAddSpotlightModalOpen(false);
  };

  const handleDeleteSpotlight = async (event) => {
    event.preventDefault();
    const updatedData = {
      fields: {
        Deleted: true,
      },
      spotlightId: deleteSpotlight.id, // Make sure the ID is included
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/spotlights/updateSpotlightData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`, // Use backticks for string interpolation
          "Content-Type": "application/json",
        },
      });

      // Check if the deleted spotlight is the selected spotlight
      if (selectedSpotlight && selectedSpotlight.id === deleteSpotlight.id) {
        setSelectedSpotlight(null); // Set selectedSpotlight to null if the deleted spotlight is the selected one
      }
    } catch (err) {
      console.error(err); // Log the error for debugging
    }
    setSpotlightNeedUpdate(true);
    setDeleteSpotlight(null);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const updatedData = {
      fields: {
        Name: editFormData.name,
      },
      spotlightId: editFormData.id, // Make sure the ID is included
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/spotlights/updateSpotlightData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`, // Use backticks for string interpolation
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.error(err); // Log the error for debugging
    }
    setSpotlightNeedUpdate(true);
    setIsEditModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const updatedData = {
      fields: {
        "Role Title": formData.title,
        "Specific Experience": formData.key_experience,
        "Interview Process": formData.interview_process,
        "Working Style": formData.working_style,
        Compensation: formData.compensation,
        Equity: formData.equity,
        "Job Description PDF": formData.job_description_link,
        "Spotlight Context": formData.spotlight_context,
        "Open to applications": formData.open_to_applications
          ? "Open to apps"
          : "Not open to apps",
      },
      roleId: role.id,
    };

    if (selectedSpotlight) {
      updatedData.fields.Spotlight = [selectedSpotlight.id];
    } else {
      updatedData.fields.Spotlight = [];
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRoleNeedUpdate(true);
      console.log(response.data);
      onClose();
    } catch (err) {
      console.error("Error updating role data:", err);
      setError("Failed to update role data: " + err.message);
    }
  };

  const handleFileUpload = (url) => {
    setFormData({ ...formData, job_description_link: url });
    setEditMode(false);
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="inline-block align-middle h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-4xl w-full">
          <div className="bg-white p-6 relative">
            <button
              type="button"
              onClick={onClose}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <form onSubmit={handleSubmit}>
              <div className="space-y-12">
                <div className="border-gray-900/10 pb-4">
                  <h2
                    className="text-4xl font-semibold leading-7 text-gray-900"
                    style={{ color: "rgb(0, 185, 140)" }}
                  >
                    {role.title}
                  </h2>

                  <h2 className="block mt-8 text-3xl font-light leading-6 text-gray-900">
                    Role Information
                  </h2>

                  <h2 className="block pt-6 text-md flex items-center font-semibold leading-6 text-gray-900">
                    Job Description <span className="text-black">*</span>{" "}
                    <div className="relative group">
                      <svg
                        onClick={() => {
                          window.open(
                            "https://jumpstart-alumni.notion.site/Writing-a-Job-Description-33504887841241d0a52f5a2a9e23e245",
                            "_blank"
                          );
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 ml-2 cursor-pointer"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                        />
                      </svg>
                      <div className="absolute left-0 hidden mt-1 w-40 p-2 z-50 text-sm text-white bg-[#02B491] rounded-lg shadow-md group-hover:block">
                        For tips on writing a JD click the icon
                      </div>
                    </div>
                  </h2>
                  <div className="mt-1 flex">
                    <div className="relative w-[400px] pr-2 flex items-center">
                      <input
                        type="text"
                        name="job_description_link"
                        id="job-description"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="https://www.jumpstart-uk.com/"
                        value={formData.job_description_link}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex items-center">
                      <button
                        type="button"
                        onClick={() => setEditMode(true)}
                        className="px-4 py-2 text-sm bg-white hover:bg-[#02B491] border-2 border-[#02B491] text-black hover:text-white rounded-lg"
                      >
                        Upload Job Description
                      </button>
                      {formErrors.job_description_link && (
                        <p className="text-red-600 text-sm ml-2">
                          {formErrors.job_description_link}
                        </p>
                      )}
                    </div>
                  </div>
                  {editMode && (
                    <EditJobDescriptionModal
                      onClose={() => setEditMode(false)}
                      formData={formData}
                      setFormData={setFormData}
                      setFile={setFile}
                      file={file}
                      onFileUpload={handleFileUpload}
                    />
                  )}
                  <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                    <div className="flex-1">
                      <div className="mb-4">
                        <label
                          htmlFor="title"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Role Title <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="Sales"
                          value={formData.title}
                          onChange={handleChange}
                        ></input>
                        {formErrors.title && (
                          <p className="text-red-600 text-md">
                            {formErrors.title}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="compensation"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Compensation <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="compensation"
                          id="compensation"
                          className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="£40-50,000 + £10k commission"
                          value={formData.compensation}
                          onChange={handleChange}
                        ></input>
                        {formErrors.compensation && (
                          <p className="text-red-600 text-md">
                            {formErrors.compensation}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="mb-4">
                        <label
                          htmlFor="working_style"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Working Style <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="working_style"
                          id="working_style"
                          autoComplete="working_style"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="Hybrid - 1 day per week in office"
                          value={formData.working_style}
                          onChange={handleChange}
                        ></input>
                        {formErrors.working_style && (
                          <p className="text-red-600 text-md">
                            {formErrors.working_style}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        {" "}
                        <label
                          htmlFor="equity"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Would you be open to offering equity?{" "}
                          <span className="text-black">*</span>
                        </label>
                        <select
                          id="equity"
                          name="equity"
                          value={formData.equity}
                          onChange={handleChange}
                          required
                          className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        >
                          <option value="">Select an option</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="TBC">To be Determined</option>
                        </select>
                        {formErrors.equity && (
                          <p className="text-red-600 text-md">
                            {formErrors.equity}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                    <div className="flex-1">
                      <label
                        htmlFor="interview_process"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Interview Process
                      </label>
                      <textarea
                        id="interview_process"
                        name="interview_process"
                        rows="4"
                        className="block mt-2 w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="A short description of the interview process for this role!"
                        value={formData.interview_process}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="flex-1">
                      <label
                        htmlFor="description"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Key Experience
                      </label>
                      <textarea
                        id="key_experience"
                        name="key_experience"
                        rows="4"
                        className="block mt-2 w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="A few lines on the type of candidate you are looking for!"
                        value={formData.key_experience}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="container mx-auto py-4">
                    <h2 className="block mt-6 text-3xl font-light leading-6 text-gray-900">
                      Video Recording
                    </h2>
                    <div className=" sm:col-span-8 mt-2">
                      {spotlights.length === 0 ? (
                        <>
                          <p className=" text-gray-600 w-[600px]  mb-4">
                            <span className="font-semibold">
                              A video recording is HIGHLY recommended.
                            </span>{" "}
                            It helps candidates self-select whether they’re a
                            good fit - saving you and them time.
                          </p>
                          <button
                            className="text-md px-6 py-2 mb-4 mr-2 rounded-md bg-white hover:bg-[#02B491] text-black border-2 border-[#02B491] hover:text-white"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsAddSpotlightModalOpen(true);
                            }}
                          >
                            Upload a link
                          </button>
                          <button
                            className="text-md px-6 py-2 mb-4 rounded-md hover:bg-white bg-[#02B491] hover:text-black border-2 border-[#02B491] text-white"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                "https://calendly.com/d/cmhy-wb9-823/record-a-video-with-jumpstart"
                              );
                            }}
                          >
                            Record with Jumpstart
                          </button>
                        </>
                      ) : (
                        <div className="flex space-x-4">
                          <div className="flex-1">
                            <p className="mb-6 text-gray-600">
                              Good news - you’ve got a video recording already!
                              If you’d like to attach a video,{" "}
                              <span className="font-bold">
                                make sure you select the relevant recording and
                              </span>{" "}
                              provide relevant context below
                            </p>

                            {spotlights.map((spotlight, index) => (
                              <ul key={index} className="space-y-4 mb-4">
                                <li>
                                  <input
                                    type="radio"
                                    id={`job-${index}`}
                                    name="job"
                                    className="hidden peer"
                                    checked={
                                      selectedSpotlight?.name === spotlight.name
                                    }
                                    onChange={() =>
                                      handleSpotlightChange(spotlight)
                                    }
                                  />
                                  <label
                                    htmlFor={`job-${index}`}
                                    className="inline-flex relative items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-[#02B491] peer-checked:text-[#02B491] hover:text-gray-900 hover:bg-gray-100"
                                  >
                                    <div className="block w-[350px]">
                                      <div className="w-full text-lg font-semibold">
                                        {spotlight.name}
                                      </div>

                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="size-6 hover:text-red-500 text-black absolute top-2 right-2"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setDeleteSpotlight(spotlight);
                                        }}
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                        />
                                      </svg>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="size-6 hover:text-[#02B491] text-black absolute top-2 right-10"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openEditModal(spotlight);
                                        }}
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                        />
                                      </svg>
                                    </div>
                                  </label>
                                </li>
                              </ul>
                            ))}
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSpotlightChange(null);
                              }}
                            >
                              <div
                                className={`inline-flex mb-2 relative items-center justify-between w-full p-5 text-gray-900 bg-white border  rounded-lg cursor-pointer ${
                                  selectedSpotlight === null
                                    ? "border-[#02B491] border text-[#02B491]"
                                    : "border-gray-200"
                                } hover:text-gray-900 hover:bg-gray-100`}
                              >
                                <div
                                  className={`w-full flex justify-center text-lg font-semibold ${
                                    selectedSpotlight === null
                                      ? "border-[#02B491 text-[#02B491]"
                                      : ""
                                  } `}
                                >
                                  No linked video
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsAddSpotlightModalOpen(true);
                              }}
                            >
                              <div className="inline-flex relative items-center justify-between w-full p-5 text-gray-900 bg-white border-dashed border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-[#02B491] peer-checked:text-[#02B491] hover:text-gray-900 hover:bg-gray-100">
                                <div className="w-full  flex justify-center text-lg font-semibold">
                                  + Add Video Recording
                                </div>
                              </div>
                            </div>
                            {selectedSpotlight && (
                              <div className="col-span-full pt-4">
                                <label
                                  htmlFor="key_experience"
                                  className="block text-md font-semibold leading-6 text-gray-900"
                                >
                                  Additional context
                                </label>
                                <div className="mt-2">
                                  <textarea
                                    id="spotlight_context"
                                    name="spotlight_context"
                                    rows="2"
                                    className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                                    placeholder="This is a recording we did for a different role but should give a good flavour of our company!"
                                    value={formData.spotlight_context}
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                              </div>
                            )}
                          </div>

                          {selectedSpotlight ? (
                            <div className="flex-1">
                              {selectedSpotlight ? (
                                selectedSpotlight.dropbox_url ? (
                                  <video
                                    src={selectedSpotlight.dropbox_url}
                                    className="h-[300px] w-[500px] object-cover rounded-xl bg-[var(--overlay-2)]"
                                    controls
                                    onError={(e) => {
                                      console.error(
                                        "Failed to load video at",
                                        e.target.src
                                      );
                                    }}
                                  />
                                ) : (
                                  <iframe
                                    src={selectedSpotlight.loom_url}
                                    className="h-[300px] w-[400px] object-cover rounded-xl bg-[var(--overlay-2)]"
                                  ></iframe>
                                )
                              ) : (
                                <p>No spotlight selected</p>
                              )}
                            </div>
                          ) : (
                            <div className="flex-1 w-[300px]"></div>
                          )}
                        </div>
                      )}
                    </div>

                    {isEditModalOpen && (
                      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white w-[300px] p-6 rounded-lg shadow-lg">
                          <h2 className="text-2xl mb-4">
                            Edit Video Recording
                          </h2>

                          <div className="mb-4">
                            <label className="block text-md font-medium text-gray-700">
                              Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={editFormData.name}
                              onChange={handleEditChange}
                              className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                              required
                            />
                          </div>

                          <div className="flex justify-end">
                            <button
                              type="button"
                              onClick={() => setIsEditModalOpen(false)}
                              className="mr-4 px-4 py-2 bg-gray-300 rounded-md"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleEditSubmit}
                              type="submit"
                              className="px-4 py-2 bg-[#02B491] text-white rounded-md"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {deleteSpotlight && (
                      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg">
                          <h2 className="text-2xl font-medium mb-4">
                            Delete Spotlight
                          </h2>
                          <h2 className="text-lg mb-4">
                            Are you sure you want to delete this spotlight?
                          </h2>
                          <div className="flex justify-end">
                            <button
                              type="button"
                              onClick={() => setDeleteSpotlight(null)}
                              className="mr-4 px-4 py-2 bg-gray-300 rounded-md"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleDeleteSpotlight}
                              className="px-4 py-2 bg-red-500 text-white rounded-md"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {isAddSpotlightModalOpen && (
                      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg">
                          <h2 className="text-2xl ">Add Video Recording</h2>
                          <p className="mb-4 text-gray-600">
                            Click{" "}
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `https://jumpstart-alumni.notion.site/Very-quick-guidance-on-recording-your-startup-s-video-5ce5688a610648c89f59d9382e42c226?pvs=4`,
                                  "_blank"
                                );
                              }}
                              className="text-blue-500 hover:underline cursor-pointer"
                            >
                              here
                            </span>{" "}
                            for very simple instructions
                          </p>
                          <div className="border-b mb-2 border-gray-200 dark:border-gray-700">
                            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 ">
                              <li className="me-2">
                                <a
                                  onClick={() => handleTabClick("Dropbox")}
                                  className={`inline-flex cursor-pointer items-center justify-center p-4 border-b-2 ${
                                    selectedSpotlightTab === "Dropbox"
                                      ? "text-[#007ee5] border-[#007ee5]"
                                      : "border-transparent"
                                  } rounded-t-lg hover:text-gray-600 hover:border-gray-300 group`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-4 h-4 me-2 text-gray-400"
                                    viewBox="25 24.999 850 790.592"
                                  >
                                    <g
                                      fill={`${
                                        selectedSpotlightTab === "Dropbox"
                                          ? "#007ee5"
                                          : "border-transparent"
                                      }`}
                                    >
                                      <path d="M275.037 24.999L25 188.255l172.886 138.448L450 171.023zM25 465.16l250.037 163.256L450 482.374l-252.114-155.67zM450 482.374l174.963 146.042L875 465.16 702.114 326.703z" />
                                      <path d="M875 188.255L624.963 24.999 450 171.024l252.114 155.68zM450.513 513.797l-175.476 145.61-75.09-49.028v54.959L450.513 815.59 701.08 665.338v-54.96l-75.09 49.029z" />
                                    </g>
                                  </svg>
                                  Dropbox
                                </a>
                              </li>
                              <li className="me-2">
                                <a
                                  onClick={() => handleTabClick("Loom")}
                                  className={`inline-flex cursor-pointer items-center justify-center p-4 border-b-2 ${
                                    selectedSpotlightTab === "Loom"
                                      ? "text-[#625DF5] border-[#625DF5]"
                                      : "border-transparent"
                                  } rounded-t-lg hover:text-gray-600 hover:border-gray-300 group`}
                                >
                                  <svg
                                    className="w-5 h-5 me-1 text-gray-400"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill={`${
                                        selectedSpotlightTab === "Loom"
                                          ? "#625DF5"
                                          : "border-transparent"
                                      }`}
                                      d="M15 7.222h-4.094l3.546-2.047-.779-1.35-3.545 2.048 2.046-3.546-1.349-.779L8.78 5.093V1H7.22v4.094L5.174 1.548l-1.348.779 2.046 3.545-3.545-2.046-.779 1.348 3.546 2.047H1v1.557h4.093l-3.545 2.047.779 1.35 3.545-2.047-2.047 3.545 1.35.779 2.046-3.546V15h1.557v-4.094l2.047 3.546 1.349-.779-2.047-3.546 3.545 2.047.779-1.349-3.545-2.046h4.093L15 7.222zm-7 2.896a2.126 2.126 0 110-4.252 2.126 2.126 0 010 4.252z"
                                    />
                                  </svg>
                                  Loom
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="mb-4">
                            <label className="block text-md font-medium text-gray-700">
                              Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              placeholder="Jumpstart: Match Maker (2024.07)"
                              value={newSpotlight.name}
                              onChange={handleAddSpotlightChange}
                              className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                              required
                            />
                          </div>

                          <div className="my-4">
                            <label className="block text-md flex font-medium text-gray-700">
                              URL
                            </label>
                            <input
                              type="url"
                              name={`${
                                selectedSpotlightTab === "Loom"
                                  ? "url_loom"
                                  : "url_dropbox"
                              }`}
                              placeholder={`${
                                selectedSpotlightTab === "Loom"
                                  ? "https://www.loom.com/share/412a3abc720e4f8e946b2633086c902d?sid=01acb4b3-2943-4330-a56c-a7e12c752339"
                                  : "https://www.dropbox.com/scl/fi/v42ug5su2fa6q77exssj0/2024.08.01_Hokodo.mp4?rlkey=afdeb5863114ad0z00ygjn1uv&dl=0"
                              }`}
                              value={
                                selectedSpotlightTab === "Loom"
                                  ? newSpotlight.url_loom
                                  : newSpotlight.url_dropbox
                              }
                              onChange={handleAddSpotlightChange}
                              className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 sm:text-md sm:leading-6"
                              required
                            />
                          </div>
                          <div className="flex justify-end">
                            <button
                              type="button"
                              onClick={() => setIsAddSpotlightModalOpen(false)}
                              className="mr-4 px-4 py-2 bg-gray-300 rounded-md"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleAddSpotlightSubmit}
                              className="px-4 py-2 bg-[#02B491] text-white rounded-md"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                    <div className="flex-1">
                      <h2 className="block text-3xl font-light leading-6 text-gray-900">
                        Receiving applications
                      </h2>

                      <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8 pb-4">
                        <div className="flex-1">
                          <div className="col-span-full">
                            <div className="flex items-center">
                              <input
                                id="open_to_applications"
                                type="checkbox"
                                checked={formData.open_to_applications}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    open_to_applications: e.target.checked,
                                  })
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="open_to_applications"
                                className="ms-2 text-md font-medium text-gray-900 dark:text-gray-300"
                              >
                                I am happy to receive applications for this
                                role.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start gap-x-6">
                <button
                  type="submit"
                  className="px-2 py-2 text-sm mr-1 hover:text-black flex rounded-lg font-medium transform hover:bg-white bg-[#02B491] text-white transition duration-400 border-2 border-[#02B491]"
                >
                  Save and update
                </button>
              </div>
              {error && <p className="text-red-600 text-md">{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRole;
