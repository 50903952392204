// ShortlistedContext.js
import React, { createContext, useContext, useState } from "react";

const ShortlistedContext = createContext();

export const useShortlisted = () => useContext(ShortlistedContext);

export const ShortlistedProvider = ({ children }) => {
  const [shortlistedCount, setShortlistedCount] = useState(0);

  return (
    <ShortlistedContext.Provider
      value={{ shortlistedCount, setShortlistedCount }}
    >
      {children}
    </ShortlistedContext.Provider>
  );
};
