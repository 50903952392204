import React, { useState } from "react";
import location from "../../images/location.svg";
import url from "../../images/url.svg";
import Modal from "./modal_company.js";

function CompanyDetails({
  activeStep,
  setActiveStep,
  company,
  setCompany,
  setCompanyNeedUpdate,
  loading,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility
  const [showFullDescription, setShowFullDescription] = useState(false); // State to handle full description visibility

  const requiredFields = ["name", "description", "location", "website"];
  const missingRequiredFields = requiredFields.filter(
    (field) => !company[field]
  );

  const renderField = (label, value, fieldName) => {
    if (loading) {
      if (fieldName === "description") {
        return (
          <div className="space-y-2 mb-2">
            <div className="h-6 bg-gray-200 rounded-full "></div>
            <div className="h-6 bg-gray-200 rounded-full w-[700px]"></div>
            <div className="h-6 bg-gray-200 rounded-full  w-full"></div>
            <div className="h-6 mr-2 bg-gray-200 rounded-full  w-28 mb-2"></div>
          </div>
        );
      } else {
        return (
          <div className="h-6 mr-2 bg-gray-200 rounded-full  w-28 mb-2"></div>
        );
      }
    }

    if (fieldName === "website" && value) {
      return (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline text-blue-600"
        >
          {value}
        </a>
      );
    }

    return value ? (
      <span>{value}</span>
    ) : (
      <span className="text-red-600">(missing {fieldName})</span>
    );
  };

  const renderDescription = (description) => {
    const maxLength = 100; // Maximum length for the truncated description
    if (!showFullDescription && description.length > maxLength) {
      return (
        <div>
          {description.slice(0, maxLength)}...
          <span
            onClick={() => setShowFullDescription(true)}
            className="text-blue-600 cursor-pointer hover:underline"
          >
            View More
          </span>
        </div>
      );
    }
    return (
      <>
        {description}
        {description.length > maxLength && (
          <span
            onClick={() => setShowFullDescription(false)}
            className="text-blue-600 cursor-pointer hover:underline"
          >
            View Less
          </span>
        )}
      </>
    );
  };

  return (
    <div
      className={`relative ${
        activeStep === "Company Information" ? "" : "flex"
      }`}
    >
      <h2
        className={`whitespace-nowrap block pb-6 text-4xl font-normal leading-6 ${
          activeStep === "Company Information"
            ? "text-[#02B491]"
            : "text-gray-900"
        }`}
      >
        1. Company Information
      </h2>
      {activeStep === "Company Information" ? (
        <div className="mb-10">
          <div className="border-2 relative rounded-lg border-[#02B491] max-w-[800px] h-auto w-auto pb-4">
            <h2
              onClick={() => setIsModalOpen(true)}
              className="pt-2 pl-4 absolute right-4 text-md font-semibold text-[#02B491] cursor-pointer hover:underline"
            >
              Edit
            </h2>
            <div className="flex items-center">
              <h2 className="pt-2 pl-4 text-xl font-semibold text-[#02B491]">
                {renderField("Name", company.name, "name")}
              </h2>
              <h2 className="pt-2 pl-4 text-md text-gray-600 flex font-semibold">
                <img src={location} className="h-5" alt="location icon" />
                {renderField("Location", company.location, "location")}
              </h2>
              <h2 className="pt-2 pl-4 text-md text-gray-600 font-semibold">
                {renderField("Funding", company.funding, "funding")}
              </h2>
              <h2 className="pt-2 pl-4 text-md text-blue-600 flex font-semibold">
                {renderField("Website", company.website, "website")}
              </h2>
            </div>
            <h2 className="pt-2 pl-4 pr-4 text-md font-semibold text-gray-600">
              <span className="text-black">Company description: </span>
              {renderDescription(company.description)}
            </h2>
          </div>
          {missingRequiredFields.length > 0 ? (
            <button
              onClick={() => setIsModalOpen(true)}
              className="px-8 py-2 mt-4 rounded-md bg-[#02B491] text-white font-bold transition duration-200 hover:bg-white hover:text-black border-2 border-[#02B491]"
            >
              Complete company information
            </button>
          ) : (
            <button
              onClick={() => setActiveStep("Role Details")}
              className="px-8 py-2 mt-4 rounded-md bg-[#02B491] text-white font-bold transition duration-200 hover:bg-white hover:text-black border-2 border-[#02B491]"
            >
              Continue
            </button>
          )}
          {isModalOpen && (
            <Modal
              onClose={() => setIsModalOpen(false)}
              company={company}
              setCompanyNeedUpdate={setCompanyNeedUpdate}
            />
          )}
        </div>
      ) : (
        <div className="pl-4">
          <div
            className="text-[#02B491] absolute top-0 right-0 text-lg cursor-pointer hover:underline"
            onClick={() => setIsModalOpen(true)}
          >
            Edit
          </div>
          <h2 className="pl-4 text-xl font-semibold text-black">
            {renderField("Name", company.name, "name")}
          </h2>
          <h2 className="pl-4 text-md text-gray-600 flex font-semibold">
            {renderField("Location", company.location, "location")}
          </h2>
          <h2 className="pl-4 text-md text-gray-600 font-semibold">
            {renderField("Funding", company.funding, "funding")}
          </h2>
          <h2 className="pl-4 text-md text-blue-600 flex font-semibold">
            {renderField("Website", company.website, "website")}
          </h2>
          <h2 className="pb-6 pl-4 text-md text-gray-600 flex font-semibold">
            {renderDescription(company.description)}
          </h2>
          {isModalOpen && (
            <Modal
              onClose={() => setIsModalOpen(false)}
              company={company}
              setCompanyNeedUpdate={setCompanyNeedUpdate}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default CompanyDetails;
