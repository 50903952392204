import React, { useState, useEffect, useRef } from "react";

const MultiSelect = ({ options, selectedOptions, onChange, text }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      onChange(selectedOptions.filter((item) => item !== option));
    } else {
      onChange([...selectedOptions, option]);
    }
  };

  const handleClearFilter = () => {
    onChange([]);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative pl-2 flex" ref={dropdownRef}>
      <div
        className="py-2 px-4 flex rounded-lg border items-center justify-center border-gray-500 hover:border-black hover:bg-black/5 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{text}</span>
        {selectedOptions.length > 0 && selectedOptions.length < 2 ? (
          <div className="flex hidden">
            {selectedOptions.map((selectedOption) => (
              <div
                key={selectedOption}
                className="bg-green-100 font-normal py-1 px-2 ml-2 text-xs rounded-md"
              >
                {selectedOption}
              </div>
            ))}
          </div>
        ) : selectedOptions.length >= 2 ? (
          <div className="flex hidden">
            <div className="bg-green-100 font-normal py-1 px-2 ml-2 text-xs rounded-md">
              {selectedOptions.length} selected
            </div>
          </div>
        ) : null}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6 ml-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="absolute top-12 pt-1 pb-1 z-10 bg-white border border-gray-300 rounded-md mt-1 max-w-[220px]">
          {options.map((option) => (
            <label
              key={option}
              className="flex text-sm items-center mx-1 rounded-md p-2 cursor-pointer hover:bg-gray-100"
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => handleOptionChange(option)}
                className="mr-2 "
              />
              <h2 className="whitespace-nowrap text-sm">{option}</h2>
            </label>
          ))}
          {selectedOptions.length > 0 && (
            <div className="border-t mt-1">
              <div
                className="p-1 mt-1 mx-1 flex rounded-md justify-center cursor-pointer text-sm hover:bg-gray-100"
                onClick={handleClearFilter}
              >
                <h2 className="">Clear filter</h2>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
