import React, { useEffect, useState, useCallback, useRef } from "react";
import { useAsyncError, useNavigate } from "react-router-dom";
import axios from "axios";
import CandidatesLoading from "../../components/candidates/candidates_loading.js";
import DeleteIcon from "../../images/delete-icon.png"; // Importing the delete icon
import DeleteIconRed from "../../images/delete-icon-red.png"; // Importing the delete icon
import EditIconGreen from "../../images/edit-icon-green.png"; // Importing the green edit icon
import EditIcon from "../../images/edit-icon.png"; // Importing the edit icon
import HoverDevCards from "../../components/home/ressource_card.js";
import CandidateSneakPeek from "../../components/home/candidate_sneak_peek.js";
import Swiper from "swiper";
import "./swiper.min.css";
import ApplicationSneakPeek from "../../components/home/application_sneak_peek.js";

export default function Home() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [newCandidates, setNewCandidates] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingApplications, setLoadingApplications] = useState(true);
  const [error, setError] = useState(null);
  const [hoveredEditIconId, setHoveredEditIconId] = useState(null);
  const [hoveredRoleId, setHoveredRoleId] = useState(null);
  const [candidatesRequest, setCandidatesRequest] = useState(0);
  const [interviewNeedUpdate, setInterviewNeedUpdate] = useState(true);
  const [initializeCarousel, setInitializeCarousel] = useState(true);

  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  const swiperInstances = useRef([]);

  const handleDelete = async (roleId) => {
    try {
      const updatedData = {
        fields: { Deleted: true },
        roleId: roleId,
      };
      const token = localStorage.getItem("token");
      await axios.patch(`/api/roles/updateRoleData`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRoles((prevData) => prevData.filter((role) => role.id !== roleId));
    } catch (err) {
      setError(err.message);
    }
  };

  const initializeCarousels = () => {
    // Destroy any existing Swiper instances if they exist
    swiperInstances.current.forEach((swiper) => {
      if (swiper) {
        swiper.destroy(true, true);
      }
    });

    // Initialize the Swiper instance for the candidates carousel
    const candidatesCarousel = document.querySelector(".carousel-candidates");
    if (candidatesCarousel) {
      swiperInstances.current[0] = new Swiper(candidatesCarousel, {
        slidesPerView: "auto",
        grabCursor: true,
        loop: false,
        centeredSlides: false,
        initialSlide: 0,
        spaceBetween: 24,
        watchSlidesProgress: true,
        navigation: {
          nextEl: `.carousel-next-1`,
          prevEl: `.carousel-prev-1`,
        },
      });
    }

    // Initialize the Swiper instance for the applications carousel
    const applicationsCarousel = document.querySelector(
      ".carousel-applications"
    );
    if (applicationsCarousel) {
      swiperInstances.current[1] = new Swiper(applicationsCarousel, {
        slidesPerView: "auto",
        grabCursor: true,
        loop: false,
        centeredSlides: false,
        initialSlide: 0,
        spaceBetween: 24,
        watchSlidesProgress: true,
        navigation: {
          nextEl: `.carousel-next-2`,
          prevEl: `.carousel-prev-2`,
        },
      });
    }
  };

  useEffect(() => {
    if (
      !loading &&
      candidates.length > 0 &&
      !loadingApplications &&
      initializeCarousel
    ) {
      initializeCarousels();
      setInitializeCarousel(false);
    }
  }, [loading, candidates, loadingApplications]);

  const parseSalaryRange = (salary) => {
    const salaryRange = salary.match(/£(\d+)k?-?£?(\d+)?k?/i);
    if (salaryRange) {
      const minSalary = parseInt(salaryRange[1]) * 1000;
      const maxSalary = salaryRange[2]
        ? parseInt(salaryRange[2]) * 1000
        : minSalary;
      return { minSalary, maxSalary };
    }
    return { minSalary: null, maxSalary: null };
  };

  const fetchRoles = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/roles/fetchRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const roles = response.data.roles;

      // Transform and sort roles
      const transformedData = roles
        .map((role) => {
          const createdDate = new Date(role["Role creation data"]);
          const createdMonthYear = createdDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          });
          return {
            id: role["id"],
            createdTime: role["Role creation data"],
            createdMonthYear: createdMonthYear,
            equity: role["Equity"],
            role_title: role["Role Title"],
            hiring_lead_name: role["Hiring Lead Name"],
            hiring_lead_email: role["Hiring Lead Email Address"],
            hiring_lead_linkedin: role["Hiring Lead Linkedin"],
            key_experience: role["Specific Experience"],
            job_description_link: role["Job Description PDF"],
            startup: role["Startup"],
            compensation: role["Compensation"],
            interviewProcess: role["Interview Process"],
            working_style: role["Working Style"],
            spotlight: role["Spotlight"],
            url_id: role["URL ID"],
            status: role["Open to applications"] || false, // Assuming status is a field in your data
          };
        })
        // Prioritize roles that are "Open to applications"
        .sort((a, b) => {
          if (a.status === b.status) {
            // If both roles have the same status, sort by creation time (latest first)
            return new Date(b.createdTime) - new Date(a.createdTime);
          }
          return a.status ? -1 : 1; // Move roles with 'Open to applications' to the top
        });

      // Select only the last 3 roles
      setRoles(transformedData.slice(0, 3));
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchNewCandidates = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "/api/candidates/fetchCandidates?limit=5",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const candidates = response.data.candidates;

      const transformedData = candidates.map((candidate) => {
        const fields = candidate.fields || {};
        const mostRecentCohort = fields["Most recent cohort"] || "";
        let stream = "";

        if (/^JS\d+$/.test(mostRecentCohort)) {
          stream = "Generalist";
        } else if (/^JSS\d+$/.test(mostRecentCohort)) {
          stream = "Sales";
        } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
          stream = "Software Engineering";
        } else if (/^JSM\d+$/.test(mostRecentCohort)) {
          stream = "Marketing";
        }

        let sweRoles = fields["SWE relevant roles"] || "";
        if (sweRoles) {
          sweRoles = Array.isArray(sweRoles)
            ? sweRoles[0].split(",").map((role) => role.trim())
            : sweRoles.split(",").map((role) => role.trim());
          sweRoles = transformRoles(sweRoles);
        } else {
          sweRoles = [];
        }

        let salary;
        if (stream === "Generalist" || stream === "Marketing") {
          salary = fields["Salary - cleaned"] || "";
        } else if (stream === "Sales") {
          salary = fields["Base range + commission"] || "";
        } else if (stream === "Software Engineering") {
          salary = fields["SWE Salary - cleaned"] || "";
        }

        const { minSalary, maxSalary } = parseSalaryRange(salary);

        return {
          id: candidate["id"] || "",
          url_id: fields["ID"] || "",
          minimum_salary: minSalary || 0,
          maximum_salary: maxSalary || 0,
          createdTime: candidate["createdTime"] || "",
          years_of_exp: fields["Years of exp (or commercial exp)"] || 0,
          cv: fields["Raw CV"] || "",
          video: fields["Raw video intro"] || "",
          linkedin: fields["LinkedIn raw URL"] || "",
          industry_exp: fields["Industry experience"] || undefined,
          name: fields["Name"] || "",
          lastName: fields["Surname"] || "",
          education: fields["Degree details"] || undefined,
          roles: fields["Generalist roles suitable for"] || "",
          salary: salary || "",
          email: fields["Email"] || "",
          image: fields.Photo ? fields.Photo[0].url : null,
          work: fields["Raw work experience"] || "",
          working_style: fields["Preferred working style G/M/S"] || "",
          github: fields["Github"] || "",
          swe_roles: sweRoles,
          languages: fields["Languages"] || "",
          time_coding: fields["Time spent coding"] || "",
          description: fields["Top achievement"] || "",
          mostRecentCohort: fields["Most recent cohort"] || "",
          recentTarget: fields["Recent target achieved"] || "",
          stream: stream,
          search_fields: fields["Search Aggregate Fields"] || "", // Ensure this field is correctly populated
          sales:
            fields["Proven Sales Experience / High Potential for Sales"] || "",
        };
      });

      setCandidates(transformedData);
      setLoading(false);
      console.log(transformedData);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNewCandidates();
    fetchRoles();
  }, [fetchNewCandidates, fetchRoles]);

  const toggleStatus = async (itemId, currentStatus) => {
    try {
      const updatedData = {
        fields: { "Open to applications": !currentStatus },
        roleId: itemId,
      };
      const token = localStorage.getItem("token");
      await axios.patch(`/api/roles/updateRoleData`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setRoles((prevData) =>
        prevData.map((item) =>
          item.id === itemId ? { ...item, status: !currentStatus } : item
        )
      );
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const transformRoles = (roles) => {
      const roleSet = new Set(roles);
      if (roleSet.size === 1) {
        if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
        if (roleSet.has("Front End Developer")) return ["Front End"];
        if (roleSet.has("Back End Developer")) return ["Back End"];
      }
      if (
        roleSet.has("Front End Developer") &&
        roleSet.has("Back End Developer")
      ) {
        return ["Full Stack"];
      }
      if (roleSet.has("Full Stack Developer")) {
        return ["Full Stack"];
      }
      return roles;
    };

    const fetchInterviews = async () => {
      try {
        const token = localStorage.getItem("token");
        const stage = "Application pending"; // New stage parameter
        const response = await axios.get("/api/interviews/fetchInterviews", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { stage: encodeURIComponent(stage) }, // Include the stage parameter in the request
        });

        const interviews = response.data.interviews;

        console.log(response);

        const transformedCandidate = interviews.map((interview) => {
          const mostRecentCohort =
            interview["Most recent cohort (from Candidate)"];
          let stream = "";

          if (/^JS\d+$/.test(mostRecentCohort)) {
            stream = "Generalist";
          } else if (/^JSS\d+$/.test(mostRecentCohort)) {
            stream = "Sales";
          } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
            stream = "Software Engineering";
          } else if (/^JSM\d+$/.test(mostRecentCohort)) {
            stream = "Marketing";
          }

          let sweRoles = interview["SWE relevant roles"];
          if (sweRoles) {
            sweRoles = Array.isArray(sweRoles)
              ? sweRoles[0].split(",").map((role) => role.trim())
              : sweRoles.split(",").map((role) => role.trim());
            sweRoles = transformRoles(sweRoles);
          } else {
            sweRoles = [];
          }

          let salary;
          if (stream === "Generalist" || stream === "Marketing") {
            salary = interview["Salary - cleaned (from Candidate)"];
          } else if (stream === "Sales") {
            salary = interview["Base range + commission (from Candidate)"];
          } else if (stream === "Software Engineering") {
            salary = interview["SWE Salary - cleaned (from Candidate)"];
          }

          return {
            role: interview["Role"]?.[0] || undefined,
            ranking: interview["Ranking"] || undefined,
            role_title: interview["Role Title (from Role)"] || undefined,
            interviewId: interview["id"] || undefined,
            url_id: interview["ID (from Candidate)"] || undefined,
            stage: interview["Stage"] || undefined,
            linkedin:
              interview["LinkedIn raw URL (from Candidate)"]?.[0] || undefined,
            cv: interview["Raw CV (from Candidate)"]?.[0] || undefined,
            name: interview["Name (from Candidate)"]?.[0] || undefined,
            lastName: interview["Surname (from Candidate)"]?.[0] || undefined,
            video:
              interview["Raw video intro (from Candidate)"]?.[0] || undefined,
            education:
              interview["Degree details (from Candidate)"]?.[0] || undefined,
            roles:
              interview["Generalist roles suitable for (from Candidate)"] ||
              undefined,
            salary: salary || undefined,
            email: interview["Email (from Candidate)"]?.[0] || undefined,
            working_style:
              interview["Preferred working style G/M/S (from Candidate)"] ||
              undefined,
            image: interview["Photo (from Candidate)"]?.[0]?.url || undefined,
            work:
              interview["Raw work experience (from Candidate)"]?.[0] ||
              undefined,
            languages:
              interview["Languages & technologies (from Candidate)"] ||
              undefined,
            description:
              interview["Top achievement (from Candidate)"] || undefined,
            mostRecentCohort:
              interview["Most recent cohort (from Candidate)"]?.[0] ||
              undefined,
            recentTarget:
              interview["Recent target achieved (from Candidate)"] || undefined,
            stream: stream || undefined, // Add the stream field
            rationale: interview["Application Rationale"] || undefined,
            sales:
              interview[
                "Proven Sales Experience / High Potential for Sales (from Candidate)"
              ] || undefined,
          };
        });

        setApplications(transformedCandidate);

        console.log(transformedCandidate);

        const requestCount = transformedCandidate.filter(
          (candidate) => candidate.stage === "Application pending"
        ).length;
        setCandidatesRequest(requestCount);

        setLoadingApplications(false);
      } catch (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === "Internal Server Error" &&
          err.response.data.error ===
            "Error: No interviews found for this company"
        ) {
          setCandidates([]); // Set empty state
        } else {
          setError(err.message);
        }
        setLoadingApplications(false);
      }
    };

    if (!interviewNeedUpdate) return;

    fetchRoles();
    fetchInterviews();
    setInterviewNeedUpdate(false);
  }, [interviewNeedUpdate]);

  const renderLoadingState = () => {
    return (
      <div className="relative max-w-[1400px] overflow-x-auto border-2 sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-3">
                Role Title
              </th>
              <th scope="col" className="px-4 py-3">
                Salary Compensation
              </th>
              <th scope="col" className="px-4 py-3">
                Job Description
              </th>
              <th scope="col" className="px-4 py-3">
                Hiring Manager Name
              </th>
              <th scope="col" className="px-4 py-3">
                Hiring Manager Email
              </th>
              <th scope="col" className="px-4 py-3">
                Created Month
              </th>
              <th scope="col" className="px-4 py-3"></th>
              <th scope="col" className="px-4 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {[...Array(3)].map((_, index) => (
              <tr key={index} className="bg-white border-b">
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-32"></div>
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-28"></div>
                </td>
                <td className="px-4 py-4 font-medium text-blue-600 hover:underline">
                  Job Description
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-32"></div>
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-40"></div>
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-28"></div>
                </td>
                <td className="px-4 py-4">
                  <img
                    className="inline-block h-6 cursor-pointer"
                    src={EditIcon}
                  />
                </td>
                <td className="px-4 py-4">
                  <img
                    className="inline-block h-6 cursor-pointer"
                    src={DeleteIcon}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="relative md:pl-56 bg-gray-100 min-h-screen overflow-x-hidden">
      <div className="flex ml-auto h-full pb-20 justify-center items-center bg-gray-100">
        <div className="max-w-[1500px] w-full">
          <div className="bg-white px-4 pt-2 pb-2 fixed top-18 z-50 w-full border-b flex">
            <div className="flex justify-left items-center">
              <div>
                <h2 className="text-3xl">Home</h2>
                <div className="mt-2">
                  You have <span className="font-bold">3 </span> active roles
                  and <span className="font-bold">20 </span> in process
                  candidates.
                </div>
              </div>
            </div>
          </div>
          <div className="flex pt-32 pl-8 mb-4">
            <div className="w-[300px] border-gray-200 rounded-md border bg-white mr-4">
              <div className="flex p-4">
                <div className="mr-2 rounded-md bg-[#02B491]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="size-8 m-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                </div>

                <div>
                  <p className="text-sm text-gray-600">
                    Roles open to applicants
                  </p>
                  <h2 className="text-2xl font-semibold text-black">5</h2>
                </div>
              </div>
              <div className="bg-gray-50 p-4 rounded-b-md">
                <p className=" cursor-pointer hover:text-[#02B491] text-black flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  View all
                </p>
              </div>
            </div>
            <div className="w-[300px] border-gray-200 rounded-md border bg-white mr-4">
              <div className="flex p-4">
                <div className="mr-2 rounded-md bg-[#02B491]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="size-8 m-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                    />
                  </svg>
                </div>
                <div>
                  <p className="text-sm text-gray-600">
                    Shortlisted candidates
                  </p>
                  <h2 className="text-2xl font-semibold text-black">5</h2>
                </div>
              </div>
              <div className="bg-gray-50 p-4 rounded-b-md">
                <p className=" cursor-pointer hover:text-[#02B491] text-black flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  View all
                </p>
              </div>
            </div>
            <div className="w-[300px] border-gray-200 rounded-md border bg-white mr-4">
              <div className="flex p-4">
                <div className="mr-2 rounded-md bg-[#02B491]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="size-8 m-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
                    />
                  </svg>
                </div>
                <div>
                  <p className="text-sm text-gray-600">
                    Applications to review
                  </p>
                  <h2 className="text-2xl font-semibold text-black">5</h2>
                </div>
              </div>
              <div className="bg-gray-50 p-4 rounded-b-md">
                <p className=" cursor-pointer hover:text-[#02B491] text-black flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  View all
                </p>
              </div>
            </div>
            <div className="w-[300px] border-gray-200 rounded-md border bg-white">
              <div className="flex p-4">
                <div className="mr-2 rounded-md bg-[#02B491]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="size-8 m-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                    />
                  </svg>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Ongoing interviews</p>
                  <h2 className="text-2xl font-semibold text-black">5</h2>
                </div>
              </div>
              <div className="bg-gray-50 p-4 rounded-b-md">
                <p className=" cursor-pointer hover:text-[#02B491] text-black flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  View all
                </p>
              </div>
            </div>
          </div>
          <div className="pl-8">
            <div className="pb-4">
              <h2 className="text-3xl">Resources</h2>
              <div>All our top tips to find the next gem!</div>
            </div>
            <HoverDevCards />
          </div>
          <div className="pt-10 pl-8">
            <div className="pb-4">
              <h2 className="text-3xl">Applications</h2>
              <div>
                You have {applications.length} applicants waiting for a response
              </div>
            </div>
            {loadingApplications ? (
              <CandidatesLoading />
            ) : applications.length === 0 ? (
              <div className="text-center w-[400px] rounded-md mt-6 border-dashed hover:border-[#02B491] border-gray-300 cursor-pointer border-2 p-4">
                <p className="text-xl text-gray-500">No applications found</p>
                <div className="pt-2">
                  <button
                    onClick={() => navigate("/roles")}
                    className="px-4 mb-2 justify-center w-full py-2 mt-2 bg-[#02B491] text-white rounded-md flex border-2 border-[#02B491] hover:bg-[#00F2C2] hover:border-[#00F2C2] hover:text-black items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6 mr-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                      />
                    </svg>
                    Open more roles to applicants
                  </button>
                  <button
                    onClick={() => navigate("/new-role")}
                    className="px-4 justify-center w-full py-2 mt-2 hover:bg-[#00F2C2] hover:border-[#00F2C2] rounded-md flex border-2 border-[#02B491] text-black items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6 mr-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    Create new role
                  </button>
                </div>

                <p className="text-gray-400"></p>
              </div>
            ) : (
              <div>
                {loadingApplications ? (
                  <CandidatesLoading />
                ) : (
                  <div className="rounded-md mr-4 relative mb-6">
                    <div className="carousel-applications swiper-container max-w-full pt-1 mx-auto sm:max-w-none">
                      <div className="swiper-wrapper">
                        {applications.map((application, index) => (
                          <div
                            key={index}
                            className="swiper-slide max-w-[450px] h-auto"
                          >
                            <ApplicationSneakPeek
                              candidate={application}
                              key={index}
                            />
                          </div>
                        ))}

                        <div className="swiper-slide max-w-[450px] h-auto"></div>
                      </div>

                      <div className="flex mt-4 space-x-3 justify-end pr-4">
                        <button
                          className={`carousel-prev carousel-prev-2 absolute top-96 left-0 z-20 w-11 h-11 rounded-full flex items-center justify-center group bg-gray-900`}
                        >
                          <span className="sr-only">Previous</span>
                          <svg
                            className="fill-white group-hover:fill-[#02B491] transition duration-150 ease-in-out"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m3.914 5 3.5-3.5L6 .086 1.086 5H1v.086L.086 6 1 6.914V7h.086L6 11.914 7.414 10.5 3.914 7H13V5z" />
                          </svg>
                        </button>
                        <button
                          className={`carousel-next carousel-next-2 absolute top-96 right-0 z-20 w-11 h-11 rounded-full flex items-center justify-center group bg-gray-900`}
                        >
                          <span className="sr-only">Next</span>
                          <svg
                            className="fill-white group-hover:fill-[#02B491] transition duration-150 ease-in-out"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m9.086 5-3.5-3.5L7 .086 11.914 5H12v.086l.914.914-.914.914V7h-.086L7 11.914 5.586 10.5l3.5-3.5H0V5z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="pt-10 pl-8">
            <div className="">
              <h2 className="text-3xl">New Candidates</h2>
              <div>Check out our 20 new candidates</div>
            </div>
            {loading ? (
              <CandidatesLoading />
            ) : (
              <div className="rounded-md relative mr-4 mb-6">
                <div className="carousel-candidates swiper-container max-w-full pt-1 mx-auto sm:max-w-none">
                  <div className="swiper-wrapper">
                    {candidates.map((candidate, index) => (
                      <div
                        key={index}
                        className="swiper-slide max-w-[450px] h-auto"
                      >
                        <CandidateSneakPeek candidate={candidate} key={index} />
                      </div>
                    ))}

                    <div className="swiper-slide max-w-[450px] h-auto"></div>
                  </div>

                  <div className="flex mt-4 space-x-3 justify-end pr-4">
                    <button
                      className={`carousel-prev carousel-prev-1 absolute top-96 left-0 z-20 w-11 h-11 rounded-full flex items-center justify-center group bg-gray-900`}
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        className="fill-white group-hover:fill-[#02B491] transition duration-150 ease-in-out"
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m3.914 5 3.5-3.5L6 .086 1.086 5H1v.086L.086 6 1 6.914V7h.086L6 11.914 7.414 10.5 3.914 7H13V5z" />
                      </svg>
                    </button>
                    <button
                      className={`carousel-next carousel-next-1 absolute top-96 right-0 z-20 w-11 h-11 rounded-full flex items-center justify-center group bg-gray-900`}
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        className="fill-white group-hover:fill-[#02B491] transition duration-150 ease-in-out"
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m9.086 5-3.5-3.5L7 .086 11.914 5H12v.086l.914.914-.914.914V7h-.086L7 11.914 5.586 10.5l3.5-3.5H0V5z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="pt-6 pl-8">
            <div className="pb-4">
              <h2 className="text-3xl">Roles</h2>
              <div>You have 3 active roles</div>
            </div>

            {loading ? (
              renderLoadingState()
            ) : (
              <>
                <div className="relative  max-w-[1400px] overflow-x-auto border-2 sm:rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-3">
                          Role Title
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Salary Compensation
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Job Description
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Hiring Manager Name
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Hiring Manager Email
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Created Month
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Status
                        </th>
                        <th scope="col" className="px-8 py-3"></th>
                        <th scope="col" className="px-8 py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {roles.map((role, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b hover:bg-gray-50 "
                        >
                          <th
                            scope="row"
                            className="px-4 py-4 font-medium text-gray-900  "
                          >
                            {role.role_title}
                          </th>
                          <td className="px-4 py-4">{role.compensation}</td>
                          <td className="px-4 py-4 whitespace-normal break-words">
                            {role.job_description_link ? (
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(
                                    role.job_description_link,
                                    "_blank"
                                  );
                                }}
                                className="font-medium text-blue-600 cursor-pointer hover:underline"
                              >
                                Job Description
                              </a>
                            ) : (
                              <>Missing Job Description</>
                            )}
                          </td>

                          <td className="px-4 py-4">{role.hiring_lead_name}</td>
                          <td className="px-4 py-4">
                            {role.hiring_lead_email}
                          </td>
                          <td className="px-4 py-4">{role.createdMonthYear}</td>
                          <td className="px-4 py-4">
                            {role.status ? (
                              <button
                                onClick={() =>
                                  toggleStatus(role.id, role.status)
                                }
                                className="p-2 text-sm whitespace-nowrap justify-center hover:border-green-600 border border-white items-center flex rounded-md bg-green-100"
                                data-tip="Disable candidates to apply directly to this role"
                              >
                                <span className="size-1.5 inline-block rounded-full bg-green-600 mr-2"></span>
                                Receiving applications
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="size-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                  />
                                </svg>
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  toggleStatus(role.id, role.status)
                                }
                                className="p-2 text-sm whitespace-nowrap justify-center hover:border-orange-600 border border-white items-center flex rounded-md bg-orange-100"
                                data-tip="Allow candidates to apply directly to this role"
                              >
                                <span className="size-1.5 inline-block rounded-full bg-orange-600 mr-2"></span>
                                Not receiving applications
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="size-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                  />
                                </svg>
                              </button>
                            )}
                          </td>

                          <td className="px-4 py-4">
                            <img
                              className="inline-block h-6 cursor-pointer"
                              src={
                                hoveredEditIconId === role.id
                                  ? EditIconGreen
                                  : EditIcon
                              }
                              onClick={() =>
                                navigate(`/edit-role?roleId=${role.url_id}`)
                              }
                              onMouseEnter={() => setHoveredEditIconId(role.id)}
                              onMouseLeave={() => setHoveredEditIconId(null)}
                            />
                          </td>
                          <td className="px-4 py-4">
                            <img
                              className="inline-block h-6 cursor-pointer"
                              src={
                                hoveredRoleId === role.id
                                  ? DeleteIconRed
                                  : DeleteIcon
                              }
                              onClick={() => handleDelete(role.id)}
                              onMouseEnter={() => setHoveredRoleId(role.id)}
                              onMouseLeave={() => setHoveredRoleId(null)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-center mt-6 mr-10">
                  <button
                    onClick={() => navigate("/roles")}
                    className="px-8 py-3 w-full mr-2 hover:bg-[#02B491] flex flex-col items-center text-black font-normal rounded-md hover:text-white transition duration-200 border-2 border-[#02B491]"
                  >
                    See All Roles
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
