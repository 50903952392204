import React from "react";

const ActiveFilters = ({
  filterStream,
  filterYearsOfExp,
  setFilterYearsOfExp,
  filterWorkingStyle,
  filterIndustry,
  searchKeywords,
  onChange,
  clearFilters,
  setSearchKeywords,
  maxSalary,
  setMaxSalary,
}) => {
  // Function to handle removing individual filters, including keywords
  const handleRemoveFilter = (filter) => {
    const updatedFilterStream = filterStream.filter((item) => item !== filter);
    const updatedFilterYearsOfExp = filterYearsOfExp.filter(
      (item) => item !== filter
    );
    const updatedFilterWorkingStyle = filterWorkingStyle.filter(
      (item) => item !== filter
    );
    const updatedFilterIndustry = filterIndustry.filter(
      (item) => item !== filter
    );
    const updatedSearchKeywords = searchKeywords.filter(
      (item) => item !== filter
    );

    onChange({
      filterStream: updatedFilterStream,
      filterYearsOfExp: updatedFilterYearsOfExp,
      filterWorkingStyle: updatedFilterWorkingStyle,
      filterIndustry: updatedFilterIndustry,
      searchKeywords: updatedSearchKeywords, // Update searchKeywords as well
    });
  };

  const HandleRemoveSalary = () => {
    setMaxSalary("");
  };

  const yearsOfExpRange = filterYearsOfExp.length
    ? `${filterYearsOfExp[0].split("-")[0]}-${
        filterYearsOfExp[filterYearsOfExp.length - 1].split("-")[0]
      }`
    : null;

  return (
    <div className="relative pb-10 pr-2">
      <div className="absolute flex col-flex mt-1">
        {searchKeywords.map((filter) => (
          <div
            key={filter}
            className="flex text-sm items-center bg-white rounded-md border border-gray-300 mr-2 pl-4 pr-2"
          >
            {filter}
            <button
              className="ml-2 rounded-md px-2 py-1 text-gray-500 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRemoveFilter(filter)}
            >
              &#x2715;
            </button>
          </div>
        ))}
        {filterStream.map((filter) => (
          <div
            key={filter}
            className="flex text-sm items-center bg-white rounded-md border border-gray-300 mr-2 pl-4 pr-2"
          >
            {filter}
            <button
              className="ml-2 rounded-md px-2 py-1 text-gray-500 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRemoveFilter(filter)}
            >
              &#x2715;
            </button>
          </div>
        ))}

        {filterWorkingStyle.map((filter) => (
          <div
            key={filter}
            className="flex text-sm items-center bg-white rounded-md border border-gray-300 mr-2 py-2 pl-4 pr-2"
          >
            {filter}
            <button
              className="ml-2 rounded-md px-2 py-1 text-gray-500 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRemoveFilter(filter)}
            >
              &#x2715;
            </button>
          </div>
        ))}

        {filterIndustry.map((filter) => (
          <div
            key={filter}
            className="flex text-sm items-center bg-white rounded-md border border-gray-300 mr-2 py-2 pl-4 pr-2"
          >
            {filter}
            <button
              className="ml-2 rounded-md px-2 py-1 text-gray-500 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRemoveFilter(filter)}
            >
              &#x2715;
            </button>
          </div>
        ))}

        {yearsOfExpRange && (
          <div className="flex text-sm items-center bg-white rounded-md border border-gray-300 mr-2 py-2 pl-4 pr-2">
            Years of Exp: {yearsOfExpRange}
            <button
              className="ml-2 rounded-md px-2 py-1 text-gray-500 hover:bg-gray-100 cursor-pointer"
              onClick={() => setFilterYearsOfExp([])}
            >
              &#x2715;
            </button>
          </div>
        )}

        {maxSalary && (
          <div className="flex text-sm items-center bg-white rounded-md border border-gray-300 mr-2 py-2 pl-4 pr-2">
            Max salary: £{maxSalary}
            {maxSalary !== "50k+" && "k"}
            <button
              onClick={() => HandleRemoveSalary()}
              className="ml-2 rounded-md px-2 py-1 text-gray-500 hover:bg-gray-100 cursor-pointer"
            >
              &#x2715;
            </button>
          </div>
        )}

        {searchKeywords.length > 0 ||
        filterStream.length > 0 ||
        filterIndustry.length > 0 ||
        filterWorkingStyle.length > 0 ||
        maxSalary ||
        filterYearsOfExp.length > 0 ||
        filterYearsOfExp.length > 0 ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              clearFilters(); // Ensure clearFilters empties the searchKeywords as well
            }}
            type="button"
            className="py-2.5 px-5 text-sm font-medium text-gray-900 bg-white hover:bg-gray-200 rounded-lg hover:text-gray-700"
          >
            Clear filters
          </button>
        ) : (
          <div className="flex items-center pt-2 gap-x-4">
            <div className="flex items-center">
              Get started with popular searches:{" "}
            </div>
            <span
              onClick={(e) => {
                e.stopPropagation();
                setSearchKeywords(["SQL"]);
              }}
              className="hover:bg-green-100 items-center px-2 py-1 rounded-md cursor-pointer flex"
            >
              SQL
            </span>
            <span
              onClick={(e) => {
                e.stopPropagation();
                setSearchKeywords(["University of Cambridge"]);
              }}
              className="hover:bg-green-100 items-center px-2 py-1 rounded-md cursor-pointer flex"
            >
              University of Cambridge
            </span>
            <span
              onClick={(e) => {
                e.stopPropagation();
                setSearchKeywords(["Biochemistry"]);
              }}
              className="hover:bg-green-100 items-center px-2 py-1 rounded-md cursor-pointer flex"
            >
              Biochemistry
            </span>
            <span
              onClick={(e) => {
                e.stopPropagation();
                setSearchKeywords(["Founder"]);
              }}
              className="hover:bg-green-100 items-center px-2 py-1 rounded-md cursor-pointer flex"
            >
              Founder
            </span>
            <span
              onClick={(e) => {
                e.stopPropagation();
                setSearchKeywords(["Imperial College London"]);
              }}
              className="hover:bg-green-100 items-center px-2 py-1 rounded-md cursor-pointer flex"
            >
              Imperial College London
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveFilters;
