import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import LoadingBlack from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path
import RoleCard from "../../components/roles/role_card";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function RolesList() {
  const query = useQuery();
  const stream = query.get("stream");
  const navigate = useNavigate();
  const id = query.get("id");

  const [loading, setLoading] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Add search query state
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null); // Track currently playing video

  const [roles, setRoles] = useState([]); // State to store available roles
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    let roleDropStatus = ""; // Define roleDropStatus based on stream
    if (stream === "sales") {
      roleDropStatus = "Live in Sales";
    } else if (stream === "generalist") {
      roleDropStatus = "Live in Gen";
    }

    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchActiveRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            roleDropStatus: stream, // Pass the roleDropStatus in the request
          },
        });

        // Assuming the response contains an array of roles
        const roleOptions = response.data.roles.map((role) => ({
          id: role.id,
          title: role["Primary Field"],
          url_id: role["URL ID"],
          compensation: role["Compensation"],
          funding: role["Funding Stage (from Startup)"] ?? "",
          team_size: role["Team Size (from Startup)"] ?? "",
          location: role["Location (from Startup)"] ?? "",
          spotlight_context: role["Spotlight Context"] ?? "",
          dropbox_url:
            role["URL Dropbox cleaned (from Spotlight)"]?.[0] ?? null,
          loom_url: role["URL Loom cleaned (from Spotlight)"]?.[0] ?? null,
          image:
            role["Role Cover Image (from Startup)"]?.[0]?.url ??
            "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg",
        }));

        setRoles(roleOptions);
      } catch (err) {
        console.error("Error fetching roles:", err);
      }
    };

    fetchRoles();
  }, [stream]); // Include stream in the dependency array to refetch when stream changes

  // Filter roles based on the search query (case-insensitive)
  const filteredRoles = roles.filter((role) =>
    role.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (id !== "l3qhEze7") {
    return (
      <div className="flex items-center justify-center h-screen">
        <h1 className="text-3xl font-bold">This page does not exist.</h1>
      </div>
    );
  }

  // If no roles are available, display the message
  if (roles.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
        <div className="text-center p-8 bg-white rounded-md shadow-md max-w-lg">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            There are currently no roles available...
          </h2>
          <p className="text-gray-600">
            We run bi-weekly role drops, come back next Thursday!
          </p>
          <p className="italic text-black pt-2">
            All roles you applied to are accessible via the email we sent you.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center bg-gray-100 min-h-screen">
      <div className="fixed z-50 top-6 left-6 flex items-center mb-6">
        <div className="flex items-center mb-6 ">
          <img
            src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
            className="h-6 my-2 px-4 sm:block hidden"
            alt="Jumpstart Logo"
          />
        </div>
      </div>
      <div className="w-full sm:p-8 md:p-20 flex max-w-[1500px] items-center flex-col h-full bg-gray-100">
        <h2 className="text-4xl mb-5 pt-5 font-semibold leading-7 text-gray-900">
          {stream === "swe"
            ? "Software Engineering Role List"
            : stream.charAt(0).toUpperCase() + stream.slice(1) + " Role List"}
        </h2>
        <div className="w-4/5">
          <span className="font-bold text-black text-lg">
            How to use this page... (read me first!)
          </span>
          <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
            1. 🤓 Click 'Learn more' to learn more about the role & company.
            {stream === "swe" ? (
              <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
                2. 🎥 Some roles include video pitches. We strongly encourage
                you to{" "}
                <span className="font-bold text-black">
                  watch the recording if available.
                </span>{" "}
                Jumpstarters change their minds about a company approx 50% of
                the time after watching.
              </p>
            ) : (
              <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
                2. 🎥 We strongly encourage you to{" "}
                <span className="font-bold text-black">
                  watch all of the video pitches available.
                </span>{" "}
                Jumpstarters change their minds about a company approx 50% of
                the time after watching.{" "}
                <span className="italic">
                  (NB - you can watch spotlights at 1.5x speed & please note
                  that not all startups have a spotlight!)
                </span>
              </p>
            )}
            {stream === "swe" ? (
              <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
                3. 🤔 Got a question about the role or working in startups?
                Email{" "}
                <a
                  href="mailto:kabir@jumpstart-uk.com"
                  className="hover:underline text-blue-500"
                >
                  Kabir
                </a>
              </p>
            ) : (
              <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
                3. 🤔 Confused about any jargon or terminology?{" "}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    // Determine the correct URL based on the stream value
                    const url =
                      stream === "generalist"
                        ? "https://jumpstart-alumni.notion.site/Startup-101-d1293f75922b40da9e61b47e8cd9c417?pvs=74"
                        : "https://jumpstart-alumni.notion.site/Startup-101-9ed4a9e1d46142418adc00c285bf28b1?pvs=74";
                    window.open(url, "_blank");
                  }}
                  className="text-blue-500 cursor-pointer hover:underline"
                >
                  Check out our startup 101 page here
                </a>
              </p>
            )}
            <p className="mt-1 pt-2  text-md leading-6 text-gray-600">
              4. ✅ Keen to apply? You can{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  // Determine the correct URL based on the stream value
                  const url = `/application-form?stream=${stream}&id=l3qhEze7`;
                  window.open(url, "_blank");
                }}
                className="text-blue-500 hover:underline cursor-pointer"
              >
                submit your choices & 100 word rationales here.
              </a>{" "}
              <span className="font-bold text-black">
                Applications will close at midnight on Sunday.
              </span>
              <span className="italic">
                {" "}
                (NB - most candidates apply for at least 3 roles in each
                application).
              </span>
            </p>
            <p className="mt-1 pt-2 text-md leading-6 text-gray-600">
              5. 📚 This{" "}
              <span className="font-bold text-black">
                page will expire on Tuesday
              </span>
              , but you will receive the relevant role information after
              applying over email.{" "}
              <span className="font-semibold text-black underline">
                Please bookmark these emails to refer back to ahead of
                interviews by midnight on Sunday!
              </span>
            </p>
            {stream !== "swe" && (
              <p className="font-bold text-black mt-1 pt-2">
                For all other information, please visit our{" "}
                <span
                  onClick={(e) => {
                    e.preventDefault();

                    // Determine the correct URL and link text based on the stream value
                    let url = "";
                    let linkText = "";

                    if (stream === "sales") {
                      url =
                        "https://jumpstart-alumni.notion.site/Jumpstart-Sales-Stream-2024-Hub-March-update-e647eb318f6844b09b5d4cedaaf348e1";
                      linkText = "Sales Notion Hub";
                    } else if (stream === "generalist") {
                      url =
                        "https://jumpstart-alumni.notion.site/Jumpstart-Generalist-Stream-Hub-2024-September-update-8548d66aaaa947acaac10adf450272f1";
                      linkText = "Generalist and Marketing Notion Hub";
                    }

                    // Open the appropriate URL in a new tab
                    window.open(url, "_blank");
                  }}
                  className="text-blue-500 hover:underline cursor-pointer"
                >
                  {stream === "sales"
                    ? "Sales Notion Hub"
                    : "Generalist and Marketing Notion Hub"}
                </span>
              </p>
            )}
          </p>
        </div>

        <div className="flex justify-center pt-10 w-full">
          <div className="relative w-full mx-1 ">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 text-gray-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="voice-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#02B491] focus:border-[#02B491] block w-full ps-10 p-2.5"
              placeholder="Search through our available roles"
              value={searchQuery} // Controlled input value
              onChange={handleSearchChange} // Update search query state on change
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 end-0 flex items-center pe-3"
            >
              <svg
                className="w-4 h-4 me-2 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 mx-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredRoles.map((role, index) => (
            <RoleCard
              key={index}
              role={role}
              currentlyPlaying={currentlyPlaying}
              setCurrentlyPlaying={setCurrentlyPlaying}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default RolesList;
