import SmallCard from "../candidates/small_card";

const LoadingCard = () => {
  return (
    <div className="w-full flex items-center space-x-4 p-4 bg-white rounded-md animate-pulse">
      <div className="w-24 h-16 bg-gray-300 rounded-md"></div>
      <div className="flex flex-col space-y-2 w-full">
        <div className="w-20 h-4 bg-gray-300 rounded"></div>
        <div className="w-24 h-4 bg-gray-300 rounded"></div>
      </div>
    </div>
  );
};

function ShortlistRecap({ candidates, loading }) {
  return (
    <div className="bg-gray-100 relative w-72 max-h-[350px] overflow-y-scroll rounded-lg flex flex-col items-center justify-start px-8 pb-6">
      <h2 className="absolute w-72 bg-gray-100 rounded-t-lg pt-4 text-lg font-semibold text-center">
        Proceed with the following candidates
      </h2>
      <div className="w-full pt-20">
        {loading
          ? Array.from({ length: 2 }).map((_, index) => (
              <LoadingCard key={index} />
            ))
          : candidates.map((savedCandidate, index) => (
              <SmallCard key={index} savedCandidate={savedCandidate} />
            ))}
      </div>
    </div>
  );
}

export default ShortlistRecap;
