import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import JobDescription from "../images/job-description-icon.jpeg";
import HeadHunting from "../images/headhunting-icon.png";
import JobApplication from "../images/job-application-icon.png";
import Interview from "../images/interview-icon.png";
import InterviewBlack from "../images/interviews-black.png";
import Application from "../images/applications-icon.png";
import ApplicationBlack from "../images/applications-icon-black.png";
import Role from "../images/roles-icon.png";
import RoleBlack from "../images/roles-icon-black.png";
import Browse from "../images/browse-icon.png";
import BrowseBlack from "../images/browse-icon-black.png";
import { useShortlisted } from "../provider/ShortlistedContext";

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCandidatesDropdownOpen, setCandidatesDropdownOpen] = useState(false);
  const [isInterviewsDropdownOpen, setInterviewsDropdownOpen] = useState(false);
  const [isInterviewHovered, setIsInterviewHovered] = useState(false);
  const [isApplicationHovered, setIsApplicationHovered] = useState(false);
  const [isRoleHovered, setIsRoleHovered] = useState(false);
  const [isBrowseHovered, setIsBrowseHovered] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { shortlistedCount, setShortlistedCount } = useShortlisted();

  const avatar =
    "https://via.placeholder.com/600x400.png?text=No+Image+Available";
  const profileFallback =
    "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=";
  const [companyLogo, setCompanyLogo] = useState(
    "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
  );
  const [companyName, setCompanyName] = useState("");
  const [companyLink, setCompanyLink] = useState("");

  useEffect(() => {
    const candidatesDropdownState =
      localStorage.getItem("candidatesDropdownOpen") === "true";
    const interviewsDropdownState =
      localStorage.getItem("interviewsDropdownOpen") === "true";
    setCandidatesDropdownOpen(candidatesDropdownState);
    setInterviewsDropdownOpen(interviewsDropdownState);
  }, []);

  useEffect(() => {
    const updateDropdownState = () => {
      const path = location.pathname;
      if (path.includes("browse-candidates") || path.includes("shortlists")) {
        setCandidatesDropdownOpen(true);
      }
      if (
        path.includes("requests") ||
        path.includes("in-process") ||
        path.includes("declined")
      ) {
        setInterviewsDropdownOpen(true);
      }
    };

    updateDropdownState();
  }, [location.pathname]);

  const fetchShortlistedCandidates = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/roles/fetchRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const roles = response.data.roles;

      const count = roles.reduce(
        (acc, role) =>
          acc +
          (role["Shortlisted Candidates"]
            ? role["Shortlisted Candidates"].length
            : 0),
        0
      );
      setShortlistedCount(count);
    } catch (err) {
      console.error("Error fetching roles:", err);
    }
  }, [setShortlistedCount]);

  useEffect(() => {
    fetchShortlistedCandidates();
  }, [fetchShortlistedCandidates]);

  const toggleCandidatesDropdown = () => {
    const newState = !isCandidatesDropdownOpen;
    setCandidatesDropdownOpen(newState);
    localStorage.setItem("candidatesDropdownOpen", newState);
  };

  const toggleInterviewsDropdown = () => {
    const newState = !isInterviewsDropdownOpen;
    setInterviewsDropdownOpen(newState);
    localStorage.setItem("interviewsDropdownOpen", newState);
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/companies/fetchCompanyData", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const companyLogoUrl = response.data.companyData.logo;
      const companyName = response.data.companyData.name;
      const companyLink = response.data.companyData.login_link;

      if (companyLogoUrl) {
        setCompanyLogo(companyLogoUrl);
        localStorage.setItem("companyLogo", companyLogoUrl);
      }

      setCompanyLink(companyLink);
      setCompanyName(companyName);
      localStorage.setItem("companyName", companyName); // Save company name to localStorage
      localStorage.setItem("companyLink", companyLink); // Save company name to localStorage
      localStorage.setItem("lastFetchTime", Date.now());
    } catch (err) {
      console.error("Error fetching company data:", err);
    }
  };

  useEffect(() => {
    const logoFromStorage = localStorage.getItem("companyLogo");
    const nameFromStorage = localStorage.getItem("companyName");
    const lastFetchTime = localStorage.getItem("lastFetchTime");
    const linkFromStorage = localStorage.getItem("companyLink");
    const currentTime = Date.now();
    const twoHours = 2 * 60 * 60 * 1000;

    if (
      logoFromStorage &&
      nameFromStorage &&
      lastFetchTime &&
      currentTime - lastFetchTime < twoHours
    ) {
      setCompanyLogo(logoFromStorage);
      setCompanyLink(linkFromStorage);
      setCompanyName(nameFromStorage); // Retrieve company name from localStorage
    } else {
      fetchData();
    }
  }, []);

  const copyToClipboard = () => {
    const currentPage = location.pathname;
    const params = new URLSearchParams(location.search);
    const roleId = params.get("roleId");
    const link = `${companyLink}&page=${currentPage}&roleId=${roleId}`;

    navigator.clipboard.writeText(link).then(
      () => {
        alert("Link copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <div className="sm:hidden flex justify-between items-center p-4 bg-white border-b sticky top-0 z-50">
        <img
          src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
          className="h-6"
          alt="Jumpstart Logo"
        />
        <button onClick={toggleMobileMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      <aside
        id="sidebar-multi-level-sidebar"
        className={`fixed top-0 border-r left-0 z-40 w-56 h-screen transition-transform ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 pt-10 overflow-y-auto flex flex-col justify-between bg-white">
          <div className="flex items-center space-x-4 pb-10">
            <img
              src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
              className="h-6 fixed left-6 sm:block hidden"
              alt="Jumpstart Logo"
            />
          </div>
          <ul className="space-y-2 font-medium">
            <li>
              <button
                onClick={toggleCandidatesDropdown}
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onMouseEnter={() => setIsBrowseHovered(true)}
                onMouseLeave={() => setIsBrowseHovered(false)}
              >
                <img
                  src={isBrowseHovered ? BrowseBlack : Browse}
                  className="w-8 h-8 text-gray-500 transition duration-75 group-hover:text-gray-900 "
                />
                <span className="ms-3 font-normal text-md">Candidates</span>
                <svg
                  className="w-3 h-3 ms-auto"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              {isCandidatesDropdownOpen && (
                <ul className="py-2 space-y-2">
                  <li>
                    <a
                      href="/browse-candidates"
                      className="flex font-normal text-sm items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-14 group hover:bg-gray-100"
                    >
                      Browse
                    </a>
                  </li>
                  <li>
                    <a
                      href="/shortlists"
                      className="flex font-normal text-sm items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-14 group hover:bg-gray-100"
                    >
                      Shortlists
                      {shortlistedCount > 0 && (
                        <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                          {shortlistedCount}
                        </span>
                      )}
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <a
                href="/applications"
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100"
                onMouseEnter={() => setIsApplicationHovered(true)}
                onMouseLeave={() => setIsApplicationHovered(false)}
              >
                <img
                  src={isApplicationHovered ? ApplicationBlack : Application}
                  className="w-7 h-7 text-gray-500 transition duration-75 group-hover:text-gray-900"
                />
                <span className="font-normal flex-1 ms-3 whitespace-nowrap">
                  Applications
                </span>
              </a>
            </li>
            <li>
              <button
                onClick={toggleInterviewsDropdown}
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onMouseEnter={() => setIsInterviewHovered(true)}
                onMouseLeave={() => setIsInterviewHovered(false)}
              >
                <img
                  src={isInterviewHovered ? InterviewBlack : Interview}
                  className="w-8 h-8 text-gray-500 transition duration-75 group-hover:text-gray-900"
                />
                <span className="flex-1 font-normal ms-3 text-left rtl:text-right whitespace-nowrap">
                  Interviews
                </span>
                <svg
                  className="w-3 h-3 ms-auto"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              {isInterviewsDropdownOpen && (
                <ul className="py-2 space-y-2">
                  <li>
                    <a
                      href="/requests"
                      className="flex font-normal text-sm items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-14 group hover:bg-gray-100 "
                    >
                      Requests
                    </a>
                  </li>
                  <li>
                    <a
                      href="/in-process"
                      className="flex font-normal text-sm items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-14 group hover:bg-gray-100"
                    >
                      In process
                    </a>
                  </li>
                  <li>
                    <a
                      href="/declined"
                      className="flex font-normal text-sm items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-14 group hover:bg-gray-100"
                    >
                      Declined
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <a
                href="/roles"
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100  group"
                onMouseEnter={() => setIsRoleHovered(true)}
                onMouseLeave={() => setIsRoleHovered(false)}
              >
                <img
                  src={isRoleHovered ? RoleBlack : Role}
                  className="w-8 h-8 text-gray-500 transition duration-75 group-hover:text-gray-900"
                />
                <span className="flex-1 font-normal ms-3 whitespace-nowrap">
                  Roles
                </span>
              </a>
            </li>
          </ul>
          <div className="flex flex-col items-center mt-auto pb-4">
            <img
              onClick={() => navigate("/company-profile")}
              src={companyLogo}
              alt="User Avatar"
              className="h-20 w-20 object-contain rounded-sm cursor-pointer mb-2"
              onError={(e) => {
                e.target.src = profileFallback; // Provide a fallback source
                console.error("Failed to load image at", e.target.src);
              }}
            />
            <button
              onClick={copyToClipboard}
              className="px-2 py-2 rounded-md border border-black bg-white text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200"
            >
              Share Page with Colleague
            </button>
          </div>
        </div>
      </aside>
    </>
  );
}
