import React, { useState, useEffect } from "react";
import axios from "axios";

function CompanyDetails({
  activeStep,
  setActiveStep,
  company,
  setCompany,
  setCompanyNeedUpdate,
  loading,
}) {
  const requiredFields = ["name", "description", "location", "website"];
  const missingRequiredFields = requiredFields.filter(
    (field) => !company[field]
  );

  const renderField = (label, value, fieldName) => {
    if (loading) {
      if (fieldName === "description") {
        return (
          <div className="space-y-2 mb-2">
            <div className="h-6 bg-gray-200 rounded-full "></div>
            <div className="h-6 bg-gray-200 rounded-full w-[700px]"></div>
            <div className="h-6 bg-gray-200 rounded-full  w-full"></div>
            <div className="h-6 mr-2 bg-gray-200 rounded-full  w-28 mb-2"></div>
          </div>
        );
      } else {
        return (
          <div className="h-6 mr-2 bg-gray-200 rounded-full  w-28 mb-2"></div>
        );
      }
    }

    return value ? (
      <span>{value}</span>
    ) : (
      <span className="text-red-600">(missing {fieldName})</span>
    );
  };

  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    website: "",
    email: "",
    linkedin: "",
    description: "",
    file: null,
    equity: "",
    funding: "",
    location: "",
  });

  useEffect(() => {
    if (company) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: company.name || "",
        website: company.website || "",
        linkedin: company.linkedin || "",
        description: company.description || "",
        equity: company.equity || "",
        funding: company.funding || "",
        file: company.logo || null,
        location: company.location || "",
        email: company.email || "",
      }));
    }
  }, [company]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const errors = {};
    if (!formData.name) errors.name = "Name is required.";
    if (!formData.description) errors.description = "Description is required.";
    if (!formData.location) errors.location = "Location is required.";
    if (!formData.website) errors.website = "Website is required.";

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const updatedData = {
      fields: {
        "Startup Name": formData.name,
        "Funding Stage": formData.funding,
        Website: formData.website,
        "Startup Description": formData.description,
        Location: formData.location,
      },
    };

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      const response = await axios.patch(
        "/api/companies/updateCompanyData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Ensure this matches the server expectation
          },
        }
      );
      setCompanyNeedUpdate(true);
      console.log(response.data);
      setActiveStep("Role Details");
      // Handle successful update
    } catch (err) {
      console.error("Error updating company data:", err);
      setError("Failed to update company data: " + err.message);
    }
  };

  return (
    <div
      className={`relative ${
        activeStep === "Company Information" ? "" : "flex"
      }`}
    >
      <h2
        className={`whitespace-nowrap block text-4xl font-normal leading-6 ${
          activeStep === "Company Information"
            ? "text-[#02B491]"
            : "text-gray-900"
        }`}
      >
        1. Company Information
      </h2>
      {activeStep === "Company Information" ? (
        <div className="mb-10 transform transition-all">
          <form onSubmit={handleSubmit}>
            <div className="space-y-12">
              <div className=" border-gray-900/10">
                <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                  <div className="flex-initial w-2/5">
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Name <span className="text-black">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="Jumpstart"
                        value={formData.name}
                        onChange={handleChange}
                      ></input>
                      {formErrors.name && (
                        <p className="text-red-600 text-md">
                          {formErrors.name}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="website"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Website <span className="text-black">*</span>
                      </label>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="https://www.jumpstart-uk.com/"
                        value={formData.website}
                        onChange={handleChange}
                      ></input>
                      {formErrors.website && (
                        <p className="text-red-600 text-md">
                          {formErrors.website}
                        </p>
                      )}
                    </div>
                    <div className="">
                      <label
                        htmlFor="location"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Location <span className="text-black">*</span>
                      </label>
                      <div>
                        <input
                          type="text"
                          name="location"
                          id="location"
                          placeholder="North London"
                          autoComplete="address-level2"
                          value={formData.location}
                          onChange={handleChange}
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        ></input>
                        {formErrors.location && (
                          <p className="text-red-600 text-md">
                            {formErrors.location}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="mt-1">
                      <label
                        htmlFor="funding"
                        className="block text-md font-semibold text-gray-900 "
                      >
                        Funding Stage <span className="text-black">*</span>
                      </label>
                      <select
                        id="funding"
                        name="funding"
                        value={formData.funding}
                        onChange={handleChange}
                        required
                        className="bg-transparent mt-2 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block pr-10 p-1.5"
                      >
                        <option selected>Choose an option</option>
                        <option value="Pre-Seed">Pre-seed</option>
                        <option value="Seed">Seed</option>
                        <option value="Series A">Series A</option>
                        <option value="Series B">Series B</option>
                        <option value="Series B+">Series B+</option>
                        <option value="Series C+">Series C+</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <label
                      htmlFor="description"
                      className="block text-md font-semibold leading-6 text-gray-900"
                    >
                      Description <span className="text-black">*</span>
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      rows="4"
                      className="block mt-2 w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                      placeholder="A short description of your company!"
                      value={formData.description}
                      onChange={handleChange}
                    ></textarea>
                    {formErrors.description && (
                      <p className="text-red-600 text-md">
                        {formErrors.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={(e) => handleSubmit(e)}
              className="px-8 py-2 mt-4 rounded-md bg-[#02B491] text-white font-bold transition duration-200 hover:bg-white hover:text-black border-2 border-[#02B491]"
            >
              Continue
            </button>
          </form>
        </div>
      ) : (
        <div className="pl-4">
          <div
            className="text-[#02B491] absolute top-0 right-0 text-lg cursor-pointer hover:underline"
            onClick={(e) => setActiveStep("Company Information")}
          >
            Edit
          </div>
          <h2 className="pl-4 text-xl font-semibold text-black">
            {renderField("Name", company.name, "name")}
          </h2>
          <h2 className="pl-4 text-md text-gray-600 flex font-semibold">
            {renderField("Location", company.location, "location")}
          </h2>
          <h2 className="pl-4 text-md text-gray-600 font-semibold">
            {renderField("Funding", company.funding, "funding")}
          </h2>
          <h2 className="pb-6 pl-4 text-md text-blue-600 flex font-semibold">
            {renderField("Website", company.website, "website")}
          </h2>
        </div>
      )}
    </div>
  );
}

export default CompanyDetails;
