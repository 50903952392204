import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

function CompanyDetails({ moveToNext }) {
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/companies/fetchCompanyData", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const companyData = response.data.companyData;

        setCompany(companyData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    website: "",
    email: "",
    linkedin: "",
    description: "",
    file: null,
    equity: "",
    funding: "",
    location: "",
    email: "",
  });

  useEffect(() => {
    if (company) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: company.name || "",
        website: company.website || "",
        linkedin: company.linkedin || "",
        description: company.description || "",
        equity: company.equity || "",
        funding: company.funding || "",
        file: company.logo || null,
        location: company.location || "",
        email: company.email || "",
      }));
    }
  }, [company]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    const updatedData = {
      fields: {
        "Startup Name": formData.name,
        "Startup Description": formData.description,
        "Funding Stage": formData.funding,
        Website: formData.website,
        Location: formData.location,
        "Startup Linkedin": formData.linkedin,
        Equity: formData.equity,
      },
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/companies/updateCompanyData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      moveToNext();
      // Handle successful update
    } catch (err) {
      console.error(err);
      setError("Failed to update company data");
      // Handle error
    }
  };

  return (
    <div className="flex flex-col items-center bg-white pl-4 pr-4">
      <div className="max-w-3xl w-full flex flex-col h-full bg-white">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className=" border-gray-900/10 pb-12">
              <h2 className="text-3xl font-normal pt-4 text-black">
                We just need to confirm some key information about you:
              </h2>

              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
                <div className="col-span-full">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Company description
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows="5"
                      className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="A short description of your company!"
                      value={formData.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="sm:col-span-8 flex space-x-4">
                  <div className="flex-1">
                    <label
                      htmlFor="website"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Your company's website
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="website"
                        id="website"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="https://www.jumpstart-uk.com/"
                        value={formData.website}
                        onChange={handleChange}
                      />
                      {formErrors.website && (
                        <p className="text-red-600 text-sm">
                          {formErrors.website}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex-1">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Your company's name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Jumpstart"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {formErrors.name && (
                        <p className="text-red-600 text-sm">
                          {formErrors.name}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-6 grid grid-cols-2 gap-4">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="funding"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Funding Stage
                    </label>
                    <select
                      id="funding"
                      name="funding"
                      value={formData.funding}
                      onChange={handleChange}
                      className="bg-transparent border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pr-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected>Choose an option</option>
                      <option value="Pre-seed">Pre-seed</option>
                      <option value="Seed">Seed</option>
                      <option value="Series A">Series A</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className="px-6 py-2 text-sm bg-black text-black rounded-lg font-medium transform hover:-translate-y-1 transition duration-400"
              style={{
                backgroundColor: "rgb(0, 242, 194)",
              }}
            >
              Save and Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyDetails;
