import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Fetch roles associated with the logged-in company
const fetchRoles = async (setData, setLoading, setError) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get("/api/roles/fetchRoles", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const roles = response.data.roles;

    // Extract only name and id
    const transformedData = roles.map((role) => ({
      id: role["id"],
      name: role["Role Title"], // Assuming "Role Title" contains the name of the role
    }));

    setData(transformedData);
    setLoading(false);
  } catch (err) {
    setLoading(false);
  }
};

// Create a new role with the given title
const createRoleWithTitle = async (roleTitle) => {
  try {
    const data = {
      fields: { "Role Title": roleTitle },
    };
    const token = localStorage.getItem("token");
    const response = await axios.post("/api/roles/createRole", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.newRoleData.id; // Assuming the API returns a `roleId` in the response
  } catch (error) {
    console.error("Error creating role:", error);
    return null;
  }
};

function CongratsScreen() {
  const query = useQuery();
  const startupId = query.get("startupId");
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState("scratch"); // Default to scratch if no roles are found
  const [roleTitle, setRoleTitle] = useState(""); // Track the role title for new roles

  useEffect(() => {
    fetchRoles(setRoles, setLoading, setError);

    // Add event listener for key presses
    const handleKeyPress = (event) => {
      const key = event.key;

      if (key === "1") {
        if (roles.length > 0) {
          setSelectedOption("role"); // Select existing role if there are roles
        } else {
          setSelectedOption("scratch"); // Default to New role when no roles are found
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress); // Clean up event listener
    };
  }, [roles]);

  const handleContinue = async () => {
    if (selectedOption === "scratch" && roleTitle) {
      // If creating a new role with the entered title
      const roleId = await createRoleWithTitle(roleTitle);
      if (roleId) {
        window.location.href = `/new-role2?roleId=${roleId}`;
      } else {
        alert("Failed to create the role.");
      }
    } else if (selectedOption === "role") {
      // Navigate to the specific link if "Finish creating role" is selected
      const roleId = roles[0]?.id;
      if (roleId) {
        window.location.href = `/new-role2?roleId=${roleId}`;
      } else {
        alert("No role found to finish creating.");
      }
    }
  };

  const handleSkipAndViewCandidates = async () => {
    // Create a role with the provided title and redirect to browse candidates
    const roleId = await createRoleWithTitle(roleTitle || "Default Role");
    if (roleId) {
      navigate("/browse-candidates");
    } else {
      alert("Failed to create the role.");
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-white">
        <h2 className={`text-xl pb-2 font-bold `}>Creating your account</h2>
        <div className="w-[200px] mb-40 h-1 rounded-md bg-gray-300 overflow-hidden relative">
          <div className="h-full w-1/2 rounded-md bg-black animate-slide-bar"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const isContinueEnabled =
    selectedOption === "role" || (selectedOption === "scratch" && roleTitle);

  return (
    <div className="bg-white flex items-center justify-center">
      <div className="fixed z-50 top-6 left-6 flex items-center mb-6">
        <div className="flex items-center mb-6">
          <img
            src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
            className="h-6 my-2 px-4 sm:block hidden"
            alt="Jumpstart Logo"
          />
        </div>
      </div>
      <div className="flex items-center bg-white max-w-[950px] w-full justify-between h-screen">
        <h2
          className={`text-6xl max-w-[500px] mr-4 mb-20 text-left pb-8 font-medium`}
        >
          Your account has been created
        </h2>

        <div className="flex flex-col space-y-2 mb-20">
          {/* If roles are available, show both options */}
          {roles.length > 0 ? (
            <>
              <p className="text-3xl mb-2">Select a role to get started:</p>
              {/* Option 1: Finish creating the existing role */}
              <button
                onClick={() => setSelectedOption("role")}
                className={`mb-2 px-2 text-left flex items-center text-2xl min-w-[max-content] py-2 rounded-md border ${
                  selectedOption === "role"
                    ? "border-2 border-blue-500"
                    : "border border-blue-500"
                } bg-blue-500/10 hover:bg-blue-500/20`}
              >
                <span
                  className={`rounded-sm text-base px-2 font-bold mr-2 border ${
                    selectedOption === "role"
                      ? "bg-blue-500 text-white"
                      : "bg-white/50"
                  } border-blue-500`}
                >
                  1
                </span>
                {roles[0]?.name}
                {selectedOption === "role" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-6 ml-auto h-6 text-blue-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <div className="w-8"></div>
                )}
              </button>

              {/* Option 2: Create a new role */}
              <button
                onClick={() => setSelectedOption("scratch")}
                className={`px-2 text-left flex items-center text-2xl min-w-[max-content] py-2 rounded-md border ${
                  selectedOption === "scratch"
                    ? "border-2 border-[#02B491]"
                    : "border border-[#02B491]"
                } bg-[#02B491]/10 hover:bg-[#02B491]/20`}
              >
                <span
                  className={`rounded-sm text-base px-2 font-bold mr-2 border ${
                    selectedOption === "scratch"
                      ? "bg-[#02B491] text-white"
                      : "bg-white/50"
                  } border-[#02B491]`}
                >
                  2
                </span>
                New role
                {selectedOption === "scratch" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-6 ml-auto h-6 text-[#02B491]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <div className="w-6"></div>
                )}
              </button>

              {/* Show input for new role title */}
              {selectedOption === "scratch" ? (
                <>
                  <h2 className="text-2xl font-medium">Role title</h2>
                  <input
                    className="border-b-2 font-normal w-[300px] border-gray-200 text-xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                    placeholder="Founders Associate"
                    autoFocus
                    value={roleTitle}
                    onChange={(e) => setRoleTitle(e.target.value)} // Update role title state
                  />
                </>
              ) : (
                <div className="h-16"></div>
              )}
            </>
          ) : (
            <>
              <h2 className="text-2xl font-medium">
                What's your first role's title?
              </h2>
              <input
                className="border-b-2 font-normal w-[350px] border-gray-200 text-xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="Founders Associate"
                autoFocus
                value={roleTitle}
                onChange={(e) => setRoleTitle(e.target.value)} // Update role title state
              />
            </>
          )}
        </div>

        {/* Continue and Skip buttons */}
        <div className="flex space-x-4 fixed bottom-20 right-20">
          <button
            onClick={handleSkipAndViewCandidates}
            disabled={!isContinueEnabled}
            className={`text-xl flex justify-center items-center border-2 w-[300px] font-normal py-2 px-4 ${
              isContinueEnabled
                ? "hover:bg-[#02B491] hover:text-white border-[#02B491] text-black"
                : "bg-gray-300 text-gray-500 border-gray-300 cursor-not-allowed"
            } rounded-md transition`}
          >
            Skip to browse candidates
          </button>
          <button
            onClick={handleContinue}
            className={`text-xl flex justify-center items-center w-[250px] border-2 font-normal py-2 px-4 ${
              isContinueEnabled
                ? "bg-[#02B491] text-white  border-[#02B491] hover:text-black hover:bg-white"
                : "bg-gray-300 text-gray-500 border-gray-300 cursor-not-allowed"
            } rounded-md transition`}
            disabled={!isContinueEnabled}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default CongratsScreen;
