import React, { useEffect } from "react";

function OnBoarding() {
  useEffect(() => {
    // Dynamically load JotForm script
    const script = document.createElement("script");
    script.src = "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <iframe
        id="JotFormIFrame-242525183589364"
        title="Startup Onboarding Form (Platform)"
        onLoad={() => window.parent.scrollTo(0, 0)}
        allowTransparency="true"
        allow="geolocation; microphone; camera; fullscreen"
        src="https://form.jotform.com/242525183589364"
        frameBorder="0"
        style={{
          minWidth: "100%",
          maxWidth: "100%",
          height: "539px",
          border: "none",
        }}
        scrolling="no"
      ></iframe>
    </div>
  );
}

export default OnBoarding;
