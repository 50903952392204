import React, { useState, useEffect } from "react";
import axios from "axios";
import EditJobDescriptionModal from "./edit_job_description"; // Adjust the import path as necessary

const ModalRole = ({ onClose, role, setRoleNeedUpdate }) => {
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    job_description_link: "",
    email: "",
    key_experience: "",
    compensation: "",
    working_style: "",
    hiring_lead_name: "",
    hiring_lead_linkedin: "",
    equity: "",
    hiring_lead_email: "",
    interview_process: "",
    additional_info: "",
    spotlight: "",
  });

  useEffect(() => {
    if (role) {
      setFormData({
        title: role.title || "",
        compensation: role.compensation || "",
        job_description_link: role.job_description_link || "",
        hiring_lead_name: role.hiring_lead_name || "",
        hiring_lead_email: role.hiring_lead_email || "",
        equity: role.equity || "",
        hiring_lead_linkedin: role.hiring_lead_linkedin || "",
        key_experience: role.key_experience || "",
        interview_process: role.interview_process || "",
        working_style: role.working_style || "",
        spotlight: role.spotlight || "",
      });
    }
  }, [role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFields = () => {
    const errors = {};
    if (!formData.title) errors.title = "Role Title is required.";
    if (!formData.compensation)
      errors.compensation = "Compensation is required.";
    if (!formData.job_description_link)
      errors.job_description_link = "Job Description is required.";
    if (!formData.working_style)
      errors.working_style = "Working Style is required.";
    if (!formData.equity) errors.equity = "Equity is required.";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const updatedData = {
      fields: {
        "Role Title": formData.title,
        "Hiring Lead Name": formData.hiring_lead_name,
        "Hiring Lead Email Address": formData.hiring_lead_email,
        "Specific Experience": formData.key_experience,
        "Hiring Lead Linkedin": formData.hiring_lead_linkedin,
        "Interview Process": formData.interview_process,
        "Working Style": formData.working_style,
        Compensation: formData.compensation,
        Equity: formData.equity,
        "Job Description PDF": formData.job_description_link,
      },
      roleId: role.id,
    };

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRoleNeedUpdate(true);
      console.log(response.data);
      onClose();
    } catch (err) {
      console.error("Error updating role data:", err);
      setError("Failed to update role data: " + err.message);
    }
  };

  const handleFileUpload = (url) => {
    setFormData({ ...formData, job_description_link: url });
    setEditMode(false);
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="inline-block align-middle h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-4xl w-full">
          <div className="bg-white p-6">
            <form onSubmit={handleSubmit}>
              <div className="space-y-12">
                <div className="border-gray-900/10 pb-4">
                  <h2
                    className="text-4xl font-semibold leading-7 text-gray-900"
                    style={{ color: "rgb(0, 185, 140)" }}
                  >
                    {role.title}
                  </h2>

                  <h2 className="block mt-8 text-3xl font-light leading-6 text-gray-900">
                    Role Information
                  </h2>

                  <h2 className="block pt-6 text-md font-semibold leading-6 text-gray-900">
                    Job Description <span className="text-black">*</span>
                  </h2>
                  <div className="mt-1">
                    <div className="relative max-w-xl flex items-center">
                      <input
                        type="text"
                        name="job_description_link"
                        id="job-description"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="https://www.jumpstart-uk.com/"
                        value={formData.job_description_link}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mt-2 flex items-center">
                      <button
                        type="button"
                        onClick={() => setEditMode(true)}
                        className="px-4 py-2 text-sm bg-black text-white rounded-lg"
                      >
                        Upload Job Description
                      </button>
                      {formErrors.job_description_link && (
                        <p className="text-red-600 text-sm ml-2">
                          {formErrors.job_description_link}
                        </p>
                      )}
                    </div>
                  </div>
                  {editMode && (
                    <EditJobDescriptionModal
                      onClose={() => setEditMode(false)}
                      formData={formData}
                      setFormData={setFormData}
                      setFile={setFile}
                      file={file}
                      onFileUpload={handleFileUpload}
                    />
                  )}
                  <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                    <div className="flex-1">
                      <div className="mb-4">
                        <label
                          htmlFor="title"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Role Title <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="Sales"
                          value={formData.title}
                          onChange={handleChange}
                        ></input>
                        {formErrors.title && (
                          <p className="text-red-600 text-md">
                            {formErrors.title}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="compensation"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Compensation <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="compensation"
                          id="compensation"
                          className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="£50,000 - £60,000"
                          value={formData.compensation}
                          onChange={handleChange}
                        ></input>
                        {formErrors.compensation && (
                          <p className="text-red-600 text-md">
                            {formErrors.compensation}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="working_style"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Working Style <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="working_style"
                          id="working_style"
                          autoComplete="working_style"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="Hybrid - 1 day per week in office"
                          value={formData.working_style}
                          onChange={handleChange}
                        ></input>
                        {formErrors.working_style && (
                          <p className="text-red-600 text-md">
                            {formErrors.working_style}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        {" "}
                        <label
                          htmlFor="equity"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Would you be open to offering equity?{" "}
                          <span className="text-black">*</span>
                        </label>
                        <select
                          id="equity"
                          name="equity"
                          value={formData.equity}
                          onChange={handleChange}
                          className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        >
                          <option value="">Select an option</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="TBC">To be Determined</option>
                        </select>
                        {formErrors.equity && (
                          <p className="text-red-600 text-md">
                            {formErrors.equity}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="mb-4">
                        <label
                          htmlFor="hiring_lead_name"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Hiring Lead Name
                        </label>
                        <input
                          type="text"
                          name="hiring_lead_name"
                          id="hiring_lead_name"
                          autoComplete="hiring_lead_name"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="John Smith"
                          value={formData.hiring_lead_name}
                          onChange={handleChange}
                        ></input>
                        {formErrors.hiring_lead_name && (
                          <p className="text-red-600 text-md">
                            {formErrors.hiring_lead_name}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="hiring_lead_email"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Hiring Lead Email Address
                        </label>
                        <input
                          type="text"
                          name="hiring_lead_email"
                          id="hiring_lead_email"
                          autoComplete="hiring_lead_email"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="john.smith@yourcompany.com"
                          value={formData.hiring_lead_email}
                          onChange={handleChange}
                        ></input>
                        {formErrors.hiring_lead_email && (
                          <p className="text-red-600 text-md">
                            {formErrors.hiring_lead_email}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="hiring_lead_linkedin"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Hiring Lead LinkedIn
                        </label>
                        <input
                          type="text"
                          name="hiring_lead_linkedin"
                          id="hiring_lead_linkedin"
                          autoComplete="hiring_lead_linkedin"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="https://www.linkedin.com/in/john-smith/"
                          value={formData.hiring_lead_linkedin}
                          onChange={handleChange}
                        ></input>
                        {formErrors.hiring_lead_linkedin && (
                          <p className="text-red-600 text-md">
                            {formErrors.hiring_lead_linkedin}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                    <div className="flex-1">
                      <label
                        htmlFor="interview_process"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Interview Process
                      </label>
                      <textarea
                        id="interview_process"
                        name="interview_process"
                        rows="4"
                        className="block mt-2 w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="A short description of the interview process for this role!"
                        value={formData.interview_process}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="flex-1">
                      <label
                        htmlFor="description"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Key Experience
                      </label>
                      <textarea
                        id="key_experience"
                        name="key_experience"
                        rows="4"
                        className="block mt-2 w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="A few lines on the type of candidate you are looking for!"
                        value={formData.key_experience}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                    <div className="flex-1"></div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start gap-x-6">
                <button
                  type="submit"
                  className="px-2 py-2 text-sm mr-1 text-black flex rounded-lg font-medium transform bg-white hover:bg-[#02B491]  hover:text-white transition duration-400 border-2 border-[#02B491]"
                >
                  Save and update
                </button>
              </div>
              {error && <p className="text-red-600 text-md">{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRole;
