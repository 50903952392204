import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SmallCandidateCard from "../../../components/candidates/small_candidate";
import ShortlistCard from "../../../components/candidates/new_shortlists";
import { Navigation } from "swiper/modules";
import Swiper from "swiper";
import "./swiper.min.css";
import { useShortlisted } from "../../../provider/ShortlistedContext";

Swiper.use([Navigation]);

function Shortlists() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const swiperInstances = useRef([]);
  const navigate = useNavigate();
  const [selectedCandidates, setSelectedCandidates] = useState({});

  const { shortlistedCount, setShortlistedCount } = useShortlisted();

  const handleShortlist = (roleURLId) => {
    navigate(`/shortlist?roleRequestId=${roleURLId}`);
    window.location.reload();
  };

  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;

        let shortlistedCandidatesByRole = {};

        roles.forEach((role) => {
          if (role["Shortlisted Candidates"]) {
            const roleTitle = role["Role Title"];
            const roleURLId = role["URL ID"];
            const roleId = role["id"];

            if (!shortlistedCandidatesByRole[roleTitle]) {
              shortlistedCandidatesByRole[roleTitle] = {
                roleURLId: roleURLId,
                roleId: roleId,
                candidates: [],
              };
            }
            shortlistedCandidatesByRole[roleTitle].candidates =
              shortlistedCandidatesByRole[roleTitle].candidates.concat(
                role["Shortlisted Candidates"]
              );
          }
        });

        const roleNames = Object.keys(shortlistedCandidatesByRole);
        if (roleNames.length > 0) {
          for (const roleName of roleNames) {
            await fetchCandidates(
              shortlistedCandidatesByRole[roleName].candidates,
              roleName,
              shortlistedCandidatesByRole[roleName].roleURLId,
              shortlistedCandidatesByRole[roleName].roleId
            );
          }
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError(err.response?.data?.error || err.message);
        setLoading(false);
      }
    };

    const fetchCandidates = async (
      candidateIds,
      roleName,
      roleURLId,
      roleId
    ) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/candidates/fetchCandidates", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            ids: candidateIds.join(","),
          },
        });

        const candidates = response.data.candidates;

        const transformedData = candidates.map((candidate) => {
          const mostRecentCohort =
            candidate.fields?.["Most recent cohort"] || "";
          const streamFromCRM =
            candidate.fields?.["Stream (from CRM)"][0] || "";

          let stream = streamFromCRM; // Default to "Stream (from CRM)" if it exists

          // Only transform based on "Most recent cohort" if "Stream (from CRM)" is not present
          if (!streamFromCRM) {
            if (/^JS\d+$/.test(mostRecentCohort)) {
              stream = "Generalist";
            } else if (/^JSS\d+$/.test(mostRecentCohort)) {
              stream = "Sales";
            } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
              stream = "Software Engineering";
            } else if (/^JSM\d+$/.test(mostRecentCohort)) {
              stream = "Marketing";
            }
          }

          let sweRoles = candidate.fields["SWE relevant roles"] || "";
          if (sweRoles) {
            sweRoles = Array.isArray(sweRoles)
              ? sweRoles[0].split(",").map((role) => role.trim())
              : sweRoles.split(",").map((role) => role.trim());
            sweRoles = transformRoles(sweRoles);
          } else {
            sweRoles = [];
          }

          let salary;
          if (stream === "Generalist" || stream === "Marketing") {
            salary = candidate.fields["Salary - cleaned"] || "";
          } else if (stream === "Sales") {
            salary = candidate.fields["Base range + commission"] || "";
          } else if (stream === "Software Engineering") {
            salary = candidate.fields["SWE Salary - cleaned"] || "";
          }

          return {
            id: candidate.id || "",
            url_id: candidate.fields?.["ID"] || "",
            featured: candidate.fields?.["Featured status"][0] || "",
            name: candidate.fields?.["Name"] || "",
            education: candidate.fields?.["Degree details"],
            cv: candidate.fields["Raw CV"] || "",
            video: candidate.fields["Raw video intro"] || "",
            linkedin: candidate.fields["LinkedIn raw URL"] || "",
            industry_exp: candidate.fields["Industry experience"] || undefined,
            lastName: candidate.fields["Surname"] || "",
            education: candidate.fields["Degree details"] || undefined,
            roles: candidate.fields["Generalist roles suitable for"] || "",
            salary: candidate.salary || "",
            email: candidate.fields["Email"] || "",
            work: candidate.fields["Raw work experience"] || "",
            working_style:
              candidate.fields["Preferred working style G/M/S"] || "",
            github: candidate.fields["Github"] || "",
            languages: candidate.fields["Languages"] || "",
            time_coding: candidate.fields["Time spent coding"] || "",
            description: candidate.fields["Top achievement"] || "",
            mostRecentCohort: candidate.fields["Most recent cohort"] || "",
            recentTarget: candidate.fields["Recent target achieved"] || "",
            image: candidate.fields?.Photo
              ? candidate.fields.Photo[0]?.url || null
              : null,
            stream: stream, // The final stream value (either from CRM or transformed)
            role: roleName,
            roleURLId: roleURLId,
            roleId: roleId,
            salary: salary,
          };
        });

        setData((prevData) => ({
          ...prevData,
          [roleName]: (prevData[roleName] || []).concat(transformedData),
        }));

        setSelectedCandidates((prev) => ({
          ...prev,
          [roleName]: transformedData.map((candidate) => candidate.id),
        }));

        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.error || err.message);
        setLoading(false);
      }
    };

    fetchRole();
  }, []);

  const handleRequestInterview = async (roleURLId, role) => {
    console.log("weird");
    if (!selectedCandidates[role] || selectedCandidates[role].length === 0) {
      console.error("No candidates selected for interview");
      return;
    }

    const updatedData = {
      fields: {
        "Selected Candidates": selectedCandidates[role], // Update with selected candidates for the role
      },
      roleId: data[role][0].roleId,
    };

    try {
      const token = localStorage.getItem("token");
      // Update the role's "Selected Candidates" field
      await axios.patch(`/api/roles/updateRoleData/`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // After successfully updating the selected candidates, navigate to request interview page
      navigate(`/new-request-interview?roleRequestId=${roleURLId}`);
    } catch (err) {
      console.error(
        "Error updating selected candidates:",
        err.response?.data?.error || err.message
      );
    }
  };

  const [removing, setRemoving] = useState(false);

  const handleRemoveCandidate = async (role, candidateId) => {
    if (!data[role]) {
      console.error(`Role ${role} not found in data`);
      return;
    }

    setRemoving(true);

    const currentShortlistedCandidates = data[role] || [];
    const updatedShortlistedCandidates = currentShortlistedCandidates
      .filter((candidate) => candidate.id !== candidateId)
      .map((candidate) => candidate.id);

    console.log(currentShortlistedCandidates);
    console.log(updatedShortlistedCandidates);

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: data[role][0].roleId,
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/updateRoleData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setData((prevData) => ({
        ...prevData,
        [role]: prevData[role].filter(
          (candidate) => candidate.id !== candidateId
        ),
      }));

      const newShortlistedCount = shortlistedCount - 1;

      setShortlistedCount(newShortlistedCount);
      setRemoving(false);
    } catch (err) {
      console.error(err);
    }
  };

  // Function to add a candidate to the shortlist
  const handleAddSelectedCandidate = (role, candidateId) => {
    setSelectedCandidates((prev) => ({
      ...prev,
      [role]: [...(prev[role] || []), candidateId],
    }));
  };

  // Function to remove a candidate from the shortlist
  const handleRemoveSelectedCandidate = (role, candidateId) => {
    setSelectedCandidates((prev) => ({
      ...prev,
      [role]: prev[role].filter((id) => id !== candidateId),
    }));
  };

  // Function to select all candidates in a shortlist
  const handleSelectAll = (role) => {
    if (!data[role]) return;

    const allCandidatesIds = data[role].map((candidate) => candidate.id);
    setSelectedCandidates((prev) => ({
      ...prev,
      [role]: allCandidatesIds,
    }));
  };

  // Function to deselect all candidates in a shortlist
  const handleDeselectAll = (role) => {
    setSelectedCandidates((prev) => ({
      ...prev,
      [role]: [],
    }));
  };

  const areAllCandidatesSelected = (role) => {
    return (
      selectedCandidates[role]?.length === data[role]?.length &&
      data[role]?.length > 0
    );
  };

  const handleToggleSelectAll = (role) => {
    if (areAllCandidatesSelected(role)) {
      handleDeselectAll(role);
    } else {
      handleSelectAll(role);
    }
  };

  return (
    <div className="flex pl-56 flex-col h-screen items-center bg-gray-100 pr-4 overflow-x-hidden">
      <div className=" ml-auto w-full flex flex-col h-full bg-gray-100">
        <div className="bg-white pl-10 pt-6 pb-4 fixed top-18 z-50 w-full border-b">
          <div className="flex justify-left items-center">
            <div>
              <h2 className="text-3xl">Shortlists</h2>
              <div className="mt-2"></div>
            </div>
          </div>
        </div>
        <div className="pl-10 pt-28">
          {loading ? (
            <>
              {" "}
              <div className="h-8 w-40 bg-gray-200 ml-4 mt-6 rounded-full mb-2.5"></div>
              <div className="grid pt-2 grid-cols-1 mr-5 ml-4 md:grid-cols-2 lg:grid-cols-3">
                {Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      role="status"
                      className="w-[375px] p-4 rounded-md animate-pulse bg-white"
                      key={index}
                    >
                      <div className="flex items-center justify-center w-full h-72 mb-4 bg-gray-300 rounded-md">
                        <svg
                          className="w-10 h-10 me-3 text-gray-200"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                        </svg>
                      </div>
                      <div className="h-5 bg-gray-200 rounded-full w-48 mb-4"></div>
                      <div className="h-4 bg-gray-200 rounded-full mb-2.5"></div>
                      <div className="h-4 bg-gray-200 rounded-full mb-2.5"></div>
                      <div className="h-4 bg-gray-200 rounded-full "></div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ))}
              </div>
            </>
          ) : error ? (
            <div className="flex justify-center">
              <div className="mt-60 bg-white max-w-md rounded-md p-4 text-center">
                <h2 className="text-xl">You don't have any roles yet!</h2>
                <button
                  onClick={() => {
                    navigate("/new-roles");
                  }}
                  className="px-4 mt-4 text-md py-2 bg-[#02B491] text-white rounded-md hover:bg-white border-2 border-[#02B491] hover:text-black"
                >
                  Create a Role
                </button>
              </div>
            </div>
          ) : Object.keys(data).length === 0 ? (
            <div className="flex justify-center">
              <div className="mt-60 bg-white max-w-md rounded-md p-4 text-center">
                <h2 className="text-xl">
                  You don't have any shortlisted candidates yet!
                </h2>
                <button
                  onClick={() => {
                    navigate("/browse-candidates");
                  }}
                  className="px-4 mt-4 text-md py-2 bg-[#02B491] text-white rounded-md hover:bg-white border-2 border-[#02B491] hover:text-black"
                >
                  Browse Candidates
                </button>
              </div>
            </div>
          ) : (
            Object.keys(data).map((role, index) => (
              <div key={role} className="rounded-md relative p-4 mb-6">
                <div className="flex">
                  <h2 className="block text-2xl pt-4 font-normal leading-6 text-gray-900">
                    {role}
                  </h2>
                </div>

                <div className="">
                  <div className=" max-w-full pt-1 mx-auto sm:max-w-none">
                    You have {data[role].length} candidates in this shortlist.
                    <div className="flex">
                      <div className="bg-white p-4 mt-2 items-center justify-center rounded-md flex gap-x-6">
                        <div
                          onClick={() => handleToggleSelectAll(role)}
                          className="flex items-center justify-center pl-1 gap-x-2 cursor-pointer"
                        >
                          <button
                            className={`rounded-md border-2 w-10 h-10 ${
                              areAllCandidatesSelected(role)
                                ? "bg-[#02B491] border-[#02B491] text-white"
                                : "border-[#02B491]"
                            }`}
                          >
                            {areAllCandidatesSelected(role) && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2.5"
                                stroke="currentColor"
                                className="w-6 h-6 mx-auto"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            )}
                          </button>
                          <p className="text-lg">Select All</p>
                        </div>
                        <div>
                          <button
                            onClick={() =>
                              handleRequestInterview(
                                data[role][0].roleURLId,
                                role
                              )
                            }
                            disabled={
                              !selectedCandidates[role] ||
                              selectedCandidates[role].length === 0
                            } // Disable if list is empty
                            data-tooltip-target="tooltip-default"
                            type="button"
                            className={`group max-w-[300px] px-8 py-2 rounded-md font-medium transition duration-200 border-2 ${
                              selectedCandidates[role]?.length > 0
                                ? "bg-[#02B491] text-white hover:bg-white hover:text-black border-[#02B491]"
                                : "bg-gray-300 text-black cursor-not-allowed border-gray-300 hover:bg-gray-300" // Disabled state
                            }`}
                          >
                            <p
                              className={`flex items-center justify-center ${
                                selectedCandidates[role]?.length > 0
                                  ? "text-white group-hover:text-black"
                                  : "text-black" // Ensure text is black when disabled
                              }`}
                            >
                              Request {selectedCandidates[role]?.length}{" "}
                              Interview
                              {selectedCandidates[role]?.length > 1 && (
                                <span>s</span>
                              )}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6 ml-2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                                />
                              </svg>
                            </p>
                          </button>

                          <p className="flex text-black italic font-normal mt-1 text-sm group-hover:text-black">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-5 mr-1"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                              />
                            </svg>
                            We’ll ask you a few questions first!
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => handleShortlist(data[role][0].roleURLId)}
                        className="px-4 ml-2 mt-2 justify-center text-sm justify-center py-2 hover:bg-gray-200 rounded-md flex flex-col items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-8"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                          />
                        </svg>
                        View full shortlist
                      </button>
                    </div>
                    <div className="max-w-full overflow-x-auto mt-2 ">
                      <div className="flex space-x-[340px] h-full">
                        {data[role].map((candidate, candidateIndex) => (
                          <div
                            key={candidateIndex}
                            className="flex-shrink-0 w-72 h-full"
                          >
                            <ShortlistCard
                              candidate={candidate}
                              handleRemoveCandidate={handleRemoveCandidate}
                              role={role}
                              handleAddSelectedCandidate={
                                handleAddSelectedCandidate
                              }
                              handleRemoveSelectedCandidate={
                                handleRemoveSelectedCandidate
                              }
                              selectedCandidates={
                                selectedCandidates[role] || []
                              }
                              removing={removing}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    {data[role].length > 2 && (
                      <div className="flex mt-4 space-x-3 justify-end pr-4 hidden">
                        <button
                          className={`carousel-prev carousel-prev-${index} absolute top-96 left-0 z-20 w-11 h-11 rounded-full flex items-center justify-center group bg-gray-900`}
                        >
                          <span className="sr-only">Previous</span>
                          <svg
                            className="fill-white group-hover:fill-[#02B491] transition duration-150 ease-in-out"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m3.914 5 3.5-3.5L6 .086 1.086 5H1v.086L.086 6 1 6.914V7h.086L6 11.914 7.414 10.5 3.914 7H13V5z" />
                          </svg>
                        </button>
                        <button
                          className={`carousel-next carousel-next-${index} absolute top-96 right-0 z-20 w-11 h-11 rounded-full flex items-center justify-center group bg-gray-900`}
                        >
                          <span className="sr-only">Next</span>
                          <svg
                            className="fill-white group-hover:fill-[#02B491] transition duration-150 ease-in-out"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m9.086 5-3.5-3.5L7 .086 11.914 5H12v.086l.914.914-.914.914V7h-.086L7 11.914 5.586 10.5l3.5-3.5H0V5z" />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default Shortlists;
