import React, { useState, useEffect } from "react";
import axios from "axios";

const ModalCompany = ({ onClose, company, setCompanyNeedUpdate }) => {
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    website: "",
    email: "",
    linkedin: "",
    description: "",
    file: null,
    equity: "",
    funding: "",
    location: "",
  });

  useEffect(() => {
    if (company) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: company.name || "",
        website: company.website || "",
        linkedin: company.linkedin || "",
        description: company.description || "",
        equity: company.equity || "",
        funding: company.funding || "",
        file: company.logo || null,
        location: company.location || "",
        email: company.email || "",
      }));
    }
  }, [company]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const errors = {};
    if (!formData.name) errors.name = "Name is required.";
    if (!formData.description) errors.description = "Description is required.";
    if (!formData.location) errors.location = "Location is required.";
    if (!formData.website) errors.website = "Website is required.";

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const updatedData = {
      fields: {
        "Startup Name": formData.name,
        "Funding Stage": formData.funding,
        Website: formData.website,
        "Startup Description": formData.description,
        Location: formData.location,
      },
    };

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      const response = await axios.patch(
        "/api/companies/updateCompanyData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Ensure this matches the server expectation
          },
        }
      );
      setCompanyNeedUpdate(true);
      console.log(response.data);
      onClose();
      // Handle successful update
    } catch (err) {
      console.error("Error updating company data:", err);
      setError("Failed to update company data: " + err.message);
    }
  };

  const CompanyLogo = ({ headerImage }) => {
    const profileFallback =
      "https://via.placeholder.com/600x400.png?text=No+Image+Available"; // Update this to your actual fallback image path

    return (
      <img
        src={headerImage || profileFallback}
        alt="profile"
        className="h-[300px] w-[400px] object-cover rounded-lg bg-[var(--overlay-2)]"
        onError={(e) => {
          e.target.src = profileFallback; // Provide a fallback source
          console.error("Failed to load image at", e.target.src);
        }}
      />
    );
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="inline-block align-middle h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-5xl w-full">
          <div className="bg-white p-10 relative">
            <button
              type="button"
              onClick={onClose}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <form onSubmit={handleSubmit}>
              <div className="space-y-12">
                <div className=" border-gray-900/10 pb-4">
                  <h2
                    className="text-4xl font-semibold leading-7 text-gray-900"
                    style={{ color: "rgb(0, 185, 140)" }}
                  >
                    {company.name}
                  </h2>

                  <h2 className="block mt-8 text-3xl font-light leading-6 text-gray-900">
                    Company Information
                  </h2>
                  <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                    <div className="flex-initial w-2/5">
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Name <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="name"
                          className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="Jumpstart"
                          value={formData.name}
                          onChange={handleChange}
                        ></input>
                        {formErrors.name && (
                          <p className="text-red-600 text-md">
                            {formErrors.name}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="website"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Website <span className="text-black">*</span>
                        </label>
                        <input
                          type="text"
                          name="website"
                          id="website"
                          className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="https://www.jumpstart-uk.com/"
                          value={formData.website}
                          onChange={handleChange}
                        ></input>
                        {formErrors.website && (
                          <p className="text-red-600 text-md">
                            {formErrors.website}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="location"
                          className="block text-md font-semibold leading-6 text-gray-900"
                        >
                          Location <span className="text-black">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            name="location"
                            id="location"
                            placeholder="North London"
                            autoComplete="address-level2"
                            value={formData.location}
                            onChange={handleChange}
                            className="block pl-2 mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          ></input>
                          {formErrors.location && (
                            <p className="text-red-600 text-md">
                              {formErrors.location}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="mb-4 mt-1">
                        <label
                          htmlFor="funding"
                          className="block text-md font-semibold text-gray-900 "
                        >
                          Funding Stage <span className="text-black">*</span>
                        </label>
                        <select
                          id="funding"
                          name="funding"
                          value={formData.funding}
                          onChange={handleChange}
                          required
                          className="bg-transparent mt-2 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block pr-10 p-1.5"
                        >
                          <option selected>Choose an option</option>
                          <option value="Pre-Seed">Pre-seed</option>
                          <option value="Seed">Seed</option>
                          <option value="Series A">Series A</option>
                          <option value="Series B">Series B</option>
                          <option value="Series B+">Series B+</option>
                          <option value="Series C+">Series C+</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <label
                        htmlFor="description"
                        className="block text-md font-semibold leading-6 text-gray-900"
                      >
                        Description <span className="text-black">*</span>
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        rows="4"
                        className="block mt-2 w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                        placeholder="A short description of your company!"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea>
                      {formErrors.description && (
                        <p className="text-red-600 text-md">
                          {formErrors.description}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" flex items-center justify-start gap-x-6">
                <button
                  type="submit"
                  className="px-2 py-2 text-sm mr-1 text-black flex rounded-lg font-medium transform hover:bg-white bg-[#02B491] hover:text-black text-white transition duration-400 border-2 border-[#02B491]"
                >
                  Save and update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCompany;
