import React from "react";
import { FaPhotoFilm } from "react-icons/fa6";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";

const HoverDevCards = () => {
  return (
    <div className="pr-8">
      <div className="grid gap-4 grid-cols-2 lg:grid-cols-4">
        <Card
          title="The platform"
          subtitle="How to use the platform"
          href="#"
          Icon={HiOutlineComputerDesktop}
        />
        <Card
          title="Job description"
          subtitle="Write the best JD"
          href="https://jumpstart-alumni.notion.site/Preparing-a-Job-Description-33504887841241d0a52f5a2a9e23e245?pvs=25"
          Icon={IoDocumentTextOutline}
        />
        <Card
          title="Video recording"
          subtitle="Book a video recording with us"
          href="https://calendly.com/d/cmhy-wb9-823/record-a-video-with-jumpstart?month=2024-09"
          Icon={FaPhotoFilm}
        />
      </div>
    </div>
  );
};

const Card = ({ title, subtitle, Icon, href }) => {
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        window.open(href);
      }}
      className="w-full cursor-pointer p-4 rounded border-[1px] border-slate-300 relative overflow-hidden group bg-white"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-[#02B491] to-[#02B491] translate-y-[100%] group-hover:translate-y-[0%] transition-transform duration-300" />

      <Icon className="absolute z-10 -top-12 -right-12 text-9xl text-slate-100 group-hover:text-[#00F2C2] group-hover:rotate-12 transition-transform duration-300" />
      <Icon className="mb-2 text-2xl text-[#02B491] group-hover:text-white transition-colors relative z-10 duration-300" />
      <h3 className="font-medium text-lg text-slate-950 group-hover:text-white relative z-10 duration-300">
        {title}
      </h3>
      <p className="text-slate-400 group-hover:text-violet-200 relative z-10 duration-300">
        {subtitle}
      </p>
    </a>
  );
};

export default HoverDevCards;
