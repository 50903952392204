import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import CandidateCard from "../../../components/candidates/candidate_shortlist.js";
import CandidatesLoading from "../../../components/candidates/candidates_loading.js";
import ShortlistCard from "../../../components/candidates/new_shortlists.js";
import { useShortlisted } from "../../../provider/ShortlistedContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Shortlist() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const query = useQuery();
  const [role, setRole] = useState("");
  const [candidates, setCandidates] = useState({});
  const [roleNeedUpdate, setRoleNeedUpdate] = useState(true);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(true);
  const [selectedCandidates, setSelectedCandidates] = useState({});
  const [removing, setRemoving] = useState(false);

  const { shortlistedCount, setShortlistedCount } = useShortlisted();

  const checkToken = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  const fetchCandidates = useCallback(
    async (candidateIds, roleName, roleId) => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get("/api/candidates/fetchCandidates", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            ids: candidateIds.join(","),
          },
        });

        const candidates = response.data.candidates;

        const transformedData = candidates.map((candidate) => {
          console.log(candidate);
          const mostRecentCohort =
            candidate.fields?.["Most recent cohort"] || "";
          let stream = "";

          if (/^JS\d+$/.test(mostRecentCohort)) {
            stream = "Generalist";
          } else if (/^JSS\d+$/.test(mostRecentCohort)) {
            stream = "Sales";
          } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
            stream = "Software Engineering";
          } else if (/^JSM\d+$/.test(mostRecentCohort)) {
            stream = "Marketing";
          }
          let sweRoles = candidate.fields["SWE relevant roles"] || "";
          if (sweRoles) {
            sweRoles = Array.isArray(sweRoles)
              ? sweRoles[0].split(",").map((role) => role.trim())
              : sweRoles.split(",").map((role) => role.trim());
            sweRoles = transformRoles(sweRoles);
          } else {
            sweRoles = [];
          }

          let salary;
          if (stream === "Generalist" || stream === "Marketing") {
            salary = candidate.fields["Salary - cleaned"] || "";
          } else if (stream === "Sales") {
            salary = candidate.fields["Base range + commission"] || "";
          } else if (stream === "Software Engineering") {
            salary = candidate.fields["SWE Salary - cleaned"] || "";
          }
          console.log("yes");
          return {
            id: candidate["id"] || "",
            url_id: candidate.fields["ID"] || "",
            createdTime: candidate["createdTime"],
            featured: candidate.fields["Featured status"][0] || "",
            years_of_exp:
              candidate.fields["Years of exp (or commercial exp)"] || "",
            cv: candidate.fields["Raw CV"] || "",
            video: candidate.fields["Raw video intro"] || "",
            linkedin: candidate.fields["LinkedIn raw URL"] || "",
            name: candidate.fields["Name"] || "",
            lastName: candidate.fields["Surname"],
            education: candidate.fields["Degree details"] || undefined,
            roles: candidate.fields["Generalist roles suitable for"],
            salary: salary,
            email: candidate.fields["Email"],
            image: candidate.fields.Photo
              ? candidate.fields.Photo[0].url
              : null,
            work: candidate.fields["Raw work experience"],
            working_style: candidate.fields["Preferred working style G/M/S"],
            github: candidate.fields["Github"],
            swe_roles: sweRoles,
            languages: candidate.fields["Languages"],
            time_coding: candidate.fields["Time spent coding"],
            description: candidate.fields["Top achievement"],
            mostRecentCohort: candidate.fields["Most recent cohort"],
            recentTarget: candidate.fields["Recent target achieved"],
            stream: stream,
            sales:
              candidate.fields[
                "Proven Sales Experience / High Potential for Sales"
              ],
            role: roleName,
          };
        });
        console.log(transformedData);
        console.log("ok2");
        setCandidates(transformedData);

        console.log("ok");

        const shortlistedCandidates = {};
        if (!shortlistedCandidates[roleName]) {
          shortlistedCandidates[roleName] = {
            roleId: roleId,
            candidates: [],
          };
        }
        shortlistedCandidates[roleName].candidates = transformedData;
        setShortlistedCandidates(shortlistedCandidates);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    },
    []
  );

  const fetchRole = useCallback(async () => {
    if (!roleNeedUpdate) return;
    try {
      const roleRequestId = query.get("roleRequestId");
      const response = await axios.get(
        `/api/roles/fetchRole?id=${roleRequestId}`
      );
      const role = response.data.role;
      const transformedData = {
        id: role["id"],
        url_id: role.fields["URL ID"],
        job_description_pdf: role.fields["Job Description PDF"],
        working_style: role.fields["Working Style"],
        hiring_lead_email: role.fields["Hiring Lead Email Address"],
        interview_process: role.fields["Interview Process"],
        hiring_lead_name: role.fields["Hiring Lead Name"],
        hiring_lead_linkedin: role.fields["Hiring Lead Linkedin"],
        image: role.fields["Logo (from Startup)"]
          ? role.fields["Logo (from Startup)"][0].url
          : null,
        spotlight: role.fields["Spotlight"],
        compensation: role.fields["Compensation"],
        startup_description: role.fields["Startup Description (from Startup)"],
        website: role.fields["Website (from Startup)"],
        team_size: role.fields["Team Size (from Startup)"],
        funding: role.fields["Funding Stage (from Startup)"],
        startup_name: role.fields["Name (from Startup)"][0],
        linkedin: role.fields["Startup Linkedin (from Startup)"],
        title: role.fields["Role Title"],
        key_experience: role.fields["Specific Experience"],
        additional_info: role.fields["Additional Information"],
        location: role.fields["Location (from Startup)"],
        shortlisted_candidates: role.fields["Shortlisted Candidates"],
      };
      setRole(transformedData);

      if (role.fields["Shortlisted Candidates"]) {
        const candidateIds = role.fields["Shortlisted Candidates"];
        const roleName = role.fields["Role Title"];
        const roleId = role["id"];
        await fetchCandidates(candidateIds, roleName, roleId);
      } else {
        setLoading(false);
      }

      setRoleNeedUpdate(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [roleNeedUpdate, fetchCandidates, query]);

  useEffect(() => {
    checkToken();
    fetchRole();
  }, [checkToken, fetchRole]);

  const handleSelectAll = (role) => {
    console.log(selectedCandidates);
    if (!candidates || !candidates[role]) return;

    const allCandidateIds = candidates[role].map((candidate) => candidate.id);
    setSelectedCandidates((prev) => ({
      ...prev,
      [role]: allCandidateIds,
    }));
    console.log(selectedCandidates);
  };

  const handleDeselectAll = (role) => {
    console.log("ok");
    setSelectedCandidates((prev) => ({
      ...prev,
      [role]: [],
    }));
  };

  const areAllCandidatesSelected = (role) => {
    if (!selectedCandidates || !candidates[role]) return false;
    console.log("ok");
    return (
      selectedCandidates[role].length === candidates[role].length &&
      candidates[role].length > 0
    );
  };

  const handleToggleSelectAll = (role) => {
    console.log(areAllCandidatesSelected(role));
    console.log(candidates[role]);
    console.log(selectedCandidates);
    if (areAllCandidatesSelected(role)) {
      handleDeselectAll(role);
    } else {
      handleSelectAll(role);
    }
  };

  const handleRequestInterview = async () => {
    const roleRequestId = query.get("roleRequestId");
    if (!selectedCandidates[role] || selectedCandidates[role].length === 0) {
      console.error("No candidates selected for interview");
      return;
    }

    const updatedData = {
      fields: {
        "Selected Candidates": selectedCandidates[role], // Update with selected candidates for the role
      },
      roleId: role.id,
    };

    try {
      const token = localStorage.getItem("token");
      // Update the role's "Selected Candidates" field
      await axios.patch(`/api/roles/updateRoleData/`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // After successfully updating the selected candidates, navigate to request interview page
      navigate(`/new-request-interview?roleRequestId=${roleRequestId}`);
    } catch (err) {
      console.error(
        "Error updating selected candidates:",
        err.response?.data?.error || err.message
      );
    }
  };

  const handleRemoveCandidate = async (role, candidateId) => {
    const roleRequestId = query.get("roleRequestId");
    if (!candidates) {
      console.error(`Role ${role} not found in data`);
      return;
    }

    setRemoving(true);

    const currentShortlistedCandidates = candidates || [];
    const updatedShortlistedCandidates = currentShortlistedCandidates
      .filter((candidate) => candidate.id !== candidateId)
      .map((candidate) => candidate.id);

    console.log(currentShortlistedCandidates);
    console.log(updatedShortlistedCandidates);

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: role.id,
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/updateRoleData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setCandidates((prevData) => ({
        ...prevData,
        [role]: prevData[role].filter(
          (candidate) => candidate.id !== candidateId
        ),
      }));

      const newShortlistedCount = shortlistedCount - 1;

      setShortlistedCount(newShortlistedCount);
      setRemoving(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddSelectedCandidate = (role, candidateId) => {
    setSelectedCandidates((prev) => ({
      ...prev,
      [role]: [...(prev[role] || []), candidateId],
    }));
  };

  // Function to remove a candidate from the shortlist
  const handleRemoveSelectedCandidate = (role, candidateId) => {
    setSelectedCandidates((prev) => ({
      ...prev,
      [role]: prev[role].filter((id) => id !== candidateId),
    }));
  };

  return (
    <div className="bg-gray-100 px-10 pl-64 pb-20 min-h-screen">
      <button
        type="button"
        onClick={() => navigate("/shortlists")}
        className="pt-6 pl-6"
      >
        ← All shortlists
      </button>

      {loading ? (
        <div className=" pt-4">
          <div className="h-12 ml-4 bg-gray-200 rounded-full w-48 mb-4"></div>
          <CandidatesLoading />
        </div>
      ) : (
        <>
          <h2 className="pl-6 pt-4 text-4xl font-normal">{role.title}</h2>
          {candidates.length === 0 ? (
            <div className="pl-6 pt-4">
              <p>You have not shortlisted any candidates for this role yet.</p>
              <button
                type="button"
                onClick={() => navigate("/browse-candidates")}
                className="mt-4 px-4 py-2 bg-[#02B491] hover:bg-white border-2 border-[#02B491] hover:text-black text-white rounded"
              >
                Browse Candidates
              </button>
            </div>
          ) : (
            <div className="pl-6">
              You have {candidates.length} candidates in this shortlist.
              <div className="flex">
                <div className="bg-white p-4 mt-2 items-center justify-center rounded-md flex gap-x-6">
                  <div
                    onClick={() => handleToggleSelectAll(role)}
                    className="flex items-center justify-center pl-1 gap-x-2 cursor-pointer"
                  >
                    <button
                      className={`rounded-md border-2 w-10 h-10 ${
                        areAllCandidatesSelected(role)
                          ? "bg-[#02B491] border-[#02B491] text-white"
                          : "border-[#02B491]"
                      }`}
                    >
                      {areAllCandidatesSelected(role) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2.5"
                          stroke="currentColor"
                          className="w-6 h-6 mx-auto"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      )}
                    </button>
                    <p className="text-lg">Select All</p>
                  </div>
                  <div>
                    <button
                      onClick={() => handleRequestInterview()}
                      disabled={
                        !selectedCandidates[role] ||
                        selectedCandidates[role].length === 0
                      } // Disable if list is empty
                      data-tooltip-target="tooltip-default"
                      type="button"
                      className={`group max-w-[300px] px-8 py-2 rounded-md font-medium transition duration-200 border-2 ${
                        selectedCandidates[role]?.length > 0
                          ? "bg-[#02B491] text-white hover:bg-white hover:text-black border-[#02B491]"
                          : "bg-gray-300 text-black cursor-not-allowed border-gray-300 hover:bg-gray-300" // Disabled state
                      }`}
                    >
                      <p
                        className={`flex items-center justify-center ${
                          selectedCandidates[role]?.length > 0
                            ? "text-white group-hover:text-black"
                            : "text-black" // Ensure text is black when disabled
                        }`}
                      >
                        Request {selectedCandidates[role]?.length} Interview
                        {selectedCandidates[role]?.length > 1 && <span>s</span>}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6 ml-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                          />
                        </svg>
                      </p>
                    </button>

                    <p className="flex text-black italic font-normal mt-1 text-sm group-hover:text-black">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-5 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                        />
                      </svg>
                      We’ll ask you a few questions first!
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {candidates.map((candidate, index) => (
                  <ShortlistCard
                    candidate={candidate}
                    handleRemoveCandidate={handleRemoveCandidate}
                    role={role}
                    handleAddSelectedCandidate={handleAddSelectedCandidate}
                    handleRemoveSelectedCandidate={
                      handleRemoveSelectedCandidate
                    }
                    selectedCandidates={selectedCandidates[role] || []}
                    removing={removing}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Shortlist;
