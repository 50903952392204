import React, { useState } from "react";
import OpenNewPageIcon from "../../images/open-newpage.svg";
import ModalRole from "./modal_role";

function RoleDetails({
  activeStep,
  setActiveStep,
  role,
  setRole,
  roles,
  setRoleNeedUpdate,
  loading,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility

  const renderField = (label, value, fieldName) => {
    if (loading) {
      return (
        <div className="h-6 ml-4 bg-gray-200 rounded-full w-48 mb-4"></div>
      );
    }

    if (fieldName === "job description PDF" && value) {
      return (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline text-blue-600"
        >
          {label}
        </a>
      );
    }

    if (fieldName === "equity") {
      return value ? (
        <span>{`Open to offering equity? ${value}`}</span>
      ) : (
        <span className="text-red-600">(missing {fieldName})</span>
      );
    }

    return value ? (
      <span>{value}</span>
    ) : (
      <span className="text-red-600">(missing {fieldName})</span>
    );
  };

  const fieldsToCheck = [
    "title",
    "compensation",
    "equity",
    "job_description_link",
  ];

  const isRoleComplete = fieldsToCheck.every(
    (field) => role[field] && role[field].trim() !== ""
  );

  const handleContinueClick = () => {
    if (isRoleComplete) {
      setActiveStep("Message");
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div className={`relative ${activeStep === "Role Details" ? "" : "flex"}`}>
      <h2
        className={`whitespace-nowrap text-4xl py-8 font-normal leading-6 ${
          activeStep === "Role Details" ? "text-[#02B491]" : "text-gray-900"
        }`}
      >
        2. Role Details
      </h2>
      {activeStep === "Role Details" ? (
        <div>
          <div className="border-2 relative rounded-lg border-[#02B491] max-w-[700px] h-auto w-full px-auto pb-2">
            <div className="w-full p-2">
              <div className="flex w-full">
                <div className="text-lg font-semibold pr-2">
                  {renderField("Title", role.title, "title")}
                </div>
                <div className="pt-1 text-md text-gray-500">
                  {renderField(
                    "Compensation",
                    role.compensation,
                    "compensation"
                  )}
                </div>
              </div>

              <div className="w-full text-gray-500">
                <span className="text-black">Hiring Lead: </span>
                {renderField(
                  "Hiring Lead Name",
                  role.hiring_lead_name,
                  "hiring lead name"
                )}
                ,{" "}
                {renderField(
                  "Hiring Lead Email",
                  role.hiring_lead_email,
                  "hiring lead email"
                )}
              </div>

              <div className="pt-1 text-md text-blue-600 font-semibold">
                {renderField(
                  "Job Description PDF",
                  role.job_description_link,
                  "job description PDF"
                )}
              </div>
              <button
                onClick={() => setIsModalOpen(true)}
                className="absolute top-2 right-10 text-[#02B491] text-md font-semibold cursor-pointer hover:underline"
              >
                Edit Role
              </button>

              <img
                className="absolute top-2 right-2 bg-white h-6 w-6 text-gray-400 cursor-pointer"
                src={OpenNewPageIcon}
                alt="Eye Icon"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`role/${role.url_id}`, "_blank");
                }}
              />
            </div>
          </div>
          {isModalOpen && (
            <ModalRole
              onClose={() => setIsModalOpen(false)}
              role={role}
              setRoleNeedUpdate={setRoleNeedUpdate}
            />
          )}
          <button
            onClick={handleContinueClick}
            className="px-8 py-2 mt-4 mb-4 rounded-md bg-[#02B491] text-white font-bold transition duration-200 hover:bg-white hover:text-black border-2 border-[#02B491]"
          >
            {isRoleComplete ? "Continue" : "Complete Role Description"}
          </button>
        </div>
      ) : (
        <div className="pl-4 pt-6">
          <div
            className="text-[#02B491] absolute top-2 right-0 text-lg cursor-pointer hover:underline"
            onClick={() => setIsModalOpen(true)}
          >
            Edit
          </div>
          <h2 className="pl-4 text-xl font-semibold text-black">
            {renderField("Title", role.title, "title")}
          </h2>
          <h2 className="pl-4 text-md text-gray-600 flex font-semibold">
            {renderField("Compensation", role.compensation, "compensation")}
          </h2>

          <div className="pl-4 text-md text-blue-600 font-semibold">
            {renderField(
              "Job Description PDF",
              role.job_description_link,
              "job description PDF"
            )}
          </div>
          <div className="pl-4 text-md text-gray-600 font-semibold">
            {renderField("Open to offering equity?", role.equity, "equity")}
          </div>
          <div className="pl-4 pb-6 text-md text-gray-600 font-semibold">
            {renderField("Working style", role.working_style, "working style")}
          </div>
          {isModalOpen && (
            <ModalRole
              onClose={() => setIsModalOpen(false)}
              role={role}
              setRoleNeedUpdate={setRoleNeedUpdate}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default RoleDetails;
