import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useAsyncError, useNavigate, useLocation } from "react-router-dom";
import CandidatesLoading from "../../components/candidates/candidates_loading.js";
import CandidatesPreviewCard from "../../components/candidates/candidates_preview_card.js";

export default function JSPreview() {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get query parameters

  const [data, setData] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterStream, setFilterStream] = useState([]);
  const [roles, setRoles] = useState([]);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [recommendedCandidates, setRecommendedCandidates] = useState([]);
  const [newRoleForm, setNewRoleForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Add search query state
  const [candidatesStatus, setCandidatesStatus] = useState([]);
  const [rolesNeedUpdate, setRolesNeedUpdate] = useState(true);
  const [toasts, setToasts] = useState([]);

  const [savedCandidates, setSavedCandidates] = useState(() => {
    const savedCandidates = localStorage.getItem("candidates");
    return savedCandidates ? JSON.parse(savedCandidates) : [];
  });

  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  const parseSalaryRange = (salary) => {
    const salaryRange = salary.match(/£(\d+)k?-?£?(\d+)?k?/i);
    if (salaryRange) {
      const minSalary = parseInt(salaryRange[1]) * 1000;
      const maxSalary = salaryRange[2]
        ? parseInt(salaryRange[2]) * 1000
        : minSalary;
      return { minSalary, maxSalary };
    }
    return { minSalary: null, maxSalary: null };
  };

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "/api/candidates/fetchPreviewCandidates",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const candidates = response.data.candidates;

      const transformedData = candidates.map((candidate) => {
        const fields = candidate.fields || {};

        let sweRoles = fields["SWE relevant roles"] || "";
        if (sweRoles) {
          sweRoles = Array.isArray(sweRoles)
            ? sweRoles[0].split(",").map((role) => role.trim())
            : sweRoles.split(",").map((role) => role.trim());
          sweRoles = transformRoles(sweRoles);
        } else {
          sweRoles = [];
        }

        const stream = fields["Stream (from CRM)"][0];

        let salary;
        if (stream === "Generalist" || stream === "Marketing") {
          salary = fields["Salary - cleaned"] || "";
        } else if (stream === "Sales") {
          salary = fields["Base range + commission"] || "";
        } else if (stream === "Software Engineering") {
          salary = fields["SWE Salary - cleaned"] || "";
        }

        const { minSalary, maxSalary } = parseSalaryRange(salary);

        return {
          id: candidate["id"] || "",
          url_id: fields["ID"] || "",
          minimum_salary: minSalary || 0,
          maximum_salary: maxSalary || 0,
          createdTime: candidate["createdTime"] || "",
          years_of_exp: fields["Years of exp (or commercial exp)"] || 0,
          cv: fields["Raw CV"] || "",
          video: fields["Raw video intro"] || "",
          linkedin: fields["LinkedIn raw URL"] || "",
          industry_exp: fields["Industry experience"] || undefined,
          name: fields["Name"] || "",
          lastName: fields["Surname"] || "",
          education: fields["Degree details"] || undefined,
          roles: fields["Generalist roles suitable for"] || "",
          salary: salary || "",
          email: fields["Email"] || "",
          image: fields.Photo ? fields.Photo[0].url : null,
          work: fields["Raw work experience"] || "",
          working_style: fields["Preferred working style G/M/S"] || "",
          github: fields["Github"] || "",
          swe_roles: sweRoles,
          languages: fields["Languages"] || "",
          time_coding: fields["Time spent coding"] || "",
          description: fields["Top achievement"] || "",
          mostRecentCohort: fields["Most recent cohort"] || "",
          recentTarget: fields["Recent target achieved"] || "",

          search_fields: fields["Search Aggregate Fields"] || "", // Ensure this field is correctly populated
          sales:
            fields["Proven Sales Experience / High Potential for Sales"] || "",
          stream: fields["Stream (from CRM)"][0] || "",
        };
      });

      setCandidates(transformedData);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const redStatuses = [
      "Rejected @ CV",
      "Rejected @ Interview",
      "Rejected Interview Request",
      "Company withdrawn",
      "Candidate withdrawn",
    ];

    const getStatusPriority = (status) => {
      if (status === "Application pending") return 1;
      if (status === "Interviewing") return 3;
      if (status === "Match requested") return 4;
      if (redStatuses.includes(status)) return 5;
      return 2; // No status
    };

    const allCandidatesWithStatus = candidates.map((candidate) => {
      const candidateStatus = candidatesStatus.find(
        (status) => status.id === candidate.id
      );
      return {
        ...candidate,
        status: candidateStatus ? candidateStatus.status : null,
        isRecommended: recommendedCandidates.includes(candidate.id),
      };
    });

    const sortedCandidates = allCandidatesWithStatus.sort((a, b) => {
      const aPriority = getStatusPriority(a.status);
      const bPriority = getStatusPriority(b.status);

      if (aPriority !== bPriority) return aPriority - bPriority;

      // When priorities are equal, sort recommended candidates first
      if (a.isRecommended && !b.isRecommended) return -1;
      if (!a.isRecommended && b.isRecommended) return 1;

      return new Date(b.createdTime) - new Date(a.createdTime);
    });

    setData(sortedCandidates);
  }, [candidates, candidatesStatus, recommendedCandidates]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    localStorage.setItem("candidates", JSON.stringify(savedCandidates));
  }, [savedCandidates]);

  // New effect to handle query parameter filtering
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const streamParam = queryParams.get("stream");

    if (streamParam) {
      setFilterStream([streamParam]); // Set the filter to match the stream param
    }
  }, [location.search]);

  const filteredData = data.filter((candidate) => {
    const matchesStream = filterStream.length
      ? filterStream.includes(candidate.stream)
      : true;

    return matchesStream;
  });

  useEffect(() => {
    if (toasts.length > 0) {
      const timers = toasts.map((toast, index) =>
        setTimeout(() => {
          setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
        }, 5000)
      );

      // Cleanup all timeouts when the component unmounts or the `toasts` array changes
      return () => timers.forEach((timer) => clearTimeout(timer));
    }
  }, [toasts]);

  return (
    <div className="relative  bg-gray-100 min-h-screen">
      <div className="fixed z-50 top-6 left-6 flex items-center mb-6 bg-[#00F2C2]">
        <div className="flex items-center">
          <img
            src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
            className="h-6 my-4 px-4 sm:block hidden"
            alt="Jumpstart Logo"
          />
        </div>
      </div>
      <div className="flex flex-col items-center pt-10">
        <h2 className="font-semibold text-black text-3xl">
          A sneak peek of Jumpstart's most recent candidates 🚀
        </h2>
        <p className="font-semibold pt-8">The Jumpstart process:</p>
        <p>
          1. We connect you with{" "}
          <span className="font-semibold pt-4">the top 1-2% of candidates</span>{" "}
          suitable for generalist, marketing or sales roles.
        </p>
        <p>
          2. You take charge of the interview process (with our support!) and we
          provide ongoing mentorship & support to successful candidates. ✅
        </p>
        <p className="pt-8">
          👉👉👉 Keen to meet candidates? Email{" "}
          <a
            href="mailto:darcy@jumpstart-uk.com"
            className="text-blue-500 hover:underline"
          >
            Darcy
          </a>{" "}
          to register interest 👈👈👈
        </p>
      </div>
      <div className="flex ml-auto h-full pb-20  items-center bg-gray-100">
        <div className="w-full flex justify-center">
          <div className="max-w-[1500px]  w-full">
            {loading ? (
              <div className="pt-8 pl-4">
                <CandidatesLoading />
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 pt-4 pl-4 mr-5 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {filteredData.map((candidate, index) => (
                    <CandidatesPreviewCard key={index} candidate={candidate} />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
