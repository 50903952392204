import React, { useState } from "react";
import axios from "axios";

const EditJobDescriptionModal = ({
  onClose,
  formData,
  setFormData,
  setFile,
  file,
  onFileUpload,
}) => {
  const [message, setMessage] = useState("");
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a file to upload.");
      return;
    }

    const CLOUDINARY_CLOUD_NAME = "dkjsnpplv";
    const CLOUDINARY_UPLOAD_PRESET = "ml_default";
    const CLOUDINARY_API_KEY = "815772339983468";
    const CLOUDINARY_FOLDER = "JobDescription";

    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/raw/upload`;

    try {
      const {
        data: { signature, timestamp },
      } = await axios.get("/api/cloudinarySignature", {
        params: { upload_preset: CLOUDINARY_UPLOAD_PRESET },
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
      formData.append("timestamp", timestamp);
      formData.append("api_key", CLOUDINARY_API_KEY);
      formData.append("signature", signature);
      formData.append("folder", CLOUDINARY_FOLDER);

      const response = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setMessage("File uploaded successfully!");
      setFormData({
        ...formData,
        job_description_link: response.data.secure_url,
      });
      onFileUpload(response.data.secure_url);
    } catch (error) {
      setMessage(
        `Error: ${error.response ? error.response.data.message : error.message}`
      );
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="inline-block align-middle h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-4xl w-full">
          <div className="bg-white p-6 relative">
            <button
              type="button"
              onClick={onClose}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="space-y-12">
              <div className="border-gray-900/10 pb-4">
                <h2 className="text-2xl font-semibold leading-7 text-gray-900">
                  Enter a new link or upload a new file
                </h2>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="block mt-2 pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                  onChange={handleFileChange}
                ></input>
                <div className="flex items-center justify-start gap-x-6 mt-4">
                  <button
                    type="button"
                    onClick={handleUpload}
                    className="px-2 py-2 text-sm mr-1 text-black flex rounded-lg font-medium transform hover:bg-white bg-[#02B491] hover:text-black text-white transition duration-400 border-2 border-[#02B491]"
                  >
                    Save and update
                  </button>
                </div>
                {message && <p>{message}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJobDescriptionModal;
