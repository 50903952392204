import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

function RoleDetails({ moveToNext }) {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRole, setSelectedRole] = useState(
    JSON.parse(localStorage.getItem("selectedRole")) || null
  );
  const [formData, setFormData] = useState({
    title: "",
    job_description_link: "",
    email: "",
    key_experience: "",
    compensation: "",
    working_style: "",
    hiring_lead_name: "",
    hiring_lead_linkedin: "",
    hiring_lead_email: "",
    interview_process: "",
    additional_info: "",
    spotlight: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;
        const transformedData = roles.map((role) => ({
          id: role["id"],
          title: role["Role Title"],
          hiring_lead_name: role["Hiring Lead Name"],
          hiring_lead_email: role["Hiring Lead Email Address"],
          hiring_lead_linkedin: role["Hiring Lead Linkedin"],
          key_experience: role["Specific Experience"],
          job_description: role["Job Description PDF"],
          startup: role["Startup"],
          compensation: role["Compensation"],
          interviewProcess: role["Interview Process"],
          working_style: role["Working Style"],
          spotlight: role["Spotlight"],
        }));

        setRoles(transformedData);
        console.log(roles);
        console.log(transformedData);
        if (transformedData.length > 0 && !selectedRole) {
          setSelectedRole(transformedData[0]);
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRole) {
      localStorage.setItem("selectedRole", JSON.stringify(selectedRole));
      setFormData({
        title: selectedRole.title || "",
        compensation: selectedRole.compensation || "",
        job_description_link: selectedRole.job_description || "",
        hiring_lead_name: selectedRole.hiring_lead_name || "",
        hiring_lead_email: selectedRole.hiring_lead_email || "",
        hiring_lead_linkedin: selectedRole.hiring_lead_linkedin || "",
        key_experience: selectedRole.key_experience || "",
        interview_process: selectedRole.interviewProcess || "",
        working_style: selectedRole.working_style || "",
        spotlight: selectedRole.spotlight || "",
      });
    }
  }, [selectedRole]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedRole === "newRole") {
      CreateRole(e);
    } else {
      UpdateRole(e);
    }
  };

  const UpdateRole = async (e) => {
    const updatedData = {
      fields: {
        "Role Title": formData.title,
        "Hiring Lead Name": formData.hiring_lead_name,
        "Hiring Lead Email Address": formData.hiring_lead_email,
        "Specific Experience": formData.key_experience,
        "Hiring Lead Linkedin": formData.hiring_lead_linkedin,
        "Interview Process": formData.interview_process,
        "Working Style": formData.working_style,
        Compensation: formData.compensation,
      },
      roleId: selectedRole.id,
    };

    console.log(updatedData);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const role = response.data.updatedRoleData;

      if (role && role.fields) {
        const transformedData = {
          id: role.id,
          title: role.fields["Role Title"],
          hiring_lead_name: role.fields["Hiring Lead Name"],
          hiring_lead_email: role.fields["Hiring Lead Email Address"],
          hiring_lead_linkedin: role.fields["Hiring Lead Linkedin"],
          key_experience: role.fields["Specific Experience"],
          job_description: role.fields["Job Description PDF"],
          startup: role.fields["Startup"],
          compensation: role.fields["Compensation"],
          interviewProcess: role.fields["Interview Process"],
          working_style: role.fields["Working Style"],
          spotlight: role.fields["Spotlight"],
        };

        console.log(response.data);
        setSelectedRole(transformedData);

        moveToNext();
      } else {
        console.error("Unexpected response structure:", role);
        // Handle unexpected response structure
      }
    } catch (err) {
      console.error(err);
      // Handle error
    }
  };

  const CreateRole = async (e) => {
    const data = {
      fields: {
        "Role Title": formData.title,
        "Hiring Lead Name": formData.hiring_lead_name,
        "Hiring Lead Email Address": formData.hiring_lead_email,
        "Hiring Lead Linkedin": formData.hiring_lead_linkedin,
        "Job Description PDF": formData.job_description_link,
        "Working Style": formData.working_style,
        "Additional Information": formData.additional_info,
        "Specific Experience": formData.key_experience,
        "Interview Process": formData.interview_process,
        Compensation: formData.compensation,
      },
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post("/api/roles/createRole", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const role = response.data.newRoleData;

      if (role && role.fields) {
        const transformedData = {
          id: role.id,
          title: role.fields["Role Title"],
          hiring_lead_name: role.fields["Hiring Lead Name"],
          hiring_lead_email: role.fields["Hiring Lead Email Address"],
          hiring_lead_linkedin: role.fields["Hiring Lead Linkedin"],
          key_experience: role.fields["Specific Experience"],
          job_description: role.fields["Job Description PDF"],
          startup: role.fields["Startup"],
          compensation: role.fields["Compensation"],
          interviewProcess: role.fields["Interview Process"],
          working_style: role.fields["Working Style"],
          spotlight: role.fields["Spotlight"],
        };

        console.log(response.data);
        setSelectedRole(transformedData);

        moveToNext();
      } else {
        console.error("Unexpected response structure:", role);
        // Handle unexpected response structure
      }
    } catch (err) {
      console.error(err);
      // Handle error
    }
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  return (
    <div className="flex flex-col items-center bg-white pb-10 pl-4 pr-4">
      <div className="max-w-3xl w-full flex flex-col h-full bg-white">
        <h2 className="text-3xl font-normal pt-4 text-black">
          What role do you wish to speak to these candidates for
        </h2>

        <div className="mt-2">
          {roles &&
            roles.map((role, index) => (
              <span
                key={index}
                onClick={() => handleRoleSelect(role)}
                className={`inline-flex mb-2 mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium cursor-pointer ${
                  selectedRole && selectedRole.id === role.id
                    ? "bg-purple-600 text-white"
                    : "bg-purple-100 text-purple-700"
                }`}
              >
                {role.title}
              </span>
            ))}
          <span
            onClick={() => handleRoleSelect("newRole")}
            className={`inline-flex mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium cursor-pointer ${
              selectedRole && selectedRole === "newRole"
                ? "bg-purple-600 text-white"
                : "bg-purple-100 text-purple-700"
            }`}
          >
            Create a new role
          </span>
        </div>

        {selectedRole && (
          <form onSubmit={handleSubmit}>
            <div className="space-y-12">
              <div className="border-gray-900/10 pb-12">
                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
                  <div className="sm:col-span-8 flex space-x-4">
                    <div className="flex-1">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Role Title
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Sales"
                          value={formData.title}
                          onChange={handleChange}
                        />
                        {formErrors.title && (
                          <p className="text-red-600 text-sm">
                            {formErrors.title}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex-1">
                      <label
                        htmlFor="job_description_link"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Link to job description
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="job_description_link"
                          id="job_description_link"
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="https://www.jumpstart-uk.com/"
                          value={formData.job_description_link}
                          onChange={handleChange}
                        ></input>
                        {formErrors.job_description_link && (
                          <p className="text-red-600 text-sm">
                            {formErrors.job_description_link}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="compensation"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Compensation (including any bonus information if relevant)
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="compensation"
                        id="compensation"
                        autoComplete="compensation"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="£50,000 - £60,000"
                        value={formData.compensation}
                        onChange={handleChange}
                      />
                      {formErrors.compensation && (
                        <p className="text-red-600 text-sm">
                          {formErrors.compensation}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="key_experience"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Are there any key skills or experiences you need this
                      candidate to have?
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="key_experience"
                        name="key_experience"
                        rows="3"
                        className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="A few lines on what the ideal looks likes!"
                        value={formData.key_experience}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="interview_process"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      What is the interview process for this role?
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="interview_process"
                        name="interview_process"
                        rows="4"
                        className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="A short description of the interview process for this role!"
                        value={formData.interview_process}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="job_description_link"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      What Spotlight should be shown to candidates?
                    </label>
                    <div className="mt-1">
                      <input
                        type="url"
                        name="spotlight"
                        id="spotlight"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="https://www.jumpstart-uk.com/"
                        value={formData.spotlight}
                        onChange={handleChange}
                      ></input>
                      {formErrors.spotlight && (
                        <p className="text-red-600 text-sm">
                          {formErrors.spotlight}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="additional_info"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Additional info about the spotlight
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="additional_info"
                        name="additional_info"
                        rows="2"
                        className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="this was recorded back in May 2024 for a slightly different role – but it should give you a good sense of the company’s direction"
                        value={formData.additional_info}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="sm:col-span-8 flex flex-wrap space-x-4">
                    <div className="flex-1">
                      <label
                        htmlFor="hiring_lead_name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Name of the hiring manager for the role
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="hiring_lead_name"
                          id="hiring_lead_name"
                          autoComplete="name"
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="John Smith"
                          value={formData.hiring_lead_name}
                          onChange={handleChange}
                        />
                        {formErrors.hiring_lead_name && (
                          <p className="text-red-600 text-sm">
                            {formErrors.hiring_lead_name}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex-1">
                      <label
                        htmlFor="hiring_lead_email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Hiring manager's email address
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          name="hiring_lead_email"
                          id="hiring_lead_email"
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="john.smith@yourcompany.com"
                          value={formData.hiring_lead_email}
                          onChange={handleChange}
                        />
                        {formErrors.hiring_lead_email && (
                          <p className="text-red-600 text-sm">
                            {formErrors.hiring_lead_email}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="hiring_lead_linkedin"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Hiring manager's LinkedIn
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="hiring_lead_linkedin"
                        id="hiring_lead_linkedin"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="https://www.linkedin.com/in/john-smith/"
                        value={formData.hiring_lead_linkedin}
                        onChange={handleChange}
                      />
                      {formErrors.hiring_lead_linkedin && (
                        <p className="text-red-600 text-sm">
                          {formErrors.hiring_lead_linkedin}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="working_style"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      What is the working style for this role?
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="working_style"
                        id="working_style"
                        autoComplete="working_style"
                        className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Hybrid - 1 day per week in office"
                        value={formData.working_style}
                        onChange={handleChange}
                      />
                      {formErrors.working_style && (
                        <p className="text-red-600 text-sm">
                          {formErrors.working_style}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className="px-6 py-2 mb-4 text-sm bg-black text-black rounded-lg font-medium transform hover:-translate-y-1 transition duration-400"
                style={{
                  backgroundColor: "rgb(0, 242, 194)",
                }}
              >
                {selectedRole === "newRole"
                  ? "Create new role"
                  : "Save job role"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default RoleDetails;
