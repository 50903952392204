import React, { useEffect, useState, useRef } from "react";
import { useAsyncError, useNavigate } from "react-router-dom";

function RoleCard({ role, currentlyPlaying, setCurrentlyPlaying }) {
  const navigate = useNavigate();

  const videoRef = useRef(null);

  const handlePlay = () => {
    if (currentlyPlaying && currentlyPlaying !== videoRef.current) {
      currentlyPlaying.pause(); // Pause any other video that is playing
    }
    setCurrentlyPlaying(videoRef.current); // Set this video as currently playing
  };

  const fallbackImageUrl =
    "https://cdn-icons-png.flaticon.com/512/4120/4120345.png";

  return (
    <div className="relative min-h-[590px] h-full max-w-lg mx-auto mt-5 pt-4 pr-4 pl-4 bg-white rounded-lg  overflow-hidden flex flex-col h-full  hover:shadow-[0_3px_10px_rgb(0,0,0,0.2)] transition duration-400">
      <div>
        <div className="relative">
          <div>
            {role.dropbox_url ? (
              <video
                ref={videoRef}
                poster={role.image || fallbackImageUrl}
                src={role.dropbox_url}
                className="w-full h-72 border object-cover rounded-xl bg-[var(--overlay-2)]"
                controls
                onPlay={handlePlay}
                onError={(e) => {
                  console.error("Failed to load video at", e.target.src);
                }}
              />
            ) : role.loom_url ? (
              <iframe
                src={role.loom_url}
                ref={videoRef}
                onPlay={handlePlay}
                className="w-full h-72 object-cover rounded-xl bg-[var(--overlay-2)]"
              ></iframe>
            ) : (
              <img
                className="w-full h-72 object-cover rounded-lg border"
                src={role.image || fallbackImageUrl}
                alt="Role"
                onError={(e) => {
                  e.target.src = fallbackImageUrl;
                }}
              />
            )}
          </div>
        </div>
      </div>
      {role.spotlight_context && (
        <span className="flex italic inline-flex mr-2 items-center gap-x-1 text-sm md:text-md font-medium text-gray-600 pt-2">
          {role.spotlight_context}
        </span>
      )}
      <div className="pt-2 flex flex-col flex-grow justify-between">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <p className="text-black font-semibold">{role.title}</p>
          </div>
        </div>

        <div className="mt-2">
          {role.compensation && (
            <span className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md bg-purple-100 px-4 py-1 text-sm md:text-md font-medium text-purple-700">
              Salary range: {role.compensation}
            </span>
          )}
          {role.team_size && (
            <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-green-100 px-4 py-1 text-sm md:text-md font-medium text-green-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>
              {role.team_size} people in the team
            </span>
          )}

          {role.funding && (
            <span className="flex inline-flex mr-2 mb-2 items-center gap-x-1 rounded-md bg-red-100 px-4 py-1 text-sm md:text-md font-medium text-red-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
                />
              </svg>

              {role.funding}
            </span>
          )}

          {role.location && (
            <span className="flex inline-flex mr-2 items-center gap-x-1 rounded-md bg-blue-100 px-4 py-1 text-sm md:text-md font-medium text-blue-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="size-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                />
              </svg>
              {role.location}
            </span>
          )}
        </div>

        <button
          onClick={(e) => {
            e.preventDefault();
            // Store the current page before opening the candidate details in a new tab
            sessionStorage.setItem("lastVisitedPage", window.location.pathname);

            // Open the candidate page in a new tab
            window.open(`role?roleId=${role.url_id}`, "_blank");
          }}
          className="mb-4 mt-auto flex justify-center w-full hover:bg-[#02B491] hover:text-white py-2 px-4 rounded-md bg-white text-black border-2 border-[#02B491]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
          Learn more
        </button>
      </div>
    </div>
  );
}

export default RoleCard;
