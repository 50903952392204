import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ShortlistRecap from "./shortlist_recap";
import LoadingBlack from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path

function Message({ role, activeStep, sendRequest, candidates }) {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    interviewer_name: "",
    other_emails: "",
    interviewer_email: "",
    eye_catching_information: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (role) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        interviewer_name: role.hiring_lead_name || "",
        interviewer_email: role.hiring_lead_email || "",
      }));
    }
  }, [role]);

  const clearShortlist = async () => {
    const token = localStorage.getItem("token");
    const updatedData = {
      fields: {
        "Shortlisted Candidates": [],
      },
      roleId: role.id,
    };

    try {
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      console.error(err);
      // Handle error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const token = localStorage.getItem("token");
    const commonFields = {
      Role: [role.id],
      "Other emails": formData.other_emails,
      "Interviewer's Email": formData.interviewer_email,
      "Interviewer's Name": formData.interviewer_name,
      "Eye Catching Information": formData.eye_catching_information,
      Stage: "Match requested",
      "Match type": "Match Request",
    };

    try {
      for (let candidate of candidates) {
        const data = {
          fields: {
            ...commonFields,
            Candidate: [candidate.id],
          },
        };

        const response = await axios.post(
          "/api/interviews/createInterview",
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(
          `Interview created for candidate ${candidate.id}:`,
          response.data
        );
      }

      await clearShortlist();
      setIsLoading(false);
      setShowSuccess(true);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      // Handle error
    }
  };

  const handleClose = () => {
    setShowSuccess(false);
  };

  return (
    <div>
      <h2
        className={`block text-4xl pt-8 font-normal leading-6 ${
          activeStep === "Message" ? "text-[#02B491]" : "text-gray-900"
        }`}
      >
        3. Interview Request
      </h2>
      {activeStep === "Message" ? (
        <div className="mb-10">
          <form onSubmit={handleSubmit}>
            <div className="space-y-12">
              <div className="border-gray-900/10">
                <div className="mt-6 sm:col-span-8 mt-4 flex space-x-8">
                  <div className="flex-1">
                    <div className="col-span-full">
                      <label
                        htmlFor="interviewer_name"
                        className="block text-md font-medium text-gray-900"
                      >
                        Who do you want the candidates to be introduced to?
                      </label>
                      <div className="mt-1">
                        <div className="">
                          <input
                            type="text"
                            name="interviewer_name"
                            id="interviewer_name"
                            autoComplete="interviewer_name"
                            className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                            placeholder="Sarah"
                            value={formData.interviewer_name}
                            onChange={handleChange}
                          ></input>
                        </div>
                        {formErrors.interviewer_name && (
                          <p className="text-red-600 text-md">
                            {formErrors.interviewer_name}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="col-span-full">
                      <label
                        htmlFor="job description"
                        className="block text-md font-medium text-gray-900"
                      >
                        What is their email address?{" "}
                      </label>
                      <div className="mt-1">
                        <div className="">
                          <input
                            type="text"
                            name="interviewer_email"
                            id="interviewer_email"
                            className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                            placeholder="john.smith@yourcompany.com"
                            value={formData.interviewer_email}
                            onChange={handleChange}
                          ></input>
                        </div>
                        {formErrors.interviewer_email && (
                          <p className="text-red-600 text-md">
                            {formErrors.interviewer_email}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 pt-2">
                  <div className="col-span-full max-w-[800px]">
                    <label
                      htmlFor="name"
                      className="block pt-4 text-md font-medium text-gray-900"
                    >
                      Is there anyone else you'd like to include in the thread
                      on CC (separate multiple emails with commas){" "}
                    </label>
                    <div className="mt-1">
                      <div className="">
                        <input
                          type="text"
                          name="other_emails"
                          id="other_emails"
                          autoComplete="other_emails"
                          className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          placeholder="first.email@yourcompany.com, second.email@yourcompany.com, etc..."
                          value={formData.other_emails}
                          onChange={handleChange}
                        ></input>
                      </div>
                      {formErrors.other_emails && (
                        <p className="text-red-600 text-md">
                          {formErrors.other_emails}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-span-full pt-6 max-w-[800px]">
                  <label
                    htmlFor="eye_catching_information"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Eye-catching information to be included in the outreach to
                    candidates
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="eye_catching_information"
                      name="eye_catching_information"
                      rows="2"
                      className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                      placeholder="We are currently growing at 30% month on month and need to hire exceptional people to help us scale"
                      value={formData.eye_catching_information}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="px-8 py-2 mt-4 rounded-md bg-[#02B491] text-white font-bold transition duration-200 hover:bg-white hover:text-black border-2 border-[#02B491]"
            >
              Save & submit to candidates
            </button>
          </form>
        </div>
      ) : (
        <div></div>
      )}
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <img src={LoadingBlack} className="h-8" />
        </div>
      )}
      {showSuccess && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
              onClick={handleClose}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="inline-block align-middle h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-4xl w-full">
              <div className="bg-white p-6">
                <div className="space-y-12">
                  <div className="border-gray-900/10 pb-4">
                    <h2 className="text-2xl font-semibold leading-7 text-gray-900">
                      We have just sent your request to the candidates.
                    </h2>
                    <p className="mt-4 text-md text-gray-600">
                      You should expect to hear back from them in the next
                      couple of days! In the meantime, you can find more
                      candidates or review your other shortlist.
                    </p>
                    <div className="mt-6 flex space-x-4">
                      <button
                        className="px-4 py-2 bg-[#02B491] hover:bg-white border-2 border-[#02B491] hover:text-black text-white rounded-md"
                        onClick={() => navigate("/browse-candidates")}
                      >
                        Browse Candidates
                      </button>
                      <button
                        className="px-4 py-2 hover:bg-[#02B491] hover:text-white border-2 border-[#02B491] rounded-md"
                        onClick={() => navigate("/shortlists2")}
                      >
                        Review Shortlists
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Message;
