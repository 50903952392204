import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

function Message({ clear, setSuccess, setIsInterviewRequest }) {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [selectedRole, setSelectedRole] = useState(
    JSON.parse(localStorage.getItem("selectedRole")) || null
  );
  const [selectedCandidates, setSelectedCandidates] = useState(
    JSON.parse(localStorage.getItem("candidates")) || null
  );
  const [formData, setFormData] = useState({
    interviewer_name: "",
    other_emails: "",
    interviewer_email: "",
    eye_catching_information: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const commonFields = {
      Role: [selectedRole.id],
      "Other emails": formData.other_emails,
      "Interviewer's Email": formData.interviewer_email,
      "Interviewer's Name": formData.interviewer_name,
      "Eye Catching Information": formData.eye_catching_information,
      Stage: "Requested",
    };

    try {
      for (let candidate of selectedCandidates) {
        const data = {
          fields: {
            ...commonFields,
            Candidate: [candidate.id],
          },
        };

        const response = await axios.post(
          "/api/interviews/createInterview",
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(
          `Interview created for candidate ${candidate.id}:`,
          response.data
        );
        clear();
        setIsInterviewRequest(false);

        setSuccess(true);
      }

      // Handle successful update for all candidates
    } catch (err) {
      console.error(err);
      // Handle error
    }
  };

  return (
    <div className="flex flex-col items-center bg-white pl-4 pr-4">
      <div className="max-w-3xl w-full flex flex-col h-full bg-white">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className=" border-gray-900/10 pb-12">
              <h2 className="text-3xl pt-5 font-normal leading-7 text-gray-900">
                Almost there! A few final questions:
              </h2>

              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="interviewer_name"
                    className="block text-sm font-medium text-gray-900"
                  >
                    What is the first name of the person you'd like to have
                    candidates introduced to?
                  </label>
                  <div className="mt-1">
                    <div className="">
                      <input
                        type="text"
                        name="interviewer_name"
                        id="interviewer_name"
                        autoComplete="interviewer_name"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Sarah"
                        value={formData.interviewer_name}
                        onChange={handleChange}
                      ></input>
                    </div>
                    {formErrors.interviewer_name && (
                      <p className="text-red-600 text-sm">
                        {formErrors.interviewer_name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label
                    htmlFor="job description"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    What is their email address?{" "}
                  </label>
                  <div className="mt-1">
                    <div className="">
                      <input
                        type="text"
                        name="interviewer_email"
                        id="interviewer_email"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="john.smith@yourcompany.com"
                        value={formData.interviewer_email}
                        onChange={handleChange}
                      ></input>
                    </div>
                    {formErrors.interviewer_email && (
                      <p className="text-red-600 text-sm">
                        {formErrors.interviewer_email}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Is there anyone else you'd like to include in the thread on
                    CC (please separate multiple emails with commas){" "}
                  </label>
                  <div className="mt-1">
                    <div className="">
                      <input
                        type="text"
                        name="other_emails"
                        id="other_emails"
                        autoComplete="other_emails"
                        className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="first.email@yourcompany.com, second.email@yourcompany.com, etc..."
                        value={formData.other_emails}
                        onChange={handleChange}
                      ></input>
                    </div>
                    {formErrors.other_emails && (
                      <p className="text-red-600 text-sm">
                        {formErrors.other_emails}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="eye_catching_information"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Eye-catching information to be included in the outreach to
                    candidates
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="eye_catching_information"
                      name="eye_catching_information"
                      rows="2"
                      className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="We are currently growing at 30% month on month and need to hire exceptional people to help us scale"
                      value={formData.eye_catching_information}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex items-center -mt-5 justify-end gap-x-6">
            <button
              type="submit"
              className="px-6 py-2 text-sm bg-black text-black rounded-lg font-medium transform hover:-translate-y-1 transition duration-400"
              style={{
                backgroundColor: "rgb(0, 242, 194)",
              }}
            >
              Save & submit to candidates
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Message;
