import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CandidateCard from "../components/candidates/generalist_card.js";

function TestSWE() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [roles, setRoles] = useState([]);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [recommendedCandidates, setRecommendedCandidates] = useState([]);
  const [isInterviewRequest, setIsInterviewRequest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newRoleTitle, setNewRoleTitle] = useState("");
  const [newRoleForm, setNewRoleForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Add search query state
  const [candidatesStatus, setCandidatesStatus] = useState([]);
  const [rolesNeedUpdate, setRolesNeedUpdate] = useState(true);
  const [candidate, setCandidate] = useState("");

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  const parseSalaryRange = (salary) => {
    const salaryRange = salary.match(/£(\d+)k?-?£?(\d+)?k?/i);
    if (salaryRange) {
      const minSalary = parseInt(salaryRange[1]) * 1000;
      const maxSalary = salaryRange[2]
        ? parseInt(salaryRange[2]) * 1000
        : minSalary;
      return { minSalary, maxSalary };
    }
    return { minSalary: null, maxSalary: null };
  };

  const fetchShortlistedCandidates = useCallback(async () => {
    if (!rolesNeedUpdate) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/roles/fetchRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const roles = response.data.roles;

      let shortlistedCandidatesByRole = {};
      let rolesList = [];

      roles.forEach((role) => {
        const roleTitle = role["Role Title"];
        const roleUrlId = role["URL ID"];
        const roleId = role["id"];
        rolesList.push(roleTitle);
        if (role["Shortlisted Candidates"]) {
          if (!shortlistedCandidatesByRole[roleTitle]) {
            shortlistedCandidatesByRole[roleTitle] = {
              roleUrlId: roleUrlId,
              roleId: roleId,
              candidates: [],
            };
          }
          shortlistedCandidatesByRole[roleTitle].candidates =
            shortlistedCandidatesByRole[roleTitle].candidates.concat(
              role["Shortlisted Candidates"]
            );
        }
      });

      setShortlistedCandidates(shortlistedCandidatesByRole);
      setRoles(roles);
      setRolesNeedUpdate(false);
    } catch (err) {
      setError(err.message);
    }
  }, [rolesNeedUpdate]);

  const fetchRecommendedCandidates = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/companies/fetchCompanyData", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const company = response.data.companyData;
      const recommendation = company["recommended_candidates"] || [];

      setRecommendedCandidates(recommendation);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  const fetchInterviews = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/interviews/fetchInterviews", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const interviews = response.data.interviews;
      const candidateStatusTuples = interviews.map((interview) => ({
        id: interview["Candidate"][0],
        status: interview["Stage"],
        role: interview["Role Title (from Role)"],
      }));

      setCandidatesStatus(candidateStatusTuples);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const id = "1901";
      const response = await axios.get(
        `/api/candidates/fetchCandidate?id=${id}`
      );

      const candidate = response.data.candidate;

      const mostRecentCohort = candidate.fields["Most recent cohort"];

      let sweRoles = candidate.fields["SWE relevant role"];
      if (sweRoles) {
        sweRoles = Array.isArray(sweRoles)
          ? sweRoles[0].split(",").map((role) => role.trim())
          : sweRoles.split(",").map((role) => role.trim());
        sweRoles = transformRoles(sweRoles);
      } else {
        sweRoles = []; // Ensure it's an empty array if not present
      }

      // Determine the correct salary field
      const stream = candidate.fields["Stream (from CRM)"][0];
      console.log(stream);
      let salary;
      if (stream === "Generalist" || stream === "Marketing") {
        salary = candidate.fields["Salary - cleaned"];
      } else if (stream === "Sales") {
        salary = candidate.fields["Base range + commission"];
      } else if (stream === "Software Engineering") {
        salary = candidate.fields["SWE Salary - cleaned"];
      }

      const transformedData = {
        id: candidate["id"] || "",
        url_id: candidate.fields["ID"] || "",
        createdTime: candidate["createdTime"], // Ensure this is correctly fetched
        years_of_exp:
          candidate.fields["Years of exp (or commercial exp)"] || "",
        cv: candidate.fields["Raw CV"] || "",
        video: candidate.fields["Raw video intro"] || "",
        linkedin: candidate.fields["LinkedIn raw URL"] || "",
        name: candidate.fields["Name"] || "",
        lastName: candidate.fields["Surname"] || "",
        education: candidate.fields["Degree details"] || undefined,
        roles: candidate.fields["Generalist roles suitable for"] || "",
        salary: salary, // Use the determined salary
        email: candidate.fields["Email"] || "",
        image: candidate.fields.Photo ? candidate.fields.Photo[0].url : null,
        work: candidate.fields["Raw work experience"] || "",
        working_style: candidate.fields["Preferred working style G/M/S"] || "",
        github: candidate.fields["Raw Github"] || "",
        swe_roles: sweRoles,
        languages: candidate.fields["Languages"] || "",
        coding_languages: candidate.fields["Coding languages"] || "",
        time_coding: candidate.fields["Time spent coding"] || "",
        description: candidate.fields["Top achievement"] || "",
        mostRecentCohort: candidate.fields["Most recent cohort"] || "",
        recentTarget: candidate.fields["Recent target achieved"] || "",
        stream: candidate.fields["Stream (from CRM)"][0], // Add the stream field
        right_to_work: candidate.fields["RTW doc"],
        most_impressive_swe: candidate.fields["Most impressive thing SWE"],
        sales:
          candidate.fields[
            "Proven Sales Experience / High Potential for Sales"
          ],
      };

      console.log(candidate);

      console.log(transformedData);

      setCandidate(transformedData);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRecommendedCandidates();
    fetchData();
    fetchInterviews();
  }, [fetchRecommendedCandidates, fetchData, fetchInterviews]);

  useEffect(() => {
    fetchShortlistedCandidates();
  }, [fetchShortlistedCandidates]);

  return (
    <div className="pt-20 pl-64 pr-2 bg-white min-h-screen h-full flex item-start">
      <div>
        <button
          type="button"
          className="mx-4 px-4 py-2  text-gray-800 rounded-md"
        >
          ← Back to all candidates
        </button>
      </div>
      <div>
        <div className="flex relative flex-col items-center bg-white rounded-xl p-4 mb-10 pb-10">
          <CandidateCard
            candidate={candidate}
            recommendedCandidates={recommendedCandidates}
            roles={roles}
            setRoles={setRoles}
            shortlistedCandidates={shortlistedCandidates}
            setRolesNeedUpdate={setRolesNeedUpdate}
            setNewRoleForm={setNewRoleForm}
            candidatesStatus={candidatesStatus}
          />
        </div>
      </div>
    </div>
  );
}

export default TestSWE;
