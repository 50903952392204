import React, { useState, useEffect, useCallback } from "react";
import CompanyDetails from "../../components/interview_request2/company_details";
import RoleDetails from "../../components/interview_request2/role_details";
import Message from "../../components/interview_request2/message";
import ShortlistRecap from "../../components/interview_request2/shortlist_recap";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function RequestInterview() {
  const navigate = useNavigate();
  const query = useQuery();
  const roleRequestId = query.get("roleRequestId");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [roles, setRoles] = useState([]);
  const [activeStep, setActiveStep] = useState("Company Information");
  const [companyNeedUpdate, setCompanyNeedUpdate] = useState(true);
  const [roleNeedUpdate, setRoleNeedUpdate] = useState(true);

  const checkToken = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  const fetchRole = useCallback(async () => {
    if (!roleNeedUpdate) return;
    try {
      const response = await axios.get(
        `/api/roles/fetchRole?id=${roleRequestId}`
      );
      const role = response.data.role;
      const transformedData = {
        id: role["id"],
        url_id: role.fields["URL ID"],
        equity: role.fields["Equity"],
        job_description_link: role.fields["Job Description PDF"],
        working_style: role.fields["Working Style"],
        hiring_lead_email: role.fields["Hiring Lead Email Address"],
        interview_process: role.fields["Interview Process"],
        hiring_lead_name: role.fields["Hiring Lead Name"],
        hiring_lead_linkedin: role.fields["Hiring Lead Linkedin"],
        image: role.fields["Logo (from Startup)"]
          ? role.fields["Logo (from Startup)"][0].url
          : null,
        spotlight: role.fields["Spotlight"],
        compensation: role.fields["Compensation"],
        startup_description: role.fields["Startup Description (from Startup)"],
        website: role.fields["Website (from Startup)"],
        team_size: role.fields["Team Size (from Startup)"],
        funding: role.fields["Funding Stage (from Startup)"],
        startup_name: role.fields["Name (from Startup)"][0],
        linkedin: role.fields["Startup Linkedin (from Startup)"],
        title: role.fields["Role Title"],
        key_experience: role.fields["Specific Experience"],
        additional_info: role.fields["Additional Information"],
        location: role.fields["Location (from Startup)"],
      };
      setRole(transformedData);

      if (role.fields["Shortlisted Candidates"]) {
        const candidateIds = role.fields["Shortlisted Candidates"];
        const roleName = role.fields["Role Title"];
        const roleId = role["id"];
        await fetchCandidates(candidateIds, roleName, roleId);
      } else {
        setLoading(false);
      }
      setRoleNeedUpdate(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [roleNeedUpdate]);

  const fetchCandidates = useCallback(
    async (candidateIds, roleName, roleId) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/candidates/fetchCandidates", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            ids: candidateIds.join(","),
          },
        });

        const candidates = response.data.candidates;
        const transformedData = candidates.map((candidate) => {
          const mostRecentCohort =
            candidate.fields?.["Most recent cohort"] || "";
          let stream = "";
          if (/^JS\d+$/.test(mostRecentCohort)) {
            stream = "Generalist";
          } else if (/^JSS\d+$/.test(mostRecentCohort)) {
            stream = "Sales";
          } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
            stream = "Software Engineering";
          } else if (/^JSM\d+$/.test(mostRecentCohort)) {
            stream = "Marketing";
          }
          return {
            id: candidate.id || "",
            url_id: candidate.fields?.["ID"] || "",
            name: candidate.fields?.["Name"] || "Unknown",
            education: Array.isArray(candidate.fields?.["Degree details"])
              ? candidate.fields["Degree details"]
              : [
                  candidate.fields?.["Degree details"] ||
                    "No details available",
                ],
            image: candidate.fields?.Photo
              ? candidate.fields.Photo[0]?.url || null
              : null,
            stream: stream,
            role: roleName,
            roleId: roleId,
          };
        });
        setCandidates(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    },
    []
  );

  const fetchCompanyData = useCallback(async () => {
    if (!companyNeedUpdate) return; // If company data doesn't need update, exit early
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/companies/fetchCompanyData", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const companyData = response.data.companyData;
      setCompany(companyData);
      setLoading(false);
      setCompanyNeedUpdate(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [companyNeedUpdate]);

  useEffect(() => {
    checkToken();
    fetchCompanyData();
    fetchRole();
  }, [checkToken, fetchCompanyData, fetchRole]);

  return (
    <div className="flex flex-col items-center bg-white pt-28 pl-4 pr-4 overflow-x-hidden">
      <div className="max-w-7xl w-full flex mb-20 h-full bg-white col-span  ">
        <div className="flex-initial w-2/3 divide-y-2 divide-gray-500">
          <CompanyDetails
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            company={company}
            setCompany={setCompany}
            setCompanyNeedUpdate={setCompanyNeedUpdate}
            loading={loading}
          />
          <RoleDetails
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            role={role}
            roles={roles}
            setRole={setRole}
            setRoleNeedUpdate={setRoleNeedUpdate}
            loading={loading}
          />
          <Message
            role={role}
            activeStep={activeStep}
            candidates={candidates}
          />
        </div>
        <div className="flex-initial pl-20 w-1/3">
          <ShortlistRecap candidates={candidates} loading={loading} />
        </div>
      </div>
    </div>
  );
}

export default RequestInterview;
