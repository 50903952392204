import React, { useEffect, useState, useRef } from "react";
import linkedin from "../../images/linkedin-logo.png";
import cv from "../../images/cv-icon.png";
import github from "../../images/github.png";
import jumpstart from "../../images/jumpstart_logo.jpeg";
import { differenceInDays } from "date-fns";
import save from "../../images/save-icon.png";
import LoadingWhite from "../../images/loading-white.svg";
import Office from "../../images/office-icon.png";
import Languages from "../../images/languages-icon.png";
import LoadingBlack from "../../images/loading-black.svg";
import axios from "axios";
import { useShortlisted } from "../../provider/ShortlistedContext";
import VideoIntro from "../../images/video-icon-green.png";
import TropheeIcon from "../../images/trophee-icon.png";
import { useNavigate } from "react-router-dom";
import { SlCopyButton } from "@shoelace-style/shoelace/dist/react";

function CandidateCard({
  candidate,
  recommendedCandidates,
  roles,
  shortlistedCandidates,
  setRoles,
  setRolesNeedUpdate,
  role,
  setNewRoleForm,
  handleSelectCandidate,
  candidatesStatus = [],
  addToast,
}) {
  const navigate = useNavigate();
  const { shortlistedCount, setShortlistedCount } = useShortlisted();
  const [showRoleMenu, setShowRoleMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const roleMenuRef = useRef();
  const [companyLink, setCompanyLink] = useState("");

  const handleRemoveCandidate = async (role, candidateId) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const updatedData = {
        fields: {
          "Shortlisted Candidates": shortlistedCandidates.filter(
            (c) => c !== candidateId
          ),
        },
      };
      await axios.patch(`/api/roles/updateRoleData/${role.id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRolesNeedUpdate(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getColorClasses = (stream) => {
    // Define color classes based on the candidate stream
    switch (stream) {
      case "Generalist":
        return {
          bgColor: "bg-emerald-100",
          textColor: "text-emerald-700",
          workBgColor: "bg-emerald-50",
          fillColor: "fill-emerald-700",
          bgColor2: "hover:bg-emerald-400",
          borderColor: "border-emerald-400",
        };
      case "Sales":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
          bgColor2: "hover:bg-blue-400",
          borderColor: "border-blue-400",
        };
      case "Software Engineering":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
          bgColor2: "hover:bg-orange-400",
          borderColor: "border-orange-400",
        };
      case "Marketing":
        return {
          bgColor: "bg-purple-100",
          textColor: "text-purple-700",
          workBgColor: "bg-purple-50",
          fillColor: "fill-purple-700",
          bgColor2: "hover:bg-purple-400",
          borderColor: "border-purple-400",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
          bgColor2: "hover:bg-gray-400",
          borderColor: "border-gray-400",
        };
    }
  };

  const formattedWork = candidate.work
    ? candidate.work
        .split(/(\d\)\s)/)
        .filter((part) => part.trim())
        .map((part, index) => {
          if (index % 2 === 1) {
            const [position, rest] = part.split("@");
            const [company, details] = rest ? rest.split(/-\s/, 2) : ["", ""];
            return (
              <div key={index} className="font-normal text-sm text-gray-500">
                <p className="text-black">
                  <span className="font-semibold">{position.trim()}</span> @{" "}
                  {company.trim()}
                </p>
                {details ? details.trim() : ""}
              </div>
            );
          }
          return (
            <p key={index} className="mb-2 font-normal text-sm text-gray-400 ">
              {part.replace(/\d\)\s/, "")}
            </p>
          );
        })
    : null;

  const { bgColor, textColor, workBgColor, fillColor, bgColor2, borderColor } =
    getColorClasses(candidate.stream);

  const fallbackImageUrl =
    "https://cdn-icons-png.flaticon.com/512/4120/4120345.png";

  return (
    <div className="relative max-w-lg mx-auto mt-5 pt-4 pr-4 pl-4 bg-white rounded-lg overflow-hidden flex flex-col h-full hover:shadow-[0_3px_10px_rgb(0,0,0,0.2)] transition duration-400">
      <a
        href={`candidate/${candidate.url_id}`}
        onClick={(e) => {
          e.preventDefault();
          // Store the current page before opening the candidate details in a new tab
          sessionStorage.setItem("lastVisitedPage", window.location.pathname);

          // Open the candidate page in a new tab
          window.open(`candidate/${candidate.url_id}`, "_blank");
        }}
      >
        <div className="relative">
          <img
            className="w-full h-72 object-cover rounded-lg"
            src={candidate.image || fallbackImageUrl}
            alt="Candidate"
            onError={(e) => {
              e.target.src = fallbackImageUrl;
            }}
          />

          <span
            className={`absolute top-2 right-2 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
          >
            <svg
              className={`h-1.5 w-1.5 ${fillColor}`}
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            {candidate.stream}
          </span>
        </div>
      </a>

      {/* Overlay if candidate is not featured */}
      {candidate.featured !== "Featured" && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 flex flex-col justify-center items-center">
          <div className="bg-white p-3 flex rounded-md w-4/5 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              class="size-6 font-bold"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m3 3 1.664 1.664M21 21l-1.5-1.5m-5.485-1.242L12 17.25 4.5 21V8.742m.164-4.078a2.15 2.15 0 0 1 1.743-1.342 48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185V19.5M4.664 4.664 19.5 19.5"
              />
            </svg>

            <p className="text-black font-bold">
              Sorry, this candidate is no longer available.
            </p>
          </div>
          <button
            onClick={() => handleRemoveCandidate(role, candidate.id)}
            className="px-8 py-2 w-4/5 mt-4 rounded-md bg-white text-black font-bold transition duration-200 hover:bg-gray-400 hover:text-white border-2 border-gray-400"
          >
            Remove
          </button>
        </div>
      )}

      <div className="pt-2 flex flex-col flex-grow justify-between">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <h5 className="text-xl font-semibold tracking-tight text-gray-900">
              {candidate.name}
            </h5>
            <img
              src={linkedin}
              alt={candidate.name}
              title="Linkedin icon"
              className="h-5 w-5 ml-1 rounded-sm"
              onClick={(e) => {
                e.preventDefault();
                let url = candidate.linkedin;
                if (!url.startsWith("https://")) {
                  url = "https://" + url;
                }
                window.open(url, "_blank");
              }}
              style={{ cursor: "pointer" }}
            />
            {candidate.cv && (
              <img
                src={cv}
                alt={candidate.cv}
                title="CV icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.cv, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {candidate.github && (
              <img
                src={github}
                alt={candidate.github}
                title="Github icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.github, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
          <div className="flex flex-col text-right">
            <h5 className="text-sm tracking-tight text-gray-900 ">
              Expected Salary Range: {candidate.expectedSalaryRange}
            </h5>
            <h5 className="text-sm tracking-tight text-gray-900 ">
              {candidate.salary}
            </h5>
          </div>
        </div>
        <div className="">
          {candidate.roles &&
            typeof candidate.roles === "string" &&
            candidate.roles.split(",").map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {role.trim()}
              </span>
            ))}
          {candidate.sales && (
            <span
              className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.sales}
            </span>
          )}
          {candidate.swe_roles &&
            candidate.swe_roles.map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {role.trim()}
              </span>
            ))}
          {candidate.time_coding && (
            <span
              className={`inline-flex mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.time_coding} of coding exp
            </span>
          )}
        </div>

        <div className="flex pb-2 pt-1">
          {candidate.working_style && (
            <p className=" pr-4 font-normal flex text-sm text-black ">
              <img className="h-6 pr-1 pb-1" src={Office} />
              {candidate.working_style}
            </p>
          )}
          {candidate.languages !== "N/A" && candidate.languages && (
            <p className="font-normal flex text-sm text-black ">
              <img className="h-5  pr-1" src={Languages} />
              {candidate.languages}
            </p>
          )}
        </div>

        <p className="text-sm">
          {candidate.education &&
            candidate.education.length > 0 &&
            (() => {
              const educationText = candidate.education;
              if (educationText.includes("; ")) {
                const splitText = educationText.split("; ")[1];
                const [beforeAt, afterAt] = splitText.split(" @ ");
                const [firstPart, ...restParts] = beforeAt.split("&");
                return (
                  <>
                    <span className="font-semibold">{firstPart.trim()}</span>
                    {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                    {afterAt ? ` @ ${afterAt}` : ""}
                  </>
                );
              }
              const [beforeAt, afterAt] = educationText.split(" @ ");
              const [firstPart, ...restParts] = beforeAt.split("&");
              return (
                <>
                  <span className="font-semibold">{firstPart.trim()}</span>
                  {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                  {afterAt ? ` @ ${afterAt}` : ""}
                </>
              );
            })()}
        </p>

        <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
          {candidate.recentTarget && (
            <div className="font-normal text-sm text-gray-700 pt-2">
              <span className="font-semibold text-black">
                Recent Target Achieved:
              </span>{" "}
              {candidate.recentTarget}
            </div>
          )}

          {formattedWork}
        </div>

        {candidate.description && (
          <div className="mt-2 flex">
            <img src={TropheeIcon} className="h-5 w-5 "></img>

            <p className="font-normal pl-1 text-sm text-gray-700">
              {candidate.description}
            </p>
          </div>
        )}
      </div>

      <button
        onClick={() => handleRemoveCandidate(role, candidate.id)}
        className="px-8 py-2 mt-4 mb-4 rounded-md bg-white text-black font-bold transition duration-200 hover:bg-gray-400 hover:text-white border-2 border-gray-400"
      >
        Remove
      </button>
    </div>
  );
}

export default CandidateCard;
