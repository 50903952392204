import linkedin from "../../images/linkedin-logo.png";
import React, { useState, useEffect } from "react";
import { useAsyncError, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path
import cv from "../../images/cv-icon.png";
import github from "../../images/github.png";
import Office from "../../images/office-icon.png"; // Ensure you have the loading.svg in the correct path
import Languages from "../../images/languages-icon.png"; // Ensure you have the loading.svg in the correct path
import "flowbite";

function ApplicationSneakPeek({ candidate }) {
  const navigate = useNavigate();

  const formattedWork = candidate.work
    ? candidate.work
        .split(/(\d\)\s)/)
        .filter((part) => part.trim())
        .map((part, index) => {
          if (index % 2 === 1) {
            const [position, rest] = part.split("@");
            const [company, details] = rest ? rest.split(/-\s/, 2) : ["", ""];
            return (
              <div key={index} className="font-normal text-sm text-gray-500 ">
                <p className="text-black">
                  <span className="font-semibold">{position.trim()}</span> @{" "}
                  {company.trim()}
                </p>
                {details ? details.trim() : ""}
              </div>
            );
          }
          return (
            <p key={index} className="mb-2 font-normal text-sm text-gray-400">
              {part.replace(/\d\)\s/, "")}
            </p>
          );
        })
    : null;

  // Define the color classes based on the stage
  const getColorClasses = (stage) => {
    switch (stage) {
      case "Match requested":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
        };
      case "Application pending":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
        };
      case "Being interviewed":
        return {
          bgColor: "bg-green-100",
          textColor: "text-green-700",
          workBgColor: "bg-green-50",
          fillColor: "fill-green-700",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
        };
    }
  };

  const { bgColor, textColor, workBgColor, fillColor } = getColorClasses(
    candidate.stage
  );

  const [loading, setLoading] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const previewText =
    candidate.rationale?.split(" ").slice(0, 10).join(" ") + "...";

  const fallbackImageUrl =
    "https://cdn-icons-png.flaticon.com/512/4120/4120345.png";

  return (
    <div className="relative w-[450px] mx-auto  p-4 bg-white rounded-lg  overflow-hidden flex flex-col h-full hover:drop-shadow-lg transition duration-400">
      <a>
        <div className="relative">
          <img
            className="w-full h-72 object-cover rounded-lg"
            src={candidate.image || fallbackImageUrl}
            alt="Candidate"
            onError={(e) => {
              e.target.src = fallbackImageUrl;
            }}
          />

          <span
            className={`absolute top-2 right-2 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
          >
            {candidate.stage === "Application pending" ? (
              <>
                Applied to <strong>{candidate.role_title}</strong>
              </>
            ) : (
              candidate.stage
            )}
          </span>
        </div>
      </a>
      <div className="pt-2 flex flex-col flex-grow">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <h5 className="text-xl font-semibold tracking-tight text-gray-900">
              {candidate.name}
            </h5>
          </div>
        </div>

        <p className="text-sm">
          {candidate.education &&
            candidate.education.length > 0 &&
            (() => {
              const educationText = candidate.education;
              if (educationText.includes("; ")) {
                const splitText = educationText.split("; ")[1];
                const [beforeAt, afterAt] = splitText.split(" @ ");
                const [firstPart, ...restParts] = beforeAt.split("&");
                return (
                  <>
                    <span className="font-semibold">{firstPart.trim()}</span>
                    {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                    {afterAt ? ` @ ${afterAt}` : ""}
                  </>
                );
              }
              const [beforeAt, afterAt] = educationText.split(" @ ");
              const [firstPart, ...restParts] = beforeAt.split("&");
              return (
                <>
                  <span className="font-semibold">{firstPart.trim()}</span>
                  {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                  {afterAt ? ` @ ${afterAt}` : ""}
                </>
              );
            })()}
        </p>

        <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
          {candidate.recentTarget && (
            <div className="font-normal text-sm text-gray-700 pt-2">
              <span className="font-semibold text-black">
                Recent Target Achieved:
              </span>{" "}
              {candidate.recentTarget}
            </div>
          )}
          {formattedWork}
        </div>

        {candidate.description && (
          <p className="mt-2 font-normal text-sm text-gray-700">
            <span className="font-semibold text-black">Most proud of: </span>
            {candidate.description}
          </p>
        )}

        {candidate.rationale && (
          <div className={`pb-2 mt-2 px-2 mb-4 rounded-md ${workBgColor}`}>
            <div className="font-normal text-sm text-gray-700 pt-2">
              <span className="font-semibold text-black">
                Application Rationale:
              </span>{" "}
              {isExpanded ? candidate.rationale : previewText}
              <button
                onClick={handleToggle}
                className="text-blue-500 ml-2 underline"
              >
                {isExpanded ? "Show Less" : "Read More"}
              </button>
            </div>
          </div>
        )}
      </div>

      <button
        onClick={() => navigate("/applications")}
        className="px-8 py-2 my-4 rounded-md bg-[#02B491] text-white font-normal transition duration-200 hover:bg-white hover:text-black border-2 border-[#02B491]"
      >
        View all applications
      </button>
    </div>
  );
}

export default ApplicationSneakPeek;
