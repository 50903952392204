import React, { useState } from "react";
import axios from "axios";

const ScrapeRequestComponent = () => {
  const [url, setUrl] = useState("");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState(null); // New state for debugging

  const handleSubmit = async () => {
    if (!url) {
      setError("Please enter a valid URL");
      return;
    }

    setLoading(true);
    setError(null);
    setDebugInfo(null);

    try {
      const result = await axios.post("/api/roles/quickCreateRole", { url });

      // Get full result data and extract only the relevant fields
      const data = result.data.result[0]; // Assuming the backend sends an array and we're only interested in the first item

      // Extract only the necessary fields (company name, image, description, location)
      const company_name = data.company_name;
      const company_location =
        data.headquarters ||
        (data.locations && data.locations[0]?.office_address_line_1);
      const company_description = data.about || data.tagline;
      const company_image = data.profile_photo;

      // Set only the fields you want to display
      setResponse({
        company_name,
        company_location,
        company_description,
        company_image,
      });

      if (result.data.debugLog) {
        setDebugInfo(result.data.debugLog);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.message || "An error occurred");
        setDebugInfo({
          errorMessage: err.response.data.errorMessage,
          errorStack: err.response.data.errorStack,
          errorData: err.response.data.errorData,
        });
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full">
        <h1 className="text-xl font-bold mb-4">URL Analyzer</h1>

        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="border border-gray-300 p-2 rounded-md w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter a URL"
        />

        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white font-semibold px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
          disabled={loading}
        >
          {loading ? "Analyzing..." : "Send"}
        </button>

        {error && <p className="text-red-500 mt-4">{error}</p>}

        {response && (
          <div className="mt-6 bg-gray-50 p-4 rounded-lg shadow-inner">
            <h2 className="text-lg font-semibold mb-2">Extracted Data:</h2>
            <div className="text-sm whitespace-pre-wrap">
              <p>
                <strong>Company Name:</strong> {response.company_name}
              </p>
              <p>
                <strong>Company Location:</strong>{" "}
                {response.company_location || "Location not available"}
              </p>
              <p>
                <strong>Company Description:</strong>{" "}
                {response.company_description || "Description not available"}
              </p>
              {response.company_image && (
                <img
                  src={response.company_image}
                  alt={response.company_name}
                  className="w-24 h-24 mt-4"
                />
              )}
            </div>
          </div>
        )}

        {debugInfo && (
          <div className="mt-6 bg-gray-50 p-4 rounded-lg shadow-inner">
            <h2 className="text-lg font-semibold mb-2">Debug Info:</h2>
            <pre className="text-sm whitespace-pre-wrap">
              {JSON.stringify(debugInfo, null, 2)}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScrapeRequestComponent;
