import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

// Fetch roles associated with the logged-in company

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function RoleCreated() {
  const query = useQuery();
  const roleId = query.get("roleId");
  const navigate = useNavigate();
  const [role, setRole] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoleById", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            id: roleId, // Pass the roleId in the request
          },
        });

        const role = response.data.role;

        console.log("fields:");
        console.log(role.fields);
        const transformedData = {
          id: role["id"] || "",
          job_description_pdf: role.fields["Job Description PDF"] ?? "",
          working_style: role.fields["Working Style"] ?? "",
          hiring_lead_email: role.fields["Hiring Lead Email Address"] ?? "",
          interview_process: role.fields["Interview Process"] ?? "",
          hiring_lead_name: role.fields["Hiring Lead Name"] ?? "",
          image:
            role.fields["Role Cover Image (from Startup)"]?.[0]?.url ??
            "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg",
          spotlight: role.fields["URL Cleaned (from Spotlight)"] ?? "",
          compensation: role.fields["Compensation"] ?? "",
          startup_description:
            role.fields["Startup Description (from Startup)"] ?? "",
          website: role.fields["Website (from Startup)"] ?? "",
          team_size: role.fields["Team Size (from Startup)"] ?? "",
          funding: role.fields["Funding Stage (from Startup)"] ?? "",
          startup_name: role.fields["Name (from Startup)"]?.[0] ?? "",
          linkedin: role.fields["Startup Linkedin (from Startup)"] ?? "",
          title: role.fields["Role Title"] ?? "",
          key_experience: role.fields["Specific Experience"] ?? "",
          additional_info: role.fields["Additional Information"] ?? "",
          location: role.fields["Location (from Startup)"] ?? "",
          dropbox_url:
            role.fields["URL Dropbox cleaned (from Spotlight)"]?.[0] ?? null,
          loom_url:
            role.fields["URL Loom cleaned (from Spotlight)"]?.[0] ?? null,
          hiring_lead_linkedin: role.fields["Hiring Lead Linkedin"] ?? "",
          spotlight_context: role.fields["Spotlight Context"] ?? "",
        };

        console.log("ok");

        setRole(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [roleId]);

  const [animationClass, setAnimationClass] = useState("animate-fadeInUp");

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-white">
        <h2 className={`text-xl pb-2 font-bold`}>Creating your role</h2>
        <div className="w-[200px] mb-40 h-1 rounded-md bg-gray-300 overflow-hidden relative">
          <div className="h-full w-1/2 rounded-md bg-black animate-slide-bar"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const posterUrl =
    "https://v5.airtableusercontent.com/v3/u/29/29/1718128800000/1G1v0uweBAfNoNapHIMEXw/z2JvwdFN0Wf-kzeAIYrtuhfbdneEygRnctEsC-4uZx1R2BjNZCHscyaUJAwdpQ08G2YrdQaqmLB4JRY-eFLKI2JVNkAW1LHfKQ70VJ7C2_TnWibCUxCzXhV4hDKSGifde4bdHj54wrq-M1CpA6bA8hUXO_IJ7bcjoehehDO9kxs/OL2VanPp-TP6F3VoXrpFddV2v0dtEbYhFSoWSMTPReo";

  return (
    <div className="flex bg-gray-50 items-center justify-center">
      <div className="fixed z-50 top-6 left-6 flex items-center mb-6">
        <div className="flex items-center mb-6">
          <img
            src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
            className="h-6 my-2 px-4 sm:block hidden"
            alt="Jumpstart Logo"
          />
        </div>
      </div>
      <div className="flex items-center max-w-[1000px] w-full justify-between h-screen">
        <h2
          className={`text-6xl max-w-[500px] mr-8 mb-20 text-left pb-8 font-medium ${animationClass}`}
        >
          Your role has been created
        </h2>

        <div className="flex flex-col space-y-2 mb-20">
          <div className="flex flex-col items-center bg-white  shadow-md rounded-xl ">
            <div className="m-4 max-w-[500px] max-h-[700px] overflow-y-scroll flex flex-col h-full bg-white gap-2">
              <div>
                {role.dropbox_url ? (
                  <video
                    src={role.dropbox_url}
                    className="h-[300px] w-[500px] object-cover rounded-xl bg-[var(--overlay-2)]"
                    controls
                    poster={role.image || posterUrl}
                    onError={(e) => {
                      console.error("Failed to load video at", e.target.src);
                    }}
                  />
                ) : role.loom_url ? (
                  <iframe
                    src={role.loom_url}
                    className="h-[300px] w-[500px] object-cover rounded-xl bg-[var(--overlay-2)]"
                  ></iframe>
                ) : (
                  <img
                    src={role.image}
                    className="object-cover h-[300px] w-[500px] object-cover rounded-xl"
                  />
                )}
              </div>

              {role.spotlight_context && (
                <>
                  <p className="-mt-1 font-normal italic text-md text-gray-500">
                    {role.spotlight_context}
                  </p>
                </>
              )}

              <div className="font-normal -mt-1 text-lg text-gray-500">
                <p className="text-black">
                  <span className="text-lg font-semibold">
                    {role.startup_name}
                  </span>{" "}
                  - {role.title}
                </p>
              </div>
              <div className="">
                {role.compensation && (
                  <span className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md bg-purple-100 px-4 py-1 text-sm md:text-sm font-medium text-purple-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                      />
                    </svg>
                    Salary range: {role.compensation}
                  </span>
                )}
                {role.team_size && (
                  <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-green-100 px-4 py-1 text-sm font-medium text-green-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                      />
                    </svg>
                    {role.team_size} people in the team
                  </span>
                )}

                {role.funding && (
                  <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-red-100 px-4 py-1 text-sm font-medium text-red-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
                      />
                    </svg>

                    {role.funding}
                  </span>
                )}

                {role.location && (
                  <span className="inline-flex mr-2 items-center gap-x-1 rounded-md bg-blue-100 px-4 py-1 text-sm font-medium text-blue-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      class="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                      />
                    </svg>
                    {role.location}
                  </span>
                )}
              </div>

              {role.startup_description && (
                <>
                  <h2 className=" font-semibold text-sm text-black">
                    Company Description
                  </h2>
                  <p className="-mt-2 font-normal text-sm text-gray-500">
                    {role.startup_description}
                  </p>
                </>
              )}

              {role.website && (
                <>
                  <h2 className=" font-semibold text-sm text-black">
                    Company Website
                  </h2>
                  <a
                    href={role.website}
                    className="-mt-2 font-normal text-sm text-blue-500 underline"
                  >
                    {role.website}
                  </a>
                </>
              )}

              {role.job_description_pdf && (
                <>
                  <h2 className="  font-semibold text-sm text-black ">
                    Job Description
                  </h2>
                  <a
                    href={role.job_description_pdf}
                    className="-mt-2 font-normal text-sm text-blue-500 underline "
                  >
                    {role.job_description_pdf}
                  </a>
                </>
              )}

              {role.key_experience && (
                <>
                  <h2 className="  font-semibold text-sm text-black">
                    Specific Experience
                  </h2>
                  <p className="-mt-2 font-normal text-sm text-gray-500 ">
                    {role.key_experience}
                  </p>
                </>
              )}

              {role.interview_process && (
                <>
                  <h2 className="  font-semibold text-sm text-black">
                    Interview Process
                  </h2>
                  <p className="-mt-2 font-normal text-sm text-gray-500">
                    {role.interview_process}
                  </p>
                </>
              )}

              {role.working_style && (
                <>
                  <h2 className=" font-semibold text-sm text-black">
                    Team Working Style
                  </h2>
                  <p className="-mt-2 font-normal text-sm text-gray-500 ">
                    {role.working_style}
                  </p>
                </>
              )}

              {role.additional_info && (
                <>
                  <h2 className="  font-semibold text-sm text-black">
                    Additional Info
                  </h2>
                  <p className="-mt-2 font-normal text-sm text-gray-500">
                    {role.additional_info}
                  </p>
                </>
              )}
              {role.hiring_lead_name && (
                <>
                  <h2 className=" font-semibold text-sm text-black">
                    Hiring Lead
                  </h2>
                  <p className="-mt-2 font-normal text-sm text-gray-500">
                    {role.hiring_lead_linkedin ? (
                      <a
                        href={role.hiring_lead_linkedin}
                        className="text-blue-500 hover:underline cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {role.hiring_lead_name}
                      </a>
                    ) : (
                      <span>{role.hiring_lead_name}</span>
                    )}
                    {role.hiring_lead_email && <> - {role.hiring_lead_email}</>}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Continue button, only enabled if an option is selected */}
        <button
          onClick={(e) => {
            e.preventDefault();
            navigate("/roles");
          }}
          className="fixed bg-gray-50 flex bottom-10 items-center justify-center right-80 text-xl w-[250px] font-normal py-2 px-4 hover:bg-[#02B491] text-black hover:text-white border-2 border-[#02B491] rounded-md transition"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
            />
          </svg>
          View roles
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            navigate("/browse-candidates");
          }}
          className="fixed flex items-center justify-center bottom-10 right-16 text-xl w-[250px] font-normal py-2 px-4 bg-[#02B491] text-white border-2 border-[#02B491] hover:text-black hover:bg-white rounded-md transition"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
            />
          </svg>
          Browse candidates
        </button>
      </div>
    </div>
  );
}

export default RoleCreated;
