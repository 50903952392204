import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function InterviewRequest() {
  const query = useQuery();
  const interviewRequestId = query.get("interviewRequestId");
  const securityCode = query.get("securityCode");

  const navigate = useNavigate();

  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [interview, setInterview] = useState(null);

  useEffect(() => {
    const fetchInterview = async () => {
      try {
        const response = await axios.get(
          `/api/interviews/fetchInterview?id=${interviewRequestId}`
        );

        const interview = response.data.interview;

        const transformedInterview = {
          company: interview.fields["Name (from Startup) (from Role)"],
        };

        setInterview(transformedInterview);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchInterview();
  }, [interviewRequestId]);

  const handleSubmit = async (stage) => {
    const updatedData = {
      fields: {
        Stage: stage,
      },
      interviewId: interviewRequestId,
    };
    setError(""); // Reset error message
    try {
      const response = await axios.patch(
        `/api/interviews/updateInterviewRequest`,
        updatedData
      );

      console.log(response);

      setActive(false); // Redirect to /candidate page
    } catch (err) {
      setError(err.message); // Set error message
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          {active ? (
            <>
              <h1 className="text-xl font-normal leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                You have been invited for an interview with{" "}
                <span
                  className="font-bold"
                  style={{
                    color: "rgb(0, 185, 140)",
                  }}
                >
                  {interview.company}
                </span>{" "}
                via Jumpstart!
              </h1>
              <div className="space-y-4 md:space-y-6">
                <button
                  onClick={() => handleSubmit("Interviewing")}
                  style={{
                    backgroundColor: "rgb(0, 242, 194)",
                  }}
                  className="w-full text-black font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Accept the interview request
                </button>
                <button
                  onClick={() => handleSubmit("Rejected Interview Request")}
                  className="w-full text-white bg-red-500 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Reject the interview request
                </button>
              </div>
              <h1>
                Please contact rachel@jumpstart-uk.com if you have any further
                questions!
              </h1>
            </>
          ) : (
            <>
              <h1 className="text-xl font-semibold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Thank you for responding to this request.
              </h1>
              <span className="font-normal text-lg">
                We've let {interview.company} know about your response!
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default InterviewRequest;
