import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useAsyncError, useNavigate } from "react-router-dom";
import CandidateCard from "../../components/candidates/generalist_card.js";
import Candidate from "../../components/candidates/candidate.js"; // Import the Candidate component
import InterviewRequest from "../../components/interview_request/progress_bar.js";
import MultiSelect from "../../components/multiselect_filter.js";
import ActiveFilters from "../../components/active_filters.js";
import CandidatesLoading from "../../components/candidates/candidates_loading.js";
import CandidatesPreviewCard from "../../components/candidates/candidates_preview_card.js";

export default function CandidatesPreview() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterStream, setFilterStream] = useState([]);
  const [filterYearsOfExp, setFilterYearsOfExp] = useState([]);
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [filterWorkingStyle, setFilterWorkingStyle] = useState([]);
  const [filterIndustry, setFilterIndustry] = useState([]);
  const [roles, setRoles] = useState([]);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [recommendedCandidates, setRecommendedCandidates] = useState([]);
  const [isInterviewRequest, setIsInterviewRequest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newRoleForm, setNewRoleForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Add search query state
  const [candidatesStatus, setCandidatesStatus] = useState([]);
  const [rolesNeedUpdate, setRolesNeedUpdate] = useState(true);
  const [toasts, setToasts] = useState([]);

  const [savedCandidates, setSavedCandidates] = useState(() => {
    const savedCandidates = localStorage.getItem("candidates");
    return savedCandidates ? JSON.parse(savedCandidates) : [];
  });

  const streams = ["Generalist", "Sales", "Software Engineer", "Marketing"];
  const year_of_exp = ["0-1", "1-2", "2-3", "3-4", "4+"];
  const working_style = ["Fully Remote", "Fully in person", "Hybrid"];

  const industry_exp = [
    "Banking",
    "Consulting",
    "Startup experience",
    "Founder experience",
  ];

  const saveFiltersToLocalStorage = () => {
    const filters = {
      filterStream,
      filterYearsOfExp,
      minSalary,
      maxSalary,
      filterWorkingStyle,
      filterIndustry,
      timestamp: Date.now(),
    };
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const loadFiltersFromLocalStorage = () => {
    const filters = JSON.parse(localStorage.getItem("filters"));
    if (filters) {
      const currentTime = Date.now();
      const twoHours = 2 * 60 * 60 * 1000;

      if (currentTime - filters.timestamp < twoHours) {
        setFilterStream(filters.filterStream);
        setFilterYearsOfExp(filters.filterYearsOfExp);
        setMinSalary(filters.minSalary);
        setMaxSalary(filters.maxSalary);
        setFilterWorkingStyle(filters.filterWorkingStyle);
        setFilterIndustry(filters.filterIndustry);
      } else {
        localStorage.removeItem("filters");
      }
    }
  };

  useEffect(() => {
    loadFiltersFromLocalStorage();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  const parseSalaryRange = (salary) => {
    const salaryRange = salary.match(/£(\d+)k?-?£?(\d+)?k?/i);
    if (salaryRange) {
      const minSalary = parseInt(salaryRange[1]) * 1000;
      const maxSalary = salaryRange[2]
        ? parseInt(salaryRange[2]) * 1000
        : minSalary;
      return { minSalary, maxSalary };
    }
    return { minSalary: null, maxSalary: null };
  };

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/candidates/fetchCandidates", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const candidates = response.data.candidates;

      const transformedData = candidates.map((candidate) => {
        const fields = candidate.fields || {};
        const mostRecentCohort = fields["Most recent cohort"] || "";
        let stream = "";

        if (/^JS\d+$/.test(mostRecentCohort)) {
          stream = "Generalist";
        } else if (/^JSS\d+$/.test(mostRecentCohort)) {
          stream = "Sales";
        } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
          stream = "Software Engineering";
        } else if (/^JSM\d+$/.test(mostRecentCohort)) {
          stream = "Marketing";
        }

        let sweRoles = fields["SWE relevant roles"] || "";
        if (sweRoles) {
          sweRoles = Array.isArray(sweRoles)
            ? sweRoles[0].split(",").map((role) => role.trim())
            : sweRoles.split(",").map((role) => role.trim());
          sweRoles = transformRoles(sweRoles);
        } else {
          sweRoles = [];
        }

        let salary;
        if (stream === "Generalist" || stream === "Marketing") {
          salary = fields["Salary - cleaned"] || "";
        } else if (stream === "Sales") {
          salary = fields["Base range + commission"] || "";
        } else if (stream === "Software Engineering") {
          salary = fields["SWE Salary - cleaned"] || "";
        }

        const { minSalary, maxSalary } = parseSalaryRange(salary);

        return {
          id: candidate["id"] || "",
          url_id: fields["ID"] || "",
          minimum_salary: minSalary || 0,
          maximum_salary: maxSalary || 0,
          createdTime: candidate["createdTime"] || "",
          years_of_exp: fields["Years of exp (or commercial exp)"] || 0,
          cv: fields["Raw CV"] || "",
          video: fields["Raw video intro"] || "",
          linkedin: fields["LinkedIn raw URL"] || "",
          industry_exp: fields["Industry experience"] || undefined,
          name: fields["Name"] || "",
          lastName: fields["Surname"] || "",
          education: fields["Degree details"] || undefined,
          roles: fields["Generalist roles suitable for"] || "",
          salary: salary || "",
          email: fields["Email"] || "",
          image: fields.Photo ? fields.Photo[0].url : null,
          work: fields["Raw work experience"] || "",
          working_style: fields["Preferred working style G/M/S"] || "",
          github: fields["Github"] || "",
          swe_roles: sweRoles,
          languages: fields["Languages"] || "",
          time_coding: fields["Time spent coding"] || "",
          description: fields["Top achievement"] || "",
          mostRecentCohort: fields["Most recent cohort"] || "",
          recentTarget: fields["Recent target achieved"] || "",
          stream: stream,
          search_fields: fields["Search Aggregate Fields"] || "", // Ensure this field is correctly populated
          sales:
            fields["Proven Sales Experience / High Potential for Sales"] || "",
        };
      });

      setCandidates(transformedData);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const redStatuses = [
      "Rejected @ CV",
      "Rejected @ Interview",
      "Rejected Interview Request",
      "Company withdrawn",
      "Candidate withdrawn",
    ];

    const getStatusPriority = (status) => {
      if (status === "Application pending") return 1;
      if (status === "Interviewing") return 3;
      if (status === "Match requested") return 4;
      if (redStatuses.includes(status)) return 5;
      return 2; // No status
    };

    const allCandidatesWithStatus = candidates.map((candidate) => {
      const candidateStatus = candidatesStatus.find(
        (status) => status.id === candidate.id
      );
      return {
        ...candidate,
        status: candidateStatus ? candidateStatus.status : null,
        isRecommended: recommendedCandidates.includes(candidate.id),
      };
    });

    const sortedCandidates = allCandidatesWithStatus.sort((a, b) => {
      const aPriority = getStatusPriority(a.status);
      const bPriority = getStatusPriority(b.status);

      if (aPriority !== bPriority) return aPriority - bPriority;

      // When priorities are equal, sort recommended candidates first
      if (a.isRecommended && !b.isRecommended) return -1;
      if (!a.isRecommended && b.isRecommended) return 1;

      return new Date(b.createdTime) - new Date(a.createdTime);
    });

    setData(sortedCandidates);
  }, [candidates, candidatesStatus, recommendedCandidates]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    localStorage.setItem("candidates", JSON.stringify(savedCandidates));
  }, [savedCandidates]);

  useEffect(() => {
    saveFiltersToLocalStorage();
  }, [
    filterStream,
    filterYearsOfExp,
    minSalary,
    maxSalary,
    filterWorkingStyle,
    filterIndustry,
  ]);

  const addCandidate = (candidate) => {
    if (!savedCandidates.some((c) => c.id === candidate.id)) {
      setSavedCandidates([...savedCandidates, candidate]);
    }
  };

  const removeCandidate = (candidate) => {
    setSavedCandidates(savedCandidates.filter((c) => c.id !== candidate.id));
  };

  const clear = () => {
    setSavedCandidates([]);
  };

  const toggleCandidate = (candidate) => {
    if (savedCandidates.some((c) => c.id === candidate.id)) {
      removeCandidate(candidate);
    } else {
      addCandidate(candidate);
    }
  };

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleStreamFilterChange = (selectedOptions) => {
    setFilterStream(selectedOptions);
  };

  const handleStreamChange = (option) => {
    if (filterStream.includes(option)) {
      handleStreamFilterChange(filterStream.filter((item) => item !== option));
    } else {
      handleStreamFilterChange([...filterStream, option]);
    }
  };

  const handleYearsOfExpFilterChange = (selectedOptions) => {
    setFilterYearsOfExp(selectedOptions);
  };

  const handleWorkingStyleFilterChange = (selectedOptions) => {
    setFilterWorkingStyle(selectedOptions);
  };

  const handleMaxSalaryChange = (e) => {
    setMaxSalary(e.target.value ? parseInt(e.target.value) : "");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((candidate) => {
    const matchesStream = filterStream.length
      ? filterStream.includes(candidate.stream)
      : true;

    const matchesYearsOfExp = filterYearsOfExp.length
      ? filterYearsOfExp.includes(candidate.years_of_exp)
      : true;

    const minSalaryK = minSalary ? minSalary * 1000 : null;
    const maxSalaryK = maxSalary ? maxSalary * 1000 : null;

    const matchesSalary =
      (!minSalaryK || candidate.maximum_salary >= minSalaryK) &&
      (!maxSalaryK || candidate.minimum_salary <= maxSalaryK);

    const matchesWorkingStyle = filterWorkingStyle.length
      ? candidate.working_style
        ? filterWorkingStyle.some((style) => {
            // If the filter is for "Fully in person" or "Hybrid," return candidates with the specific label
            if (style === "Fully in person" || style === "Hybrid") {
              return candidate.working_style
                .toLowerCase()
                .includes("hybrid (2/3 days) or fully in office".toLowerCase());
            }
            // For "Fully Remote," match as usual
            return candidate.working_style
              .toLowerCase()
              .startsWith(style.toLowerCase());
          })
        : false
      : true;

    const candidateIndustryArray = candidate.industry_exp
      ? candidate.industry_exp
          .split(",")
          .map((industry_exp) => industry_exp.trim())
      : [];
    const matchesIndustry = filterIndustry.length
      ? candidateIndustryArray.some((industry) =>
          filterIndustry.includes(industry)
        )
      : true;

    const matchesSearchQuery = candidate.search_fields
      ? candidate.search_fields
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      : true;

    return (
      matchesStream &&
      matchesYearsOfExp &&
      matchesSalary &&
      matchesWorkingStyle &&
      matchesIndustry &&
      matchesSearchQuery
    );
  });

  const addToast = (message) => {
    setToasts((prevToasts) => [...prevToasts, message]);
  };

  useEffect(() => {
    if (toasts.length > 0) {
      const timers = toasts.map((toast, index) =>
        setTimeout(() => {
          setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
        }, 5000)
      );

      // Cleanup all timeouts when the component unmounts or the `toasts` array changes
      return () => timers.forEach((timer) => clearTimeout(timer));
    }
  }, [toasts]);

  return (
    <div className="relative  bg-gray-100 min-h-screen">
      <div className="flex ml-auto h-full pb-20 justify-center items-center bg-gray-100">
        {isInterviewRequest && (
          <InterviewRequest
            setIsInterviewRequest={setIsInterviewRequest}
            clear={clear}
            setSuccess={setSuccess}
          />
        )}

        <div className="w-full flex justify-center">
          <div className="bg-white flex justify-center w-full max-w-none px-4 pt-2 pb-2 fixed top-18 z-20 border-b">
            <div className="flex justify-left items-center">
              <div>
                <h2 className="text-3xl">Candidates</h2>
                <div className="mt-2">
                  There are{" "}
                  <span className="font-bold">{filteredData.length} </span>{" "}
                  candidates that match your search.
                </div>
              </div>
              <div className="mx-4 mb-2">
                <div className="flex pt-2">
                  <button
                    className={`p-2 mr-2 text-sm justify-center flex rounded-md ${
                      filterStream.includes("Generalist")
                        ? "bg-green-100"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleStreamChange("Generalist")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                      />
                    </svg>
                    Generalist
                  </button>
                  <button
                    className={`p-2 mr-2 text-sm justify-center flex rounded-md ${
                      filterStream.includes("Sales")
                        ? "bg-blue-100"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleStreamChange("Sales")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                    Sales
                  </button>
                  <button
                    className={`p-2 mr-2 text-sm justify-center flex rounded-md ${
                      filterStream.includes("Marketing")
                        ? "bg-purple-100"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleStreamChange("Marketing")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42"
                      />
                    </svg>
                    Marketing
                  </button>
                  <button
                    className={`p-2 mr-2 text-sm justify-center flex rounded-md ${
                      filterStream.includes("Software Engineering")
                        ? "bg-orange-100"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleStreamChange("Software Engineering")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"
                      />
                    </svg>
                    Software Engineer
                  </button>
                </div>
                <div className="flex pt-2">
                  <div className="relative w-[400px]">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4 text-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="voice-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-[#02B491] focus:border-[#02B491] block w-full ps-10 p-2.5"
                      placeholder="Search through candidates’ CVs and profiles..."
                      value={searchQuery} // Controlled input value
                      onChange={handleSearchChange} // Update search query state on change
                      required
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 end-0 flex items-center pe-3"
                    >
                      <svg
                        className="w-4 h-4 me-2 text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex pt-2 justify-start">
                  <MultiSelect
                    text={"Years of Experience"}
                    options={year_of_exp}
                    selectedOptions={filterYearsOfExp}
                    onChange={handleYearsOfExpFilterChange}
                  />
                  <MultiSelect
                    text={"Working Styles"}
                    options={working_style}
                    selectedOptions={filterWorkingStyle}
                    onChange={handleWorkingStyleFilterChange}
                  />
                  <MultiSelect
                    text={"Industry Experience"} // Added this line
                    options={industry_exp} // Use candidate_roles for the options
                    selectedOptions={filterIndustry} // Added this line
                    onChange={setFilterIndustry} // Added this line
                  />
                  <div className="flex items-center space-x-2">
                    <div className="flex items-center border border-gray-300 rounded-md">
                      {maxSalary && <span className="pl-2">£</span>}
                      <input
                        type="text"
                        placeholder="Max Salary"
                        value={maxSalary}
                        onChange={handleMaxSalaryChange}
                        className={`px-2 py-2 text-sm ${
                          maxSalary ? "max-w-[35px]" : "w-[90px]"
                        } rounded-md border-none outline-none`}
                      />
                      {maxSalary && <span className="pr-2">k</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-[1500px]  w-full">
            {loading ? (
              <div className="pt-48 pl-4">
                <CandidatesLoading />
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 pt-40 pl-4 mr-5 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {filteredData.map((candidate, index) => (
                    <CandidatesPreviewCard
                      key={index}
                      candidate={candidate}
                      isInList={savedCandidates.some(
                        (c) => c.id === candidate.id
                      )}
                      onToggleCandidate={toggleCandidate}
                      onSelectCandidate={handleSelectCandidate}
                      recommendedCandidates={recommendedCandidates}
                      roles={roles}
                      setRoles={setRoles}
                      shortlistedCandidates={shortlistedCandidates}
                      setRolesNeedUpdate={setRolesNeedUpdate}
                      setNewRoleForm={setNewRoleForm}
                      handleSelectCandidate={handleSelectCandidate}
                      candidatesStatus={candidatesStatus}
                      addToast={addToast}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
